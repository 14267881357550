import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { useAppSelector } from 'common/hooks';
import {
  selectFilteredCustomers,
  selectFilteredCustomersTotal,
} from 'features/customers/customersSlice';
import LinkedCustomerCell from './LinkedCustomerCell';

type CustomersTableBodyProps = {
  page: number,
  rowsPerPage: number,
};

const CustomersTableBody: React.FC<CustomersTableBodyProps> = (props: CustomersTableBodyProps) => {
  const { page, rowsPerPage } = props;

  const count = useAppSelector(selectFilteredCustomersTotal);
  const customers = useAppSelector(selectFilteredCustomers);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  return (
    <TableBody>
      {customers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => (
          <TableRow
            tabIndex={-1}
            key={row.id}
          >
            <LinkedCustomerCell align="left" id={row.id}>
              {row.company}
            </LinkedCustomerCell>
            <LinkedCustomerCell align="left" id={row.id}>
              {row.department}
            </LinkedCustomerCell>
            <LinkedCustomerCell align="left" id={row.id}>
              {row.name}
            </LinkedCustomerCell>
            <LinkedCustomerCell align="left" id={row.id}>
              {row.sales}
            </LinkedCustomerCell>
            <LinkedCustomerCell align="left" id={row.id}>
              {row.movring}
            </LinkedCustomerCell>
            <LinkedCustomerCell align="left" id={row.id}>
              {row.tgc}
            </LinkedCustomerCell>
          </TableRow>
        ))}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
};

export default CustomersTableBody;
