import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import AutoLoginRoute from 'features/auth/components/AutoLoginRoute';
import AuthenticatedRoute from 'features/auth/components/AuthenticatedRoute';
import FirstAuthenticatedRoute from 'features/auth/components/FirstAuthenticatedRoute';

import TentativeSignUpComplete from 'pages/tentativeSignUpComplete/index';
import CustomerSignUp from 'pages/customerSignUp';
import SignIn from 'pages/signIn';
import FirstSignIn from 'pages/firstSignIn';
import ForgotPassword from 'pages/forgotPassword';
import ProjectDetail from 'pages/projectDetail';
import ToppanSignUp from 'pages/toppanSignUp';
import EditAccountData from 'pages/editAccountData';
import ProjectsList from 'pages/projectsList';
import ChangePassword from 'pages/changePassword';
import CreateProject from 'pages/createProject';
import ToppanAccountList from 'pages/toppanAccountList';
import CustomerAccountList from 'pages/customerAccountList';
import EditCustomer from 'pages/editCustomer';
import EditSupplier from 'pages/editSupplier';
import Top from 'pages/top';
import ChangeTemporaryPassword from 'pages/changeTemporaryPassword';

import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';

const theme = createTheme({
  palette: {
    primary: {
      light: '#76798d',
      main: '#545871',
      dark: '#3a3d4f',
      contrastText: '#fff',
    },
    secondary: {
      light: '#5393ff',
      main: '#2979ff',
      dark: '#1c54b2',
      contrastText: '#000',
    },
  },
});

const { CUSTOMER, TOPPAN } = strings.tentativeSignUpComplete;

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={5}
        autoHideDuration={10000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Router>
          <Switch>
            <AutoLoginRoute exact path={pageUrl.SIGNIN} component={SignIn} />
            <AutoLoginRoute exact path={pageUrl.FIRST_SIGNIN} component={FirstSignIn} />
            <Route exact path={pageUrl.FORGOT_PASSWORD} component={ForgotPassword} />
            <AuthenticatedRoute
              exact
              path={pageUrl.TOP}
              component={Top}
            />
            <AuthenticatedRoute
              exact
              path={pageUrl.PROJECTS_LIST}
              component={ProjectsList}
            />
            <AuthenticatedRoute
              exact
              path={pageUrl.CREATE_PROJECT}
              component={CreateProject}
            />
            <AuthenticatedRoute
              exact
              path={pageUrl.PROJECT_DETAIL}
              component={ProjectDetail}
            />
            <AuthenticatedRoute
              exact
              path={pageUrl.CUSTOMER_ACCOUNT_LIST}
              component={CustomerAccountList}
            />
            <AuthenticatedRoute
              exact
              path={pageUrl.CUSTOMER_SIGNUP}
              component={CustomerSignUp}
            />
            <AuthenticatedRoute
              exact
              path={pageUrl.TENTATIVE_CUSTOMER_SIGNUP_COMPLETE}
              render={() => <TentativeSignUpComplete text={CUSTOMER} />}
            />
            <AuthenticatedRoute
              exact
              path={pageUrl.EDIT_CUSTOMER}
              component={EditCustomer}
            />
            <AuthenticatedRoute
              exact
              path={pageUrl.TOPPAN_ACCOUNT_LIST}
              component={ToppanAccountList}
            />
            <AuthenticatedRoute
              exact
              path={pageUrl.TOPPAN_SIGNUP}
              component={ToppanSignUp}
            />
            <AuthenticatedRoute
              exact
              path={pageUrl.TENTATIVE_TOPPAN_SIGNUP_COMPLETE}
              render={() => <TentativeSignUpComplete text={TOPPAN} />}
            />
            <AuthenticatedRoute
              exact
              path={pageUrl.EDIT_SUPPLIER}
              component={EditSupplier}
            />
            <AuthenticatedRoute
              exact
              path={pageUrl.CHANGE_PASSWORD}
              component={ChangePassword}
            />
            <AuthenticatedRoute
              exact
              path={pageUrl.EDIT_ACCOUNT_DATA}
              component={EditAccountData}
            />
            <FirstAuthenticatedRoute
              exact
              path={pageUrl.CHANGE_TEMPORARY_PASSWORD}
              component={ChangeTemporaryPassword}
            />
            <Redirect to={pageUrl.TOP} />
          </Switch>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
