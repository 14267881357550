import { useState } from 'react';

import strings from 'common/utils/freeze/strings';
import { useDeleteProjectMutation } from 'features/api/apiSlice';
import { useSnackbar } from 'notistack';
import { useAppSelector, useAppDispatch } from 'common/hooks';
import { setSelectedProject } from 'features/projects/projectsSlice';
import { RootState } from 'app/store';

type Return = {
  isLoading: boolean,
  open: boolean,
  onClickDeleteButton: () => void,
  onClickCancel: () => void,
  onClickOk: () => void,
}

const useDeleteProject = (): Return => {
  const [open, setOpen] = useState(false);
  const deleteCompMsg = strings.projects.completionMessage.delete;
  const dispatch = useAppDispatch();
  const projectId = useAppSelector((state: RootState) => state.projects.selected);

  const [deleteProject, {
    isLoading: isLoadingDelete,
  }] = useDeleteProjectMutation();

  const isLoading = isLoadingDelete;

  const { enqueueSnackbar } = useSnackbar();

  const onClickDeleteButton = () => {
    setOpen(true);
  };

  const onClickCancel = () => {
    setOpen(false);
  };

  const onClickOk = async () => {
    setOpen(false);

    if (!isLoading) {
      try {
        await deleteProject(projectId).unwrap();
        enqueueSnackbar(deleteCompMsg.SUCCESS, { variant: 'success' });
      } catch (err) {
        enqueueSnackbar(deleteCompMsg.ERROR, { variant: 'error' });
      }
    }
    dispatch(setSelectedProject(0));
  };

  return {
    isLoading,
    open,
    onClickDeleteButton,
    onClickCancel,
    onClickOk,
  };
};

export default useDeleteProject;
