import { useState } from 'react';
import { useSnackbar } from 'notistack';
import deleteFileData from 'features/api/axios/deleteFileData';
import strings from 'common/utils/freeze/strings';

type ReturnType = {
  isLoading: boolean
  deleteFile: () => Promise<void>
}

const useDeleteFile = (
  projectId: string,
  fileId: number,
  refetchProjectData: () => void,
): ReturnType => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const deleteFile = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const res = await deleteFileData(projectId, `${fileId}`);
    setIsLoading(false);

    if (res.error) {
      enqueueSnackbar(res.error, { variant: 'error' });
      return;
    }

    enqueueSnackbar(strings.projectDetail.fileDeleteButtonField.delete, {
      variant: 'success',
    });
    refetchProjectData();
  };

  return {
    isLoading, deleteFile,
  };
};

export default useDeleteFile;
