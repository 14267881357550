import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import strings from 'common/utils/freeze/strings';
import PopoverComponent from 'common/components/molecules/PopoverComponent';

const {
  SUBMITTED_AT,
  CONFIRMATION_REQUESTED_AT,
  SUBMITTED_AT_POPOVER_MESSAGE,
  CONFIRMATION_REQUESTED_AT_POPOVER_MESSAGE,
} = strings.projectDetail.projectInfo;

const DateField = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const DateItem = styled.div`
  margin: 5px;
`;

const DateLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
`;

type ProjectInfoDateFieldProps = {
  submittedDate: string,
  confirmationReqDate: string,
}

const ProjectInfoDateField: React.FC<ProjectInfoDateFieldProps> = (
  props: ProjectInfoDateFieldProps,
) => {
  const {
    submittedDate,
    confirmationReqDate,
  } = props;

  return (
    <DateField>
      <DateItem>
        <DateLabel>
          <Typography variant="body1">{SUBMITTED_AT}</Typography>
          <PopoverComponent
            message={SUBMITTED_AT_POPOVER_MESSAGE}
          >
            <Icon>
              <InfoOutlinedIcon />
            </Icon>
          </PopoverComponent>
        </DateLabel>
        <TextField
          defaultValue={submittedDate}
          type="date"
          disabled
        />
      </DateItem>
      <DateItem>
        <DateLabel>
          <Typography variant="body1">{CONFIRMATION_REQUESTED_AT}</Typography>
          <PopoverComponent
            message={CONFIRMATION_REQUESTED_AT_POPOVER_MESSAGE}
          >
            <Icon>
              <InfoOutlinedIcon />
            </Icon>
          </PopoverComponent>
        </DateLabel>
        <TextField
          defaultValue={confirmationReqDate}
          type="date"
          disabled
        />
      </DateItem>
    </DateField>
  );
};

export default ProjectInfoDateField;
