import styled from 'styled-components';

type AppButtonProps = {
  border?: string
  width?: string
  value: string
  margin?: string
  onClick?: () => void
  backgroundColor?: string
  color?: string
  disabled?: boolean
}

const Button = styled.button<Pick<AppButtonProps, 'border' | 'width' | 'margin' | 'backgroundColor' | 'color'>>`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  font-size: var(--button-big-font-size);
  line-height: 2;
  border-radius: 1.6rem;
  width: ${(props) => props.width};
  border: ${(props) => props.border};
  margin: ${(props) => props.margin};
  cursor: pointer;

  &:disabled {
    background-color: #DADBE6;
    border-color: #DADBE6;
    color: var(--font-color-1);
  }
`;

const AppButton: React.FC<AppButtonProps> = (props: AppButtonProps) => {
  const {
    border, width, value, margin, onClick, backgroundColor, color, disabled,
  } = props;

  return (
    <Button
      border={border}
      width={width}
      margin={margin}
      onClick={onClick}
      backgroundColor={backgroundColor}
      color={color}
      disabled={disabled}
    >
      {value}
    </Button>
  );
};

AppButton.defaultProps = {
  border: 'none',
  width: '',
  margin: '',
  onClick: () => { /* no-empty-function回避用コメント */ },
  backgroundColor: '#545871',
  color: '#fff',
  disabled: false,
};

export default AppButton;
