import { useState } from 'react';
import Button from '@material-ui/core/Button';
import ContactMailIcon from '@material-ui/icons/ContactMail';

import MailSettingsDialog from './MailSettingsDialog';

type MailSettingsButtonProps = {
  text: string,
  fixedEmails: string[],
  emails: string[],
  saveEmails: (emails: string[]) => void,
};

const MailSettingsButton: React.FC<MailSettingsButtonProps> = (props: MailSettingsButtonProps) => {
  const {
    text,
    fixedEmails,
    emails,
    saveEmails,
  } = props;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        color="primary"
        size="large"
        startIcon={<ContactMailIcon />}
        onClick={handleClickOpen}
      >
        {text}
      </Button>
      <MailSettingsDialog
        open={open}
        onClose={handleClose}
        title={text}
        fixedEmails={fixedEmails}
        savedEmails={emails}
        saveEmails={saveEmails}
      />
    </>
  );
};

export default MailSettingsButton;
