import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSendBackConfirmationVideoMutation } from 'features/api/apiSlice';
import strings from 'common/utils/freeze/strings';
import { AcceptAndSendBackDialogProps } from '../components/AcceptAndSendBackDialog';

const {
  CONFIRMATION_VIDEO_SEND_BACK,
  SALES_SEND_BACK_DIALOG_TEXT,
  SEND_BACK_COMMENT,
  SEND_BACK_TO_TGC,
  SALES_SEND_BACK_SUCCESS,
  SALES_SEND_BACK_ERROR,
} = strings.projectDetail.videoConfirmation;

const useSalesSendBackDialog = (projectId: string): [AcceptAndSendBackDialogProps, () => void] => {
  const dialogTitle = CONFIRMATION_VIDEO_SEND_BACK;
  const text = SALES_SEND_BACK_DIALOG_TEXT;
  const subText = '';
  const commentTitle = SEND_BACK_COMMENT;
  const agreeButtonText = SEND_BACK_TO_TGC;

  const [comment, setComment] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sendBack, { isLoading }] = useSendBackConfirmationVideoMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => setDialogOpen(false);

  const onAgree = async () => {
    try {
      await sendBack({ projectId, message: comment.trim() }).unwrap();
      enqueueSnackbar(SALES_SEND_BACK_SUCCESS, { variant: 'success' });
      setDialogOpen(false);
    } catch {
      enqueueSnackbar(SALES_SEND_BACK_ERROR, { variant: 'error' });
    }
  };

  const onChangeComment = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  return [{
    dialogTitle,
    text,
    subText,
    commentTitle,
    comment,
    agreeButtonText,
    dialogOpen,
    isAgree: isLoading,
    handleClose,
    onAgree,
    onChangeComment,
  }, handleOpen];
};

export default useSalesSendBackDialog;
