import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { Role } from 'common/types/Role';
import strings from 'common/utils/freeze/strings';
import { useGetAnnouncementQuery, useEditAnnouncementMutation } from 'features/api/apiSlice';
import { User, AnnouncementBody } from 'features/api/types';

const {
  SUCCESS_EDIT_ANNOUNCEMENT,
  ERROR_EDIT_ANNOUNCEMENT,
  ERROR_GET_ANNOUNCEMENT,
} = strings.topPage;

type Return = {
  isLoading: boolean,
  canEdit: boolean,
  value: string,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onClickSave: () => void,
}

const useAnnouncements = (
  userInfo: User | undefined,
  isSuccessGetUser: boolean,
  announcementId: string,
): Return => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [value, setValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const {
    data,
    isLoading: isLoadingGetAnnouncement,
    isSuccess: isSuccessGetAnnouncement,
    isError: isErrorGetAnnouncement,
  } = useGetAnnouncementQuery(announcementId, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  const [editAnnouncement, {
    isLoading: isLoadingEditAnnouncement,
  }] = useEditAnnouncementMutation();

  useEffect(() => {
    if (isSuccessGetUser && userInfo) {
      if ((userInfo.role === Role.admin) || (userInfo.role === Role.movring)) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
  }, [isSuccessGetUser, userInfo]);

  useEffect(() => {
    if (isSuccessGetAnnouncement && data) {
      setValue(data.announcement);
    }
  }, [isSuccessGetAnnouncement, data]);

  useEffect(() => {
    if (isErrorGetAnnouncement) {
      enqueueSnackbar(ERROR_GET_ANNOUNCEMENT, { variant: 'error' });
    }
  }, [isErrorGetAnnouncement, enqueueSnackbar]);

  const handleClickSaveButton = async () => {
    if (!isLoadingEditAnnouncement) {
      const body: AnnouncementBody = {
        announcement: value.trim(),
      };

      try {
        await editAnnouncement({ announcementId, ...body }).unwrap();
        enqueueSnackbar(SUCCESS_EDIT_ANNOUNCEMENT, { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(ERROR_EDIT_ANNOUNCEMENT, { variant: 'error' });
      }
    }
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return {
    isLoading: isLoadingGetAnnouncement || isLoadingEditAnnouncement,
    canEdit: isSuccessGetAnnouncement && isAdmin,
    value,
    onChange: handleChangeValue,
    onClickSave: handleClickSaveButton,
  };
};

export default useAnnouncements;
