import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { UserStatus } from 'common/types/UserStatus';
import { useGetSupplierQuery, useEditSupplierMutation } from 'features/api/apiSlice';
import { SupplierBody } from 'features/api/types';
import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';

const { REQUIRED } = strings.editSupplier.validationError;
const {
  SUCCESS,
  ERROR_PERMISSION,
  ERROR_GET_SUPPLIER,
  ERROR_EDIT_SUPPLIER,
  ERROR_EDIT_SUPPLIER_424,
} = strings.editSupplier.completionMessage;

type TextFieldData = {
  value: string,
  error: string,
  onChange: (value: string) => void,
}

type radioField = {
  value: number,
  error: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

type Return = {
  isLoadingGetSupplier: boolean,
  isLoadingEditSupplier: boolean,
  visibleResendTmpPass: boolean,
  save: () => void,
  role: radioField,
  company: TextFieldData,
  businessUnit: TextFieldData,
  department: TextFieldData,
  name: TextFieldData,
  email: TextFieldData,
}

const useEditSupplier = (
  supplierId: string,
): Return => {
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [businessUnit, setBusinessUnit] = useState('');
  const [department, setDepartment] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState(0);

  const [nameError, setNameError] = useState('');
  const [companyError, setCompanyError] = useState('');
  const [businessUnitError, setBusinessUnitError] = useState('');
  const [departmentError, setDepartmentError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [roleError, setRoleError] = useState('');

  const [visibleResendTmpPass, setVisibleResendTmpPass] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  // RTK queryにType Guardが実装されていなかったので、自前で実装しました。そのため `any` を使用。
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isFetchBaseQueryError = (err: any): err is FetchBaseQueryError => err.status !== undefined;

  const {
    data: supplier,
    isLoading: isLoadingGetSupplier,
    isSuccess: isSuccessGetSupplier,
    isError: isErrorGetSupplier,
    error: errorGetSupplier,
  } = useGetSupplierQuery(supplierId);

  useEffect(() => {
    if (isSuccessGetSupplier && supplier) {
      setRole(supplier.role);
      setCompany(supplier.company);
      setBusinessUnit(supplier.business_unit);
      setDepartment(supplier.department);
      setName(supplier.name);
      setEmail(supplier.mail);

      if (supplier.status && supplier.status === UserStatus.forceChangePassword) {
        setVisibleResendTmpPass(true);
      }
    }
  }, [isSuccessGetSupplier, supplier]);

  useEffect(() => {
    if (isErrorGetSupplier) {
      if (isFetchBaseQueryError(errorGetSupplier) && errorGetSupplier.status === 403) {
        enqueueSnackbar(ERROR_PERMISSION, { variant: 'error' });
        history.replace({ pathname: pageUrl.TOP });
      } else {
        enqueueSnackbar(ERROR_GET_SUPPLIER, { variant: 'error' });
        history.goBack();
      }
    }
  }, [isErrorGetSupplier, errorGetSupplier, enqueueSnackbar, history]);

  const [editSupplier, {
    isLoading: isLoadingEditSupplier,
  }] = useEditSupplierMutation();

  const handleChangeRole = (e: React.ChangeEvent<HTMLInputElement>) => {
    const roleNum = Number(e.target.value);
    setRole(roleNum);
  };

  const handleChangeCompany = (value: string) => {
    setCompany(value);
  };

  const handleChangeBusinessUnit = (value: string) => {
    setBusinessUnit(value);
  };

  const handleChangeDepartment = (value: string) => {
    setDepartment(value);
  };

  const handleChangeName = (value: string) => {
    setName(value);
  };

  const handleChangeEmail = (value: string) => {
    setEmail(value);
  };

  const validateRequired = (value: string): string => {
    let errorMessage = '';

    if (value.trim() === '') {
      errorMessage = REQUIRED;
    }

    return errorMessage;
  };

  const validate = (): boolean => {
    const errorCompany = validateRequired(company);
    const errorBusinessUnit = validateRequired(businessUnit);
    const errorDepartment = validateRequired(department);
    const errorName = validateRequired(name);
    const errorEmail = '';

    setCompanyError(errorCompany);
    setBusinessUnitError(errorBusinessUnit);
    setDepartmentError(errorDepartment);
    setNameError(errorName);
    setEmailError(errorEmail);

    return ((errorCompany === '')
      && (errorBusinessUnit === '')
      && (errorDepartment === '')
      && (errorName === '')
      && (errorEmail === ''));
  };

  const save = async () => {
    if (!validate()) {
      return;
    }

    const supplierData: SupplierBody = {
      role,
      mail: email.trim(),
      name: name.trim(),
      company: company.trim(),
      business_unit: businessUnit.trim(),
      department: department.trim(),
    };

    if (!isLoadingEditSupplier) {
      try {
        await editSupplier({ supplierId, ...supplierData }).unwrap();
        enqueueSnackbar(SUCCESS, { variant: 'success' });
        history.push(pageUrl.TOPPAN_ACCOUNT_LIST);
      } catch (err) {
        if (isFetchBaseQueryError(err) && err.status === 424) {
          setRoleError(ERROR_EDIT_SUPPLIER_424);
          return;
        }
        enqueueSnackbar(ERROR_EDIT_SUPPLIER, { variant: 'error' });
      }
    }
  };

  return {
    isLoadingGetSupplier,
    isLoadingEditSupplier,
    visibleResendTmpPass,
    save,
    role: {
      value: role,
      error: roleError,
      onChange: handleChangeRole,
    },
    company: {
      value: company,
      error: companyError,
      onChange: handleChangeCompany,
    },
    businessUnit: {
      value: businessUnit,
      error: businessUnitError,
      onChange: handleChangeBusinessUnit,
    },
    department: {
      value: department,
      error: departmentError,
      onChange: handleChangeDepartment,
    },
    name: {
      value: name,
      error: nameError,
      onChange: handleChangeName,
    },
    email: {
      value: email,
      error: emailError,
      onChange: handleChangeEmail,
    },
  };
};

export default useEditSupplier;
