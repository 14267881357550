import styled from 'styled-components';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

const Button = styled.div`
  margin-right: -1px
`;

const StyledFormControl = withStyles(() => createStyles({
  root: {
    backgroundColor: '#F0F1F7',
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px 0 0 4px',
    },
  },
}))(FormControl);

const StyledSelect = withStyles(() => createStyles({
  root: {
    minWidth: 71,
    padding: 10,
    fontSize: 'var(--small-font-size)',
    height: '20px',
  },
}))(Select);

const StyledMenuItem = withStyles(() => createStyles({
  root: {
    fontSize: 'var(--small-font-size)',
  },
}))(MenuItem);

const StyledTextField = withStyles(() => createStyles({
  root: {
    minWidth: 250,
    height: 40,
    padding: 0,
    mergin: 0,
    fontSize: 14,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '0 4px 4px 0',
      },
    },
  },
}))(TextField);

export type SelectorItem = {
  id: string,
  label: string,
};

type SelectAndInputFieldProps = {
  selectedItem: string,
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void,
  selectorItems: SelectorItem[],
  inputValue: string,
  handleInput: (event: React.ChangeEvent<{ value: unknown }>) => void,
};

const SelectAndInputField: React.FC<SelectAndInputFieldProps> = (
  props: SelectAndInputFieldProps,
) => {
  const {
    selectedItem,
    handleChange,
    selectorItems,
    inputValue,
    handleInput,
  } = props;

  return (
    <>
      <Button>
        <StyledFormControl
          variant="outlined"
        >
          <StyledSelect
            value={selectedItem}
            onChange={handleChange}
          >
            {selectorItems.map((selectorItem) => (
              <StyledMenuItem key={selectorItem.id} value={selectorItem.id}>
                {selectorItem.label}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </StyledFormControl>
      </Button>
      <StyledTextField
        variant="outlined"
        size="small"
        value={inputValue}
        onChange={handleInput}
      />
    </>
  );
};

export default SelectAndInputField;
