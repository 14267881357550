import { ProjectFile, Submission } from 'features/api/types';
import { format } from 'date-fns';

export type DisplaySubmission = {
  submitId: string;
  submitName: string;
  createdAt: number;
  drafts: ProjectFile[]
  username: string;
}

export const unixTimeToString = (unixTime: number): string => format(new Date(unixTime * 1000), 'yyyyMMdd');

export const unixTimeToDisplayString = (unixTime: number): string => format(new Date(unixTime * 1000), 'yyyy/MM/dd HH:mm:ss');

export const createDisplaySubmissions = (submissions: Submission[]): DisplaySubmission[] => {
  const result: DisplaySubmission[] = [];
  const sortedSubmissions = [...submissions].sort((a, b) => (a.created_at < b.created_at ? -1 : 1));

  let serialNumber = 1;
  let beforeSubmitDate = '';
  sortedSubmissions.forEach((submission) => {
    const submitDate = unixTimeToString(submission.created_at);
    if (submitDate !== beforeSubmitDate) {
      serialNumber = 1;
    }

    result.push({
      submitId: submission.submit_id,
      submitName: `${submitDate}-${serialNumber}`,
      createdAt: submission.created_at,
      drafts: submission.drafts,
      username: submission.drafts[0].uploaded_by,
    });

    serialNumber += 1;
    beforeSubmitDate = submitDate;
  });

  result.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
  return result;
};

export const findSubmission = (
  submissions: Submission[], submitId: string,
): Submission | undefined => submissions.find((submission) => submission.submit_id === submitId);
