import strings from 'common/utils/freeze/strings';
import CustomTextField from 'common/components/molecules/CustomTextField';

type TextFieldProps = {
  value: string
  label: string
  onChange: (value: string) => void
  error?: string
  placeholder?: string
  disabled?: boolean
  margin?: string
  maxLength?: number
}

const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const {
    value,
    label,
    onChange,
    error,
    placeholder,
    disabled,
    margin,
    maxLength,
  } = props;

  return (
    <CustomTextField
      value={value}
      onChange={onChange}
      label={label}
      error={error !== ''}
      helperText={error}
      placeholder={placeholder}
      margin={margin}
      disabled={disabled}
      maxLength={maxLength}
    />
  );
};

TextField.defaultProps = {
  error: '',
  placeholder: strings.INPUT_PLACEHOLDER,
  disabled: false,
  margin: '0 0 1rem 0',
  maxLength: undefined,
};

export default TextField;
