import { useState } from 'react';

type UseSelectedFileReturn = {
  selected: number
  handleSelectedFile: (fileId: number) => void
}

const useSelectedFile = (): UseSelectedFileReturn => {
  const [selected, setSelected] = useState(0);

  const handleSelectedFile = (fileId: number) => {
    if (fileId === selected) {
      setSelected(0);
      return;
    }
    setSelected(fileId);
  };

  return { selected, handleSelectedFile };
};

export default useSelectedFile;
