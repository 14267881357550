import { useHistory } from 'react-router-dom';

import TableCell from '@material-ui/core/TableCell';

import pageUrl from 'common/utils/freeze/pageUrl';

type LinkedCustomerCellProps = {
  align: 'center' | 'left' | 'right',
  id: number,
  children: React.ReactNode,
};

const LinkedCustomerCell: React.FC<LinkedCustomerCellProps> = (props: LinkedCustomerCellProps) => {
  const { align, id, children } = props;

  const history = useHistory();
  const onClick = (customerId: number) => {
    history.push(`${pageUrl.CUSTOMER_ACCOUNT_LIST}/${customerId}`);
  };

  return (
    <TableCell
      align={align}
      onClick={() => onClick(id)}
    >
      {children}
    </TableCell>
  );
};

export default LinkedCustomerCell;
