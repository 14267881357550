const download = (url: string): void => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', '');
  link.target = '_blank';
  link.click();
  link.remove();
};

export default download;
