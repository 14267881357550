import styled from 'styled-components';
import LocalParkingIcon from '@material-ui/icons/LocalParking';

import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';
import SidebarLink from '../atoms/SidebarLink';

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.2rem;
  .sidebar-title-font {
    font-size: var(--sidebar-title-font-size);
    font-weight: bold;
    margin-left: 0.6rem;
  }
`;

type MenuObject = {
  menu: string
  path: string
}

const SidebarProjectMenu: React.FC = () => {
  const {
    PROJECT_MANAGEMENT,
  } = strings.sidebar.TITLE;

  const {
    PROJECTS,
    CREATE_PROJECT,
  } = strings.sidebar.MENU;

  const projectMenu: MenuObject[] = [
    {
      menu: PROJECTS,
      path: pageUrl.PROJECTS_LIST,
    },
    {
      menu: CREATE_PROJECT,
      path: pageUrl.CREATE_PROJECT,
    },
  ];

  return (
    <div>
      <Title>
        <LocalParkingIcon style={{ fontSize: 35 }} />
        <div className="sidebar-title-font">{PROJECT_MANAGEMENT}</div>
      </Title>
      {projectMenu.map((item) => (
        <SidebarLink
          key={item.path}
          to={item.path}
          text={item.menu}
        />
      ))}
    </div>
  );
};

export default SidebarProjectMenu;
