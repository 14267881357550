import styled from 'styled-components';
import { Link } from 'react-router-dom';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { User } from 'features/api/types';
import { Role } from 'common/types/Role';
import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';

const OtherItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0.2rem 0 0 1rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--font-color-1);
`;

type Props = {
  userInfo: User | undefined
  signOut: () => Promise<void>
}

const SidebarOtherMenu: React.FC<Props> = (props: Props) => {
  const { userInfo, signOut } = props;

  const {
    EDIT_ACCOUNT,
    CHANGE_PASSWORD,
    LOGOUT,
  } = strings.sidebar.MENU;

  return (
    <div>
      {userInfo && userInfo.role === Role.customer && (
        <OtherItem>
          <RecentActorsIcon style={{ fontSize: 25, marginRight: '1rem' }} />
          <StyledLink to={pageUrl.EDIT_ACCOUNT_DATA}>{EDIT_ACCOUNT}</StyledLink>
        </OtherItem>
      )}
      <OtherItem>
        <VpnKeyIcon style={{ fontSize: 25, marginRight: '1rem' }} />
        <StyledLink to={pageUrl.CHANGE_PASSWORD}>{CHANGE_PASSWORD}</StyledLink>
      </OtherItem>
      <OtherItem onClick={signOut}>
        <ExitToAppIcon style={{ fontSize: 25, marginRight: '1rem' }} />
        <div>{LOGOUT}</div>
      </OtherItem>
    </div>
  );
};

export default SidebarOtherMenu;
