import { useState } from 'react';

import { useGetUserQuery } from 'features/api/apiSlice';
import CloseSidebar from 'common/components/molecules/CloseSidebar';
import OpenSidebar from '../molecules/OpenSidebar';

type AppSidebarProps = {
  initState: boolean
}

const AppSidebar: React.FC<AppSidebarProps> = (props: AppSidebarProps) => {
  const { initState } = props;
  const [sidebarState, setSidebarState] = useState(initState);

  const { data: userInfo, isLoading } = useGetUserQuery();

  const handleToggle = () => {
    setSidebarState(!sidebarState);
  };

  if (!sidebarState) {
    return (
      <CloseSidebar
        handleToggle={handleToggle}
        userInfo={userInfo}
      />
    );
  }

  return (
    <OpenSidebar
      handleToggle={handleToggle}
      userInfo={userInfo}
      isLoading={isLoading}
    />
  );
};

export default AppSidebar;
