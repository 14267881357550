import styled from 'styled-components';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import BackgroundPageStyle from 'common/styles/BackgroundPageStyle';
import { useAppSelector, useAppDispatch } from 'common/hooks';
import { Role } from 'common/types/Role';
import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';
import AppSidebar from 'common/components/orgnisms/AppSidebar';
import AppHeader from 'common/components/molecules/AppHeader';
import Loading from 'common/components/molecules/Loading';
import { useGetUserQuery, useGetSuppliersQuery } from 'features/api/apiSlice';
import { TableHeadCell } from 'features/suppliers/types';
import {
  setSuppliers,
  selectSuppliersTotal,
} from 'features/suppliers/suppliersSlice';
import SuppliersTable from './components/SuppliersTable';
import SearchSuppliers from './components/SearchSuppliers';
import AddNewSupplierButton from './components/AddNewSupplierButton';

const {
  HEADER_TITLE,
  ERROR_GET_SUPPLIERS,
  errorUserInfo,
} = strings.suppliers;
const {
  COMPANY,
  BUSINESS_UNIT,
  DEPARTMENT,
  NAME,
  ROLE,
  CREATED_AT,
} = strings.suppliers.tableHeader;

const Body = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const MainArea = styled.div`
  flex: 1;
  padding: 0 35px 35px 35px;
  overflow: scroll;
`;

const Head = styled.div`
  display: flex;
  padding: 20px 0 20px 0
`;

const Search = styled.div`
  width: 800px;
  height: 40px;
  margin: 0 0 0 auto;
`;

type SuppliersProps = {
  role: Role,
};

const Suppliers: React.FC<SuppliersProps> = (props: SuppliersProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { role } = props;
  const count = useAppSelector(selectSuppliersTotal);
  const dispatch = useAppDispatch();
  const tableHeadCells: TableHeadCell[] = [
    { id: 'company', align: 'left', label: COMPANY },
    { id: 'businessUnit', align: 'left', label: BUSINESS_UNIT },
    { id: 'department', align: 'left', label: DEPARTMENT },
    { id: 'name', align: 'left', label: NAME },
    { id: 'role', align: 'left', label: ROLE },
    { id: 'createdAt', align: 'left', label: CREATED_AT },
  ];

  const {
    data,
    isLoading,
    isSuccess,
    isError,
  } = useGetSuppliersQuery();

  if (isSuccess) {
    if (data) {
      dispatch(setSuppliers(data));
    }
  }

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(ERROR_GET_SUPPLIERS, { variant: 'error' });
    }
  }, [isError, enqueueSnackbar]);

  return (
    <BackgroundPageStyle>
      <AppHeader title={HEADER_TITLE} />
      <Body>
        <AppSidebar initState={false} />
        <MainArea>
          <Loading open={isLoading} />
          <Head>
            {((role === Role.admin) || (role === Role.movring)) && (
              <AddNewSupplierButton />
            )}
            <Search>
              <SearchSuppliers tableHeadCells={tableHeadCells} />
            </Search>
          </Head>
          {count > 0 && (
            <SuppliersTable headCells={tableHeadCells} />
          )}
        </MainArea>
      </Body>
    </BackgroundPageStyle>
  );
};

const SupplierAccountList: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const {
    data,
    isLoading,
    isSuccess,
    isError,
  } = useGetUserQuery();

  useEffect(() => {
    if (isSuccess) {
      if (data && (data.role === Role.customer || data.role === Role.sales)) {
        enqueueSnackbar(errorUserInfo.ERROR_PERMISSION, { variant: 'error' });
        history.replace({ pathname: pageUrl.TOP });
      }
    }
  }, [data, isSuccess, enqueueSnackbar, history]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(errorUserInfo.ERROR_GET_USER, { variant: 'error' });
    }
  }, [isError, enqueueSnackbar]);

  return (
    <>
      <Loading open={isLoading} />
      {(isSuccess && data) && (
        <Suppliers role={data.role} />
      )}
    </>
  );
};

export default SupplierAccountList;
