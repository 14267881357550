import styled from 'styled-components';
import { useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import strings from 'common/utils/freeze/strings';
import maxLength from 'common/utils/freeze/maxLength';

const {
  PROJECT_ID,
  BUTTON_SAVE,
  ERROR_REQUIRED,
} = strings.projectDetail.projectInfo;

const ProjectIdField = styled.div`
  width: 250px;
  display: flex;
`;

const TitleField = styled.div`
  flex: 1;
  display: flex;
`;

const EditSaveButtonField = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const EditButtonField = styled.div`
  width: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SaveButtonField = styled.div`
  width: 170px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledTextField = withStyles(() => createStyles({
  root: {
    backgroundColor: 'white',
    padding: 0,
    margin: 0,
    height: 33,
  },
}))(TextField);

const StyledButton = withStyles(() => createStyles({
  root: {
    margin: '0 2px 0 2px ',
  },
}))(Button);

const StyledIconButton = withStyles(() => createStyles({
  root: {
    padding: 0,
  },
}))(IconButton);

type ProjectInfoHeaderProps = {
  edit: boolean,
  projectId: number,
  projectName: string,
  onClickEdit: () => void,
  onClickSave: () => void,
  onClickCancel: () => void,
  onChangeProjectName: (value: string) => void,
}

const ProjectInfoHeader: React.FC<ProjectInfoHeaderProps> = (props: ProjectInfoHeaderProps) => {
  const {
    edit,
    projectId,
    projectName,
    onClickEdit,
    onClickSave,
    onClickCancel,
    onChangeProjectName,
  } = props;

  const [error, setError] = useState('');
  const handleInput = (event: React.ChangeEvent<{ value: string }>) => {
    if (event.target.value.trim() === '') {
      setError(ERROR_REQUIRED);
    } else {
      setError('');
    }
    onChangeProjectName(event.target.value);
  };

  return (
    <>
      <ProjectIdField>
        <p className="title">{`${PROJECT_ID}: ${projectId}`}</p>
      </ProjectIdField>
      <TitleField>
        {(edit) ? (
          <StyledTextField
            value={projectName}
            onChange={handleInput}
            fullWidth
            inputProps={{
              maxLength: maxLength.PROJECT_NAME,
              style: { fontSize: 18 },
            }}
            error={error !== ''}
            helperText={error}
          />
        ) : (
          <p className="title">{projectName}</p>
        )}
      </TitleField>
      <EditSaveButtonField>
        {(edit) ? (
          <SaveButtonField>
            <StyledButton
              variant="contained"
              size="small"
              onClick={onClickCancel}
            >
              キャンセル
            </StyledButton>
            <StyledButton
              variant="contained"
              size="small"
              startIcon={<DoneIcon />}
              onClick={onClickSave}
              disabled={projectName.trim() === ''}
            >
              {BUTTON_SAVE}
            </StyledButton>
          </SaveButtonField>
        ) : (
          <EditButtonField>
            <StyledIconButton
              color="inherit"
              onClick={onClickEdit}
            >
              <EditIcon />
            </StyledIconButton>
          </EditButtonField>
        )}
      </EditSaveButtonField>
    </>
  );
};

export default ProjectInfoHeader;
