import CustomTextField from 'common/components/molecules/CustomTextField';
import maxLength from 'common/utils/freeze/maxLength';

type PassswordInputFieldProps = {
  value: string,
  label: string,
  placeholder: string,
  error: string,
  onChange: (value: string) => void,
};

const PasswordInputField: React.FC<PassswordInputFieldProps> = (
  props: PassswordInputFieldProps,
) => {
  const {
    value,
    label,
    placeholder,
    error,
    onChange,
  } = props;

  return (
    <CustomTextField
      value={value}
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      error={(error !== '')}
      helperText={error}
      type="password"
      width="613px"
      margin="50px"
      maxLength={maxLength.PASSWORD}
    />
  );
};

export default PasswordInputField;
