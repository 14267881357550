import { Auth } from 'aws-amplify';

type Return = {
  idToken: string,
  accessToken: string,
}

const getToken = async (): Promise<Return> => {
  let idToken = '';
  let accessToken = '';

  const currentSession = await Auth.currentSession();
  const currentIdToken = currentSession.getIdToken().getJwtToken();
  const currentAccessToken = currentSession.getAccessToken().getJwtToken();
  idToken = currentIdToken;
  accessToken = currentAccessToken;

  return {
    idToken,
    accessToken,
  };
};

export default getToken;
