import axios from 'axios';

import strings from 'common/utils/freeze/strings';
import ApiUrl from 'common/utils/freeze/ApiUrl';
import getToken from 'features/auth/getToken';

const { BASE_URL } = ApiUrl;
const { ERROR_429, ERROR_424, ERROR_DEFAULT } = strings.projectDetail.completionMessage.downloadZip;

type GetZipDownloadUrl = {
  response: void | null
  error: string | null
}

const getZipDownloadUrl = async (projectId: string, type: 'DRAFT' | 'PRODUCTION', submitId: string | null): Promise<GetZipDownloadUrl> => {
  try {
    const { idToken, accessToken } = await getToken();
    const response = await axios.get<void>(
      `${BASE_URL}/projects/${projectId}/file-download-url/invoke`,
      {
        headers: {
          authorization: idToken,
          accesstoken: accessToken,
          // TODO: デバッグ用のコードなので、本番では削除する
          // Prefer: 'code=429',
        },
        params: {
          type,
          submitId,
        },
      },
    );
    return { response: response.data, error: null };
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      if (e.response.status === 429) {
        return { response: null, error: ERROR_429 };
      } if (e.response.status === 424) {
        return { response: null, error: ERROR_424 };
      }
    }

    /* TODO: サーバー側が修正され、エラー429が返ってくるようになったら、
    `ERROR_DEFAULT` を `strings.ERROR_DESCRIPTION_DOWNLOAD_ZIP` に変更する */
    return { response: null, error: ERROR_DEFAULT };
  }
};

export default getZipDownloadUrl;
