import styled from 'styled-components';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const FileIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  pointer-events: none;
`;

const FileNameText = styled.div`
  font-size: var(--vy-small-font-size);
  color: var(--font-color-3);
  font-weight: bold;
`;

const FileItem: React.FC<{ fileName: string }> = (props: { fileName: string }) => {
  const { fileName } = props;
  const sliceString = (str: string, num: number) => (str.length > num ? `${str.slice(0, num)}...` : str);

  return (
    <FileIcon>
      {/* TODO: アイコン差し替え */}
      <InsertDriveFileIcon style={{ width: '72px', height: '95px', color: '#545871' }} />
      <FileNameText>{sliceString(fileName, 12)}</FileNameText>
    </FileIcon>
  );
};

export default FileItem;
