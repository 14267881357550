import styled from 'styled-components';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import BackgroundPageStyle from 'common/styles/BackgroundPageStyle';
import { useAppSelector, useAppDispatch } from 'common/hooks';
import AppHeader from 'common/components/molecules/AppHeader';
import AppSidebar from 'common/components/orgnisms/AppSidebar';
import strings from 'common/utils/freeze/strings';
import { useGetProjectsQuery } from 'features/api/apiSlice';
import Loading from 'common/components/molecules/Loading';
import {
  setProjects,
  selectProjectsTotal,
} from 'features/projects/projectsSlice';
import { TableHeadCell } from 'features/projects/types';
import ProjectsTable from './components/ProjectsTable';
import SearchProjects from './components/SearchProjects';
import ProjectsMenu from './components/ProjectsMenu';
import DeleteDialog from './components/DeleteDialog';
import useDeleteProject from './hooks/useDeleteProject';

const Body = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const MainArea = styled.div`
  flex: 1;
  padding: 0 35px 35px 35px;
  overflow: scroll;
`;

const Head = styled.div`
  display: flex;
  padding: 20px 0 20px 0
`;

const Search = styled.div`
  width: 800px;
  height: 40px;
  margin: 0 0 0 auto;
`;

const ProjectsList: React.FC = () => {
  const title = 'プロジェクト一覧';
  const count = useAppSelector(selectProjectsTotal);
  const dispatch = useAppDispatch();
  const tableHeadCells: TableHeadCell[] = [
    { id: 'customerCompany', align: 'left', label: '企業' },
    { id: 'customerDepartment', align: 'left', label: '部署' },
    { id: 'status', align: 'left', label: 'ステータス' },
    { id: 'projectId', align: 'left', label: 'プロジェクトID' },
    { id: 'projectName', align: 'left', label: 'プロジェクト名' },
    { id: 'updatedAt', align: 'left', label: '最終更新日' },
    { id: 'deliveredAt', align: 'left', label: '最終納品日' },
  ];
  const { enqueueSnackbar } = useSnackbar();
  const { ERROR } = strings.projects.completionMessage.getProjects;

  const {
    data,
    isLoading,
    isSuccess,
    isError,
  } = useGetProjectsQuery();

  const {
    isLoading: isLoadingDelete,
    open: openDeleteDialog,
    onClickDeleteButton,
    onClickCancel: onClickDeleteCancel,
    onClickOk: onClickDeleteOk,
  } = useDeleteProject();

  useEffect(() => {
    if (isSuccess) {
      if (data) {
        dispatch(setProjects(data));
      }
    }
  }, [isSuccess, dispatch, data]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(ERROR, { variant: 'error' });
    }
  }, [isError, enqueueSnackbar, ERROR]);

  return (
    <BackgroundPageStyle>
      <AppHeader title={title} />
      <Body>
        <DeleteDialog
          open={openDeleteDialog}
          onClickCancel={onClickDeleteCancel}
          onClickOk={onClickDeleteOk}
        />
        <AppSidebar initState={false} />
        <MainArea>
          <Loading open={isLoading || isLoadingDelete} />
          <Head>
            <ProjectsMenu onClickDeleteButton={onClickDeleteButton} />
            <Search>
              <SearchProjects tableHeadCells={tableHeadCells} />
            </Search>
          </Head>
          {count > 0 && (
            <ProjectsTable headCells={tableHeadCells} />
          )}
        </MainArea>
      </Body>
    </BackgroundPageStyle>
  );
};

export default ProjectsList;
