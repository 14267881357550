import styled from 'styled-components';

import CustomTextField from 'common/components/molecules/CustomTextField';
import PopoverInfoTextField from 'common/components/molecules/PopoverInfoTextField';
import strings from 'common/utils/freeze/strings';
import maxLength from 'common/utils/freeze/maxLength';

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem 0;
  margin: 1.2rem 0 0 1.4rem;
`;

const TopForm = styled.div`
  display: flex;
  width: 100%;
  gap: 1.2rem 2rem;
`;

const BottomForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem 0;
`;

type CustomerFormProps = {
  customerName: string
  customerCD: string
  accountName: string
  email: string
  handleChangeCustomerName: (value: string) => void
  handleChangeCustomerCD: (value: string) => void
  handleChangeAccountName: (value: string) => void
  handleChangeEmail: (value: string) => void
  customerNameError: string
  customerCDError: string
  accountNameError: string
  emailError: string
}

const CustomerForm: React.FC<CustomerFormProps> = (props: CustomerFormProps) => {
  const {
    customerName,
    customerCD,
    accountName,
    email,
    handleChangeCustomerName,
    handleChangeCustomerCD,
    handleChangeAccountName,
    handleChangeEmail,
    customerNameError,
    customerCDError,
    accountNameError,
    emailError,
  } = props;
  const {
    CUSTOMER_NAME, CUSTOMER_CD, ACCOUNT_NAME, EMAIL,
  } = strings.customerSignUp.TEXT_LABEL;
  const {
    POPOVER_MESSAGE_CUSTOMER_NAME, POPOVER_MESSAGE_CUSTOMER_CD,
  } = strings.customerSignUp.customerForm;

  return (
    <Form>
      <TopForm>
        <PopoverInfoTextField
          label={CUSTOMER_NAME}
          value={customerName}
          onChange={handleChangeCustomerName}
          error={customerNameError !== ''}
          helperText={customerNameError}
          placeholder={strings.INPUT_PLACEHOLDER}
          popoverMessage={POPOVER_MESSAGE_CUSTOMER_NAME}
          maxLength={maxLength.CUSTOMER_COMPANY_NAME}
        />
        <PopoverInfoTextField
          label={CUSTOMER_CD}
          value={customerCD}
          onChange={handleChangeCustomerCD}
          error={customerCDError !== ''}
          helperText={customerCDError}
          placeholder={strings.INPUT_PLACEHOLDER}
          popoverMessage={POPOVER_MESSAGE_CUSTOMER_CD}
          maxLength={maxLength.CUSTOMER_COMPANY_CODE}
        />
      </TopForm>
      <BottomForm>
        <CustomTextField
          label={ACCOUNT_NAME}
          value={accountName}
          onChange={handleChangeAccountName}
          error={accountNameError !== ''}
          helperText={accountNameError}
          placeholder={strings.INPUT_PLACEHOLDER}
          maxLength={maxLength.CUSTOMER_USER_NAME}
        />
        <CustomTextField
          label={EMAIL}
          value={email}
          onChange={handleChangeEmail}
          error={emailError !== ''}
          helperText={emailError}
          placeholder={strings.INPUT_PLACEHOLDER}
          maxLength={maxLength.EMAIL}
        />
      </BottomForm>
    </Form>
  );
};

export default CustomerForm;
