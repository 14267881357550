import {
  TableBody, TableRow, TableCell, withStyles, createStyles,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import { Submission } from 'features/api/types';
import { createDisplaySubmissions, unixTimeToDisplayString } from 'pages/projectDetail/utils/createDisplayFiles';

const StyledTableCell = withStyles(() => createStyles({
  body: {
    fontSize: 12,
    color: '#707070',
  },
}))(TableCell);

type SubmissionFileTableBodyProps = {
  submissions: Submission[];
  selectedRadio: string;
  handleSelectedRadio: (submitId: string) => void;
  handleSelectedSubmisson: (submitId: string) => void;
}

const SubmissionFileTableBody: React.FC<SubmissionFileTableBodyProps> = (
  props: SubmissionFileTableBodyProps,
) => {
  const {
    submissions, selectedRadio, handleSelectedRadio, handleSelectedSubmisson,
  } = props;
  const displaySubmissions = createDisplaySubmissions(submissions);

  return (
    <TableBody>
      {displaySubmissions.map((submission, index) => {
        const isItemSelected = (selectedRadio === submission.submitId);
        const labelId = `file-table-checkbox-${index}`;

        return (
          <TableRow key={submission.submitId}>
            <TableCell>
              <Radio
                checked={isItemSelected}
                onClick={() => { handleSelectedRadio(submission.submitId); }}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </TableCell>
            <StyledTableCell
              onClick={() => { handleSelectedSubmisson(submission.submitId); }}
            >
              {submission.submitName}
            </StyledTableCell>
            <StyledTableCell
              onClick={() => { handleSelectedSubmisson(submission.submitId); }}
            >
              {unixTimeToDisplayString(submission.createdAt)}
            </StyledTableCell>
            <StyledTableCell
              onClick={() => { handleSelectedSubmisson(submission.submitId); }}
            >
              {submission.username}
            </StyledTableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default SubmissionFileTableBody;
