import { useState } from 'react';
import { useSnackbar } from 'notistack';

import getZipDownloadUrl from 'features/api/axios/getZipDownloadUrl';
import checkZipDownloadUrl from 'features/api/axios/checkZipDownloadUrl';
import download from '../utils/download';

type UseDownloadZipReturn = {
  downloadZipLoading: boolean
  downloadZip: () => Promise<void>
}

const useDownloadZip = (projectId: string, downloadFileType: 'DRAFT' | 'PRODUCTION', submitId: string | null): UseDownloadZipReturn => {
  const [downloadZipLoading, setDownloadZipLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const checkDownload = (): Promise<void> => new Promise((resolve) => {
    const checkDownloadActual = async () => {
      const res = await checkZipDownloadUrl(projectId, downloadFileType, submitId);
      if (res.response?.presigned_url) {
        download(res.response.presigned_url);
        resolve();
        return;
      }

      if (res.error) {
        enqueueSnackbar(res.error, { variant: 'error' });
        setDownloadZipLoading(false);
        resolve();
        return;
      }

      setTimeout(() => checkDownloadActual(), 5000);
    };
    checkDownloadActual();
  });

  const downloadZip = async (): Promise<void> => {
    setDownloadZipLoading(true);

    const res = await getZipDownloadUrl(projectId, downloadFileType, submitId);
    if (res.error) {
      enqueueSnackbar(res.error, { variant: 'error' });
      setDownloadZipLoading(false);
      return;
    }

    await checkDownload();

    setDownloadZipLoading(false);
  };

  return {
    downloadZipLoading, downloadZip,
  };
};

export default useDownloadZip;
