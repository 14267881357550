import { ProjectStatus } from 'common/types/ProjectStatus';
import { Role } from 'common/types/Role';
import { ProjectFile, Submission } from 'features/api/types';
import { StepStatus } from '../type';
import DeliveryFileForm from './DeliveryFileForm';
import SubmissionFileForm from './SubmissionFileForm';
import VideoConfirmation from './VideoConfirmation';

type ProgressMainContentProps = {
  activeStep: StepStatus;
  submissions: Submission[];
  projectId: string;
  refetchProjectData: () => void;
  productions: ProjectFile[];
  userRole: Role;
  confirmationUrl: string;
  projectStatus: ProjectStatus;
}

const ProgressMainContent: React.FC<ProgressMainContentProps> = (
  props: ProgressMainContentProps,
) => {
  const {
    activeStep,
    submissions,
    projectId,
    refetchProjectData,
    productions,
    userRole,
    confirmationUrl,
    projectStatus,
  } = props;

  if (activeStep === StepStatus.submission) {
    return (
      <SubmissionFileForm
        submissions={submissions}
        projectId={projectId}
        refetchProjectData={refetchProjectData}
      />
    );
  }

  if (activeStep === StepStatus.delivery) {
    return (
      <DeliveryFileForm
        projectId={projectId}
        refetchProjectData={refetchProjectData}
        productions={productions}
        userRole={userRole}
      />
    );
  }
  return (
    <VideoConfirmation
      userRole={userRole}
      confirmationUrl={confirmationUrl}
      projectId={projectId}
      projectStatus={projectStatus}
    />
  );
};

export default ProgressMainContent;
