import styled from 'styled-components';

import { Role } from 'common/types/Role';
import strings from 'common/utils/freeze/strings';
import MailSettingsButton from 'common/components/orgnisms/MailSettingsButton';

const {
  BUTTON_NOTIFICATION,
  BUTTON_NOTIFICATION_TOPPAN,
} = strings.createProjectPage;

const InputComponent = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

type MailSettingsButtonFieldProps = {
  role: Role,
  fixedEmails: string[],
  emails: string[],
  fixedEmailsToppan: string[],
  emailsToppan: string[],
  saveEmails: (emails: string[]) => void,
  saveEmailsToppan: (emails: string[]) => void,
};

const MailSettingsButtonField: React.FC<MailSettingsButtonFieldProps> = (
  props: MailSettingsButtonFieldProps,
) => {
  const {
    role,
    fixedEmails,
    emails,
    fixedEmailsToppan,
    emailsToppan,
    saveEmails,
    saveEmailsToppan,
  } = props;

  return (
    <InputComponent>
      <MailSettingsButton
        text={BUTTON_NOTIFICATION}
        fixedEmails={fixedEmails}
        emails={emails}
        saveEmails={saveEmails}
      />
      {(role !== Role.customer) && (
        <MailSettingsButton
          text={BUTTON_NOTIFICATION_TOPPAN}
          fixedEmails={fixedEmailsToppan}
          emails={emailsToppan}
          saveEmails={saveEmailsToppan}
        />
      )}
    </InputComponent>
  );
};

export default MailSettingsButtonField;
