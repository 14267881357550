import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Loading from 'common/components/molecules/Loading';
import AppButton from 'common/components/atoms/AppButton';
import maxLength from 'common/utils/freeze/maxLength';
import ActionDialog from './ActionDialog';

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  margin: 0 10px;
  color: var(--progress-button-dark-grey);
`;

const Text = styled.p`
  white-space: pre-line;
  font-size: 15px;
`;

const SubText = styled.p`
  white-space: pre-line;
  font-size: 12px;
  padding-left: 0.5em;
`;

const Comment = styled.div`
  padding: 0 10px;
`;

const TextFieldTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  color: var(--progress-button-dark-grey);
`;

const TextFieldTitle = styled.p`
  margin: 0;
  font-size: 15px;
`;

const StyledTextField = withStyles(() => ({
  root: {
    backgroundColor: 'white',
  },
}))(TextField);

const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
  margin: 20px 0;
`;

export type AcceptAndSendBackDialogProps = {
  dialogTitle: string;
  text: string;
  subText: string;
  commentTitle: string;
  comment: string;
  agreeButtonText: string;
  dialogOpen: boolean;
  isAgree: boolean;
  handleClose: () => void;
  onAgree: () => void;
  onChangeComment: (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => void;
}

const AcceptAndSendBackDialog: React.FC<AcceptAndSendBackDialogProps> = (
  props: AcceptAndSendBackDialogProps,
) => {
  const {
    dialogTitle,
    text,
    subText,
    commentTitle,
    comment,
    agreeButtonText,
    dialogOpen,
    isAgree,
    handleClose,
    onAgree,
    onChangeComment,
  } = props;

  return (
    <ActionDialog
      open={dialogOpen}
      onClose={handleClose}
      maxWidth="sm"
      title={dialogTitle}
      titleContent={null}
    >
      <Loading open={isAgree} />
      <DialogContent>
        <Header>
          <Text>{text}</Text>
          <SubText>{subText}</SubText>
        </Header>
        <Comment>
          <TextFieldTitleWrapper>
            <ArrowDropDownIcon />
            <TextFieldTitle>{commentTitle}</TextFieldTitle>
          </TextFieldTitleWrapper>
          <StyledTextField
            multiline
            variant="outlined"
            maxRows="12"
            minRows="12"
            fullWidth
            value={comment}
            onChange={onChangeComment}
            inputProps={{
              maxLength: maxLength.APPROVALANDRETURN,
            }}
          />
        </Comment>
        <ButtonArea>
          <AppButton
            value="キャンセル"
            backgroundColor="white"
            color="var(--progress-button-dark-grey)"
            border="1px solid var(--progress-button-dark-grey)"
            onClick={handleClose}
            width="180px"
          />
          <AppButton
            value={agreeButtonText}
            width="180px"
            onClick={onAgree}
          />
        </ButtonArea>
      </DialogContent>
    </ActionDialog>
  );
};

export default AcceptAndSendBackDialog;
