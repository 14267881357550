const pageUrl = Object.freeze({
  TOP: '/',
  SIGNIN: '/signin',
  FIRST_SIGNIN: '/welcome',
  CHANGE_TEMPORARY_PASSWORD: '/signup/password',
  FORGOT_PASSWORD: '/forgot-password',
  CUSTOMER_ACCOUNT_LIST: '/customers',
  EDIT_CUSTOMER: '/customers/:userId',
  CUSTOMER_SIGNUP: '/customers/new',
  TENTATIVE_CUSTOMER_SIGNUP_COMPLETE: '/customers/new/complete',
  TOPPAN_ACCOUNT_LIST: '/suppliers',
  EDIT_SUPPLIER: '/suppliers/:userId',
  TOPPAN_SIGNUP: '/suppliers/new',
  TENTATIVE_TOPPAN_SIGNUP_COMPLETE: '/suppliers/new/complete',
  PROJECTS_LIST: '/projects',
  CREATE_PROJECT: '/projects/new',
  PROJECT_DETAIL: '/projects/:projectId',
  CHANGE_PASSWORD: '/settings/password',
  EDIT_ACCOUNT_DATA: '/settings/account',
});

export default pageUrl;
