import { ProjectStatus } from 'common/types/ProjectStatus';
import { Role } from 'common/types/Role';
import { StepStatus } from '../type';

export const mostAdvancedStepCaseCustomer = (projectStatus: ProjectStatus): StepStatus => {
  switch (projectStatus) {
    case 'pre_submission':
    case 'WIP_first':
    case 'WIP_sent_back':
    case 'TBC_sales':
      return StepStatus.submission;
    case 'TBC_customer':
    case 'WIP_production':
      return StepStatus.videoCheck;
    case 'done':
      return StepStatus.delivery;
    default: {
      const exhaustivenessCheck: never = projectStatus;
      throw new Error(`Unsupported type: ${exhaustivenessCheck}`);
    }
  }
};

export const mostAdvancedStepCaseSales = (projectStatus: ProjectStatus): StepStatus => {
  switch (projectStatus) {
    case 'pre_submission':
    case 'WIP_first':
    case 'WIP_sent_back':
      return StepStatus.submission;
    case 'TBC_sales':
    case 'TBC_customer':
    case 'WIP_production':
      return StepStatus.videoCheck;
    case 'done':
      return StepStatus.delivery;
    default: {
      const exhaustivenessCheck: never = projectStatus;
      throw new Error(`Unsupported type: ${exhaustivenessCheck}`);
    }
  }
};

export const mostAdvancedStepCaseMovringAndAdmin = (projectStatus: ProjectStatus): StepStatus => {
  switch (projectStatus) {
    case 'pre_submission':
      return StepStatus.submission;
    case 'WIP_first':
    case 'WIP_sent_back':
    case 'TBC_sales':
    case 'TBC_customer':
      return StepStatus.videoCheck;
    case 'WIP_production':
    case 'done':
      return StepStatus.delivery;
    default: {
      const exhaustivenessCheck: never = projectStatus;
      throw new Error(`Unsupported type: ${exhaustivenessCheck}`);
    }
  }
};

export const checkMovableStepCaseCustomer = (
  projectStatus: ProjectStatus, step: StepStatus,
): boolean => {
  switch (projectStatus) {
    case 'pre_submission':
    case 'WIP_first':
    case 'WIP_sent_back':
    case 'TBC_sales':
      return step === StepStatus.submission;
    case 'TBC_customer':
    case 'WIP_production':
      return step === StepStatus.submission || step === StepStatus.videoCheck;
    case 'done':
      return step === StepStatus.submission || step === StepStatus.delivery;
    default: {
      const exhaustivenessCheck: never = projectStatus;
      throw new Error(`Unsupported type: ${exhaustivenessCheck}`);
    }
  }
};

export const checkMovableStepCaseSales = (
  projectStatus: ProjectStatus, step: StepStatus,
): boolean => {
  switch (projectStatus) {
    case 'pre_submission':
    case 'WIP_first':
    case 'WIP_sent_back':
      return step === StepStatus.submission;
    case 'TBC_sales':
    case 'TBC_customer':
    case 'WIP_production':
      return step === StepStatus.submission || step === StepStatus.videoCheck;
    case 'done':
      return step === StepStatus.submission || step === StepStatus.delivery;
    default: {
      const exhaustivenessCheck: never = projectStatus;
      throw new Error(`Unsupported type: ${exhaustivenessCheck}`);
    }
  }
};

export const checkMovableStepCaseMovringAndAdmin = (
  projectStatus: ProjectStatus, step: StepStatus,
): boolean => {
  switch (projectStatus) {
    case 'pre_submission':
      return step === StepStatus.submission;
    case 'WIP_first':
    case 'WIP_sent_back':
    case 'TBC_sales':
    case 'TBC_customer':
      return step === StepStatus.submission || step === StepStatus.videoCheck;
    case 'WIP_production':
      return true;
    case 'done':
      return step === StepStatus.submission || step === StepStatus.delivery;
    default: {
      const exhaustivenessCheck: never = projectStatus;
      throw new Error(`Unsupported type: ${exhaustivenessCheck}`);
    }
  }
};

export const checkActiveStep = (activeStep: StepStatus, step: StepStatus): boolean => {
  if (activeStep < step) return false;
  return true;
};

export const stepButtonDisabled = (
  projectStatus: ProjectStatus, userRole: Role, step: StepStatus,
): boolean => {
  switch (userRole) {
    case Role.customer:
      return !checkMovableStepCaseCustomer(projectStatus, step);
    case Role.sales:
      return !checkMovableStepCaseSales(projectStatus, step);
    case Role.movring:
    case Role.admin:
      return !checkMovableStepCaseMovringAndAdmin(projectStatus, step);
    default: {
      const exhaustivenessCheck: never = userRole;
      throw new Error(`Unsupported type: ${exhaustivenessCheck}`);
    }
  }
};
