import { useState } from 'react';
import { Button, withStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Loading from 'common/components/molecules/Loading';
import FileDeleteDialog from './FileDeleteDialog';
import useDeleteFile from '../hooks/useDeleteFile';

const GreyButton = withStyles({
  root: {
    fontSize: 'var(--button-vy-small-font-size)',
    color: 'var(--theme-color)',
    backgroundColor: 'var(--progress-button-grey)',
    border: '1px solid var(--progress-button-white)',
    marginRight: '0.5rem',
  },
})(Button);

type Props = {
  projectId: string
  selected: number
  refetchProjectData: () => void
  resetSelected: () => void
}

const FileDeleteField: React.FC<Props> = (props: Props) => {
  const {
    projectId, selected, refetchProjectData, resetSelected,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const {
    isLoading, deleteFile,
  } = useDeleteFile(projectId, selected, refetchProjectData);

  const handleDeleteFile = async () => {
    setOpen(false);
    await deleteFile();
    resetSelected();
  };

  return (
    <>
      <Loading open={isLoading} />
      <GreyButton
        startIcon={<DeleteIcon />}
        disabled={selected === 0}
        onClick={() => { setOpen(true); }}
      >
        削除
      </GreyButton>
      <FileDeleteDialog
        open={open}
        onClose={() => { setOpen(false); }}
        handleDeleteFile={handleDeleteFile}
      />
    </>
  );
};

export default FileDeleteField;
