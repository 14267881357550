import { format } from 'date-fns';
import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { descendingComparator, stableSort } from 'common/utils/util';
import { ProjectStatus } from 'common/types/ProjectStatus';
import { Projects } from 'features/api/types';
import { ProjectsListViewModel, Order, DisplayStatus } from 'features/projects/types';

export type Filtering = {
  selected: keyof ProjectsListViewModel,
  text: string,
}

export type ProjectsState = {
  projects: ProjectsListViewModel[],
  order: Order,
  orderBy: keyof ProjectsListViewModel,
  filtering: Filtering[],
  selected: number,
  page: number,
};

export const initialState: ProjectsState = {
  projects: [],
  order: 'desc',
  orderBy: 'updatedAt',
  filtering: [
    {
      selected: 'projectId',
      text: '',
    },
    {
      selected: 'customerName',
      text: '',
    },
  ],
  selected: 0,
  page: 0,
};

const getComparator = <Key extends keyof ProjectsListViewModel>(order: Order, orderBy: Key): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number => ((order === 'desc')
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy));

const epochToString = (epoch: number) => format(new Date(epoch * 1000), 'yyyy/MM/dd HH:mm:ss');
export const getDisplayStatus = (status: ProjectStatus): DisplayStatus => {
  switch (status) {
    case ProjectStatus.preSubmission:
      return '入稿前';
    case ProjectStatus.wipFirst:
    case ProjectStatus.wipSentBack:
    case ProjectStatus.wipProduction:
      return '制作中';
    case ProjectStatus.tbcSales:
    case ProjectStatus.tbcCustomer:
      return '確認中';
    case ProjectStatus.done:
      return '完了';
    default: {
      const exhaustivenessCheck: never = status;
      throw new Error(`Unsupported type: ${exhaustivenessCheck}`);
    }
  }
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<Projects[]>) => {
      const projects: ProjectsListViewModel[] = action.payload.map((project) => ({
        projectId: project.id,
        projectName: project.name,
        statusId: project.status,
        status: getDisplayStatus(project.status),
        customerCompany: project.customer.company,
        customerDepartment: project.customer.department ?? '',
        customerName: project.customer.name,
        updatedAt: epochToString(project.updated_at),
        deliveredAt: (project.delivered_at) ? epochToString(project.delivered_at) : '',
      }));

      state.projects = stableSort(projects, getComparator(state.order, state.orderBy));
      state.page = 0;
      state.selected = 0;
    },
    sortProjects: (state, action: PayloadAction<keyof ProjectsListViewModel>) => {
      const isAsc = state.orderBy === action.payload && state.order === 'asc';
      state.order = isAsc ? 'desc' : 'asc';
      state.orderBy = action.payload;
      state.projects = stableSort(state.projects, getComparator(state.order, state.orderBy));
      state.page = 0;
      state.selected = 0;
    },
    filterProjects: (state, action: PayloadAction<Filtering[]>) => {
      state.filtering = action.payload;
      state.page = 0;
      state.selected = 0;
    },
    setSelectedProject: (state, action: PayloadAction<number>) => {
      state.selected = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const {
  setProjects,
  sortProjects,
  filterProjects,
  setSelectedProject,
  setPage,
} = projectsSlice.actions;

export const selectAllProjects = (
  state: RootState,
): ProjectsListViewModel[] => state.projects.projects;
export const selectProjectsTotal = (state: RootState): number => state.projects.projects.length;
export const selectProjectById = (
  state: RootState,
  projectId: number,
): ProjectsListViewModel | undefined => state.projects.projects
  .find((project) => project.projectId === projectId);

const filterdProjects = (state: RootState): ProjectsListViewModel[] => state.projects.projects
  .filter((project) => (
    String(project[state.projects.filtering[0].selected])
      .indexOf(state.projects.filtering[0].text.trim()) >= 0))
  .filter((project) => (
    String(project[state.projects.filtering[1].selected])
      .indexOf(state.projects.filtering[1].text.trim()) >= 0));

export const selectFilteredProjects = (
  state: RootState,
): ProjectsListViewModel[] => filterdProjects(state);
export const selectFilteredProjectsTotal = (
  state: RootState,
): number => filterdProjects(state).length;

export default projectsSlice.reducer;
