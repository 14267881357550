import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import strings from 'common/utils/freeze/strings';
import regex from 'common/utils/freeze/regex';
import pageUrl from 'common/utils/freeze/pageUrl';
import { useForgotPasswordMutation } from 'features/api/unAuthApiSlice';
import { ForgotPasswordBody } from 'features/api/types';

const { ERROR } = strings.forgotPassword;

const {
  REQUIRED,
  EMAIL_FORMAT,
} = strings.forgotPassword.validationError;

type useSendMailReturn = {
  send: () => void,
  toLogin: () => void,
  isLoading: boolean,
  isSuccess: boolean,
  mail: {
    value: string,
    error: string,
    onChange: (value: string) => void,
  }
};

const useSendMail = (): useSendMailReturn => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [mail, setMail] = useState('');
  const [errorMail, setErrorMail] = useState('');
  const [forgotPassword, {
    isLoading,
    isSuccess,
  }] = useForgotPasswordMutation();

  const validate = (): boolean => {
    const value = mail;

    if (value === '') {
      setErrorMail(REQUIRED);
      return false;
    }

    if (!regex.EMAIL.test(value)) {
      setErrorMail(EMAIL_FORMAT);
      return false;
    }

    setErrorMail('');
    return true;
  };

  const handleChangeMail = (value: string) => {
    setMail(value);
  };

  const send = async () => {
    if (!validate()) {
      return;
    }

    const body: ForgotPasswordBody = {
      username: mail,
    };

    if (!isLoading) {
      try {
        await forgotPassword(body).unwrap();
      } catch (e) {
        enqueueSnackbar(ERROR, { variant: 'error' });
      }
    }
  };

  const toLogin = async () => {
    history.push(pageUrl.SIGNIN);
  };

  return {
    send,
    toLogin,
    isLoading,
    isSuccess,
    mail: {
      value: mail,
      error: errorMail,
      onChange: handleChangeMail,
    },
  };
};

export default useSendMail;
