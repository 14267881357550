import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import strings from 'common/utils/freeze/strings';
import regex from 'common/utils/freeze/regex';
import pageUrl from 'common/utils/freeze/pageUrl';
import { useConfirmForgotPasswordMutation } from 'features/api/unAuthApiSlice';
import { ConfirmForgotPasswordBody } from 'features/api/types';

const { SUCCESS, ERROR } = strings.resetPassword;

const {
  REQUIRED,
  PASSWORD_FORMAT,
  CONFIRM_ERROR_NEW_PASSWORD,
  CONFIRM_ERROR_CONFIRM_PASSWORD,
} = strings.resetPassword.validationError;

type useSendMailReturn = {
  send: () => void,
  isLoading: boolean,
  code: {
    value: string,
    error: string,
    onChange: (value: string) => void,
  }
  newPassword: {
    value: string,
    error: string,
    onChange: (value: string) => void,
  }
  confirmPassword: {
    value: string,
    error: string,
    onChange: (value: string) => void,
  },
};

const useResetPassword = (mail: string): useSendMailReturn => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [code, setCode] = useState('');
  const [errorCode, setErrorCode] = useState('');

  const [newPassword, setNewPassword] = useState('');
  const [errorNewPassword, setErrorNewPassword] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');

  const [confirmForgotPassword, {
    isLoading,
  }] = useConfirmForgotPasswordMutation();

  const validateCode = (value: string): boolean => {
    if (value === '') {
      setErrorCode(REQUIRED);
      return false;
    }

    setErrorCode('');
    return true;
  };

  const handleChangeCode = (value: string) => {
    setCode(value);
  };

  const validateNewPassword = (value: string): boolean => {
    if (value === '') {
      setErrorNewPassword(REQUIRED);
      return false;
    }

    if (!regex.PASSWORD.test(value)) {
      setErrorNewPassword(PASSWORD_FORMAT);
      return false;
    }

    setErrorNewPassword('');
    return true;
  };

  const handleChangeNewPassword = (value: string) => {
    setNewPassword(value);
  };

  const validateConfirmPassword = (value: string): boolean => {
    if (value === '') {
      setErrorConfirmPassword(REQUIRED);
      return false;
    }

    if (value !== newPassword) {
      setErrorNewPassword(CONFIRM_ERROR_NEW_PASSWORD);
      setErrorConfirmPassword(CONFIRM_ERROR_CONFIRM_PASSWORD);
      setNewPassword('');
      setConfirmPassword('');
      return false;
    }

    setErrorConfirmPassword('');
    return true;
  };

  const handleChangeConfirmPassword = (value: string) => {
    setConfirmPassword(value);
  };

  const validate = (): boolean => {
    const resultCode = validateCode(code);
    const resultNew = validateNewPassword(newPassword);
    const resultConfirm = validateConfirmPassword(confirmPassword);
    return resultCode && resultNew && resultConfirm;
  };

  const clean = () => {
    setCode('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const send = async () => {
    if (!validate()) {
      return;
    }

    const body: ConfirmForgotPasswordBody = {
      username: mail,
      confirmation_code: code,
      proposed_password: newPassword,
    };

    if (!isLoading) {
      try {
        await confirmForgotPassword(body).unwrap();
        clean();
        enqueueSnackbar(SUCCESS, { variant: 'success' });
        history.replace(pageUrl.SIGNIN);
      } catch (e) {
        enqueueSnackbar(ERROR, { variant: 'error' });
      }
    }
  };

  return {
    send,
    isLoading,
    code: {
      value: code,
      error: errorCode,
      onChange: handleChangeCode,
    },
    newPassword: {
      value: newPassword,
      error: errorNewPassword,
      onChange: handleChangeNewPassword,
    },
    confirmPassword: {
      value: confirmPassword,
      error: errorConfirmPassword,
      onChange: handleChangeConfirmPassword,
    },
  };
};

export default useResetPassword;
