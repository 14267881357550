import { useState, useEffect } from 'react';
import strings from 'common/utils/freeze/strings';
import { useEditProjectMutation } from 'features/api/apiSlice';
import { useSnackbar } from 'notistack';
import getProject from 'features/api/axios/getProject';

const {
  EDIT_NOTE_SUCCES,
  EDIT_NOTE_ERROR,
} = strings.projectDetail.projectInfo;

type useMessageNoteReturn = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onClickSave: () => void,
  isLoading: boolean,
}

type useMessageNoteProps = {
  content: string;
  id: number;
  updateAt: number | undefined;
  setErrorDialogOpen: (conflict: boolean) => void;
}

const useMessageNote = (props: useMessageNoteProps): useMessageNoteReturn => {
  const {
    content,
    id,
    updateAt,
    setErrorDialogOpen,
  } = props;

  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(content);
  }, [content]);

  const { enqueueSnackbar } = useSnackbar();
  const [editProject, { isLoading }] = useEditProjectMutation();

  const detectConflict = async (): Promise<boolean> => {
    const response = await getProject(id);

    if (response.error || !response.data) {
      throw new Error('APIエラー');
    }

    const updatedAt = response.data.note?.updated_at;
    if (updatedAt !== updateAt) {
      return true;
    }

    return false;
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleClickSaveButton = async () => {
    if (!isLoading) {
      try {
        if (await detectConflict()) {
          setErrorDialogOpen(true);
          return;
        }
      } catch (e) {
        enqueueSnackbar(strings.ERROR_DESCRIPTION_PROJECT, { variant: 'error' });
        return;
      }

      try {
        await editProject(
          {
            projectId: id,
            note: value,
          },
        ).unwrap();
        enqueueSnackbar(EDIT_NOTE_SUCCES, { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(EDIT_NOTE_ERROR, { variant: 'error' });
      }
    }
  };

  return {
    value,
    onChange: handleChangeValue,
    onClickSave: handleClickSaveButton,
    isLoading,
  };
};

export default useMessageNote;
