const fileSizeUnit = (byte: number): string => {
  const kb = 1024;
  const mb = kb ** 2;

  if (byte >= mb) {
    const size = Math.floor(byte / mb);
    return `${size}MB`;
  }

  if (byte >= kb) {
    const size = Math.floor(byte / kb);
    return `${size}KB`;
  }

  return `${byte}B`;
};

export default fileSizeUnit;
