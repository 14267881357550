import { useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import strings from 'common/utils/freeze/strings';

const StyledTextField = withStyles(() => createStyles({
  root: {
    backgroundColor: 'white',
  },
}))(TextField);

export type AutoCompleteData = {
  id: number,
  label: string,
};

type AutoCompleteTextFieldProps = {
  data: AutoCompleteData[],
  value: AutoCompleteData,
  onChange: (value: AutoCompleteData) => void,
  errorMessage?: string,
};

const AutoCompleteTextField: React.FC<AutoCompleteTextFieldProps> = (
  props: AutoCompleteTextFieldProps,
) => {
  const {
    data,
    value,
    onChange,
    errorMessage,
  } = props;
  const [inputValue, setInputValue] = useState('');

  const handleChangeValue = (event: unknown, newValue: string | AutoCompleteData | null) => {
    if (typeof newValue === 'string') {
      onChange({ id: 0, label: '' });
      return;
    }
    if (newValue === null) {
      onChange({ id: 0, label: '' });
      return;
    }
    onChange(newValue);
  };

  const handleInputChange = (event: unknown, newValue: string) => {
    setInputValue(newValue);
  };

  const handleOptionLabel = (option: AutoCompleteData | string) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.label;
  };

  return (
    <>
      <Autocomplete
        value={value.label}
        onChange={handleChangeValue}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        freeSolo
        autoHighlight
        clearOnBlur
        options={data}
        getOptionLabel={handleOptionLabel}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <StyledTextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            error={errorMessage !== ''}
            helperText={errorMessage}
            placeholder={strings.INPUT_PLACEHOLDER}
          />
        )}
      />
    </>
  );
};

AutoCompleteTextField.defaultProps = {
  errorMessage: '',
};

export default AutoCompleteTextField;
