import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--font-color-1);
`;

const MenuItem = styled.div`
  padding: 1rem 0 1rem 0.5rem;
  cursor: pointer;
  transition: 400ms;
  &:hover {
    text-align: center;
    box-shadow: 0 3px 6px var(--border-shadow-color) inset;
  }
`;

type SidebarLinkProps = {
  to: string
  text: string
}

const SidebarLink: React.FC<SidebarLinkProps> = (props: SidebarLinkProps) => {
  const { to, text } = props;

  return (
    <StyledLink to={to}>
      <MenuItem>{text}</MenuItem>
    </StyledLink>
  );
};

export default SidebarLink;
