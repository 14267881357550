import styled from 'styled-components';

import strings from 'common/utils/freeze/strings';

const ExternalLink = styled.a`
  display: block;
  text-align: center;
  color: var(--font-color-1);
  text-decoration: none;
  margin: 0.2rem 0;
`;

const SidebarExternalMenu: React.FC = () => {
  const { MANUAL, TOS } = strings.sidebar.MENU;
  const { URL_TERMS, URL_MANUAL } = strings;

  return (
    <div>
      <ExternalLink href={URL_MANUAL} target="_blank">
        {MANUAL}
      </ExternalLink>
      <ExternalLink href={URL_TERMS} target="_blank">
        {TOS}
      </ExternalLink>
    </div>
  );
};

export default SidebarExternalMenu;
