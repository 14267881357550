import styled from 'styled-components';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { Role } from 'common/types/Role';
import strings from 'common/utils/freeze/strings';
import AppButton from 'common/components/atoms/AppButton';
import { AutoCompleteData } from './AutoCompleteTextField';
import ProjectNameField from './ProjectNameField';
import AccountNameField from './AccountNameField';
import MailSettingsButtonField from './MailSettingsButtonField';

const { BUTTON_SAVE, MESSAGE_MOVE_TO_PJ } = strings.createProjectPage;

const InputArea = styled.div`
  padding: 3rem 3rem 0;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SaveButtonArea = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

const MessageTextArea = styled.div`
  position: absolute;
  bottom: 30px;
  right: 220px;
  color: gray;
`;

const StyledPaper = withStyles(() => createStyles({
  root: {
    width: '100%',
    margin: '30px 60px 30px 60px',
    backgroundColor: 'rgba(251,251,251,0.9)',
    position: 'relative',
  },
}))(Paper);

type CreateProjectFormProps = {
  role: Role,
  accountData: AutoCompleteData[],
  fixedEmails: string[],
  fixedEmailsToppan: string[],
  projectName: string,
  projectNameError: string,
  accountName: AutoCompleteData,
  accountNameError: string,
  emails: string[],
  emailsToppan: string[],
  handleChangeProjectName: (value: string) => void,
  handleChangeAccountName: (value: AutoCompleteData) => void,
  saveEmails: (newEmails: string[]) => void,
  saveEmailsToppan: (newEmails: string[]) => void,
  handleClickSaveButton: () => void,
};

const CreateProjectForm: React.FC<CreateProjectFormProps> = (props: CreateProjectFormProps) => {
  const {
    role,
    accountData,
    fixedEmails,
    fixedEmailsToppan,
    projectName,
    projectNameError,
    accountName,
    accountNameError,
    emails,
    emailsToppan,
    handleChangeProjectName,
    handleChangeAccountName,
    saveEmails,
    saveEmailsToppan,
    handleClickSaveButton,
  } = props;

  return (
    <StyledPaper>
      <InputArea>
        <ProjectNameField
          value={projectName}
          onChange={handleChangeProjectName}
          errorMessage={projectNameError}
        />
        <AccountNameField
          role={role}
          value={accountName}
          data={accountData}
          onChange={handleChangeAccountName}
          errorMessage={accountNameError}
        />
        <MailSettingsButtonField
          role={role}
          fixedEmails={fixedEmails}
          emails={emails}
          fixedEmailsToppan={fixedEmailsToppan}
          emailsToppan={emailsToppan}
          saveEmails={saveEmails}
          saveEmailsToppan={saveEmailsToppan}
        />
      </InputArea>
      <MessageTextArea>
        { MESSAGE_MOVE_TO_PJ }
      </MessageTextArea>
      <SaveButtonArea>
        <AppButton
          value={BUTTON_SAVE}
          width="180px"
          onClick={handleClickSaveButton}
        />
      </SaveButtonArea>
    </StyledPaper>
  );
};

export default CreateProjectForm;
