import styled from 'styled-components';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import BackgroundPageStyle from 'common/styles/BackgroundPageStyle';
import AppHeader from 'common/components/molecules/AppHeader';
import Loading from 'common/components/molecules/Loading';
import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';
import CustomTextField from 'common/components/molecules/CustomTextField';
import SignInButton from 'features/auth/components/SignInButton';
import CustomReCAPTCHA from 'features/auth/components/CustomReCAPTCHA';
import useSignIn from 'features/auth/hooks/useSignIn';

const PageContainer = styled(BackgroundPageStyle)`
  min-height: 660px;
`;

const SignInContainer = styled.div`
  margin: auto;
  width: 40%;
  min-width: 400px;
  height: 560px;
  background-color: #FFFFFFB3;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 3px 3px 6px 5px var(--border-shadow-color);
`;

const FormContainer = styled.div`
  margin-top: 100px;
  width: 70%;
  height: 50vh;
`;

const ForgotContents = styled.div`
  margin-bottom: 0.8rem;
`;

const ForgotPasswordLink = styled(Link)`
  color: var(--font-color-black);
  letter-spacing: 0.15px;
`;

const SignIn: React.FC = () => {
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [reCAPTCHAToken, setReCAPTHCAToken] = useState<string | null>('');
  const history = useHistory();

  const textFieldMargin = '0 0 18px 0';

  const handleChangeId = (value: string) => {
    setId(value);
  };

  const handleChangePassword = (value: string) => {
    setPassword(value);
  };

  const handleChangeReCAPTCHAToken = (value: string | null) => {
    setReCAPTHCAToken(value);
  };

  const success = () => {
    history.replace({ pathname: pageUrl.TOP });
  };

  const { signIn, inputError, loading } = useSignIn(success);

  const handleSignIn = () => {
    signIn(
      id,
      password,
      reCAPTCHAToken,
    );
  };

  return (
    <PageContainer>
      <Loading open={loading} />
      <AppHeader />
      <SignInContainer>
        <FormContainer>
          {/* ID・メールアドレス */}
          <CustomTextField
            margin={textFieldMargin}
            value={id}
            onChange={handleChangeId}
            placeholder={strings.signIn.ID_SIGNIN}
            error={inputError.id !== ''}
            helperText={inputError.id}
          />
          {/* パスワード */}
          <CustomTextField
            margin={textFieldMargin}
            type="password"
            value={password}
            onChange={handleChangePassword}
            placeholder={strings.signIn.PASSWORD_SIGNIN}
            error={inputError.password !== ''}
            helperText={inputError.password}
          />
          <ForgotContents>
            <ForgotPasswordLink
              to={pageUrl.FORGOT_PASSWORD}
            >
              {strings.signIn.FORGET_PASSWORD}
            </ForgotPasswordLink>
          </ForgotContents>
          <CustomReCAPTCHA
            onChange={handleChangeReCAPTCHAToken}
            inputError={inputError.reCAPTCHAToken}
          />
          <SignInButton onClick={handleSignIn} />
        </FormContainer>
      </SignInContainer>
    </PageContainer>
  );
};

export default SignIn;
