import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSendBackConfirmationVideoMutation } from 'features/api/apiSlice';
import strings from 'common/utils/freeze/strings';
import { AcceptAndSendBackDialogProps } from '../components/AcceptAndSendBackDialog';

const {
  CONFIRMATION_VIDEO_SEND_BACK,
  CUSTOMER_SEND_BACK_TEXT,
  CUSTOMER_SEND_BACK_SUB_TEXT,
  FIX_COMMENT,
  SEND_FIX_INSTRUCTIONS,
  CUSTOMER_SEND_BACK_SUCCESS,
  CUSTOMER_SEND_BACK_ERROR,
} = strings.projectDetail.videoConfirmation;

const useCustomerSendBackDialog = (projectId: string)
: [AcceptAndSendBackDialogProps, () => void] => {
  const dialogTitle = CONFIRMATION_VIDEO_SEND_BACK;
  const text = CUSTOMER_SEND_BACK_TEXT;
  const subText = CUSTOMER_SEND_BACK_SUB_TEXT;
  const commentTitle = FIX_COMMENT;
  const agreeButtonText = SEND_FIX_INSTRUCTIONS;

  const [comment, setComment] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sendBack, { isLoading }] = useSendBackConfirmationVideoMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => setDialogOpen(false);

  const onAgree = async () => {
    try {
      await sendBack({ projectId, message: comment.trim() }).unwrap();
      enqueueSnackbar(CUSTOMER_SEND_BACK_SUCCESS, { variant: 'success' });
      setDialogOpen(false);
    } catch {
      enqueueSnackbar(CUSTOMER_SEND_BACK_ERROR, { variant: 'error' });
    }
  };

  const onChangeComment = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  return [{
    dialogTitle,
    text,
    subText,
    commentTitle,
    comment,
    agreeButtonText,
    dialogOpen,
    isAgree: isLoading,
    handleClose,
    onAgree,
    onChangeComment,
  }, handleOpen];
};

export default useCustomerSendBackDialog;
