import ConfirmDialog from 'common/components/molecules/ConfirmDialog';
import strings from 'common/utils/freeze/strings';

const { TITLE, DESCRIPTION } = strings.editCustomer.resendTmpPassword.dialog;

type ResendTmpPasswordDialogProps = {
  open: boolean,
  onClickCancel: () => void,
  onClickOk: () => void,
};

const ResendTmpPasswordDialog: React.FC<ResendTmpPasswordDialogProps> = (
  props: ResendTmpPasswordDialogProps,
) => {
  const {
    open,
    onClickCancel,
    onClickOk,
  } = props;

  return (
    <ConfirmDialog
      title={TITLE}
      description={DESCRIPTION}
      open={open}
      onClickCancel={onClickCancel}
      onClickOk={onClickOk}
    />
  );
};

export default ResendTmpPasswordDialog;
