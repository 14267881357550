import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { Role } from 'common/types/Role';
import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';
import { useDisableSupplierMutation } from 'features/api/apiSlice';
import { User } from 'features/api/types';

const { SUCCESS, ERROR } = strings.editSupplier.disableAccount.completionMessage;

type Return = {
  isLoading: boolean,
  open: boolean,
  visible: boolean,
  onClickDisableButton: () => void,
  onClickCancel: () => void,
  onClickOk: () => void,
}

const useDisableAccount = (
  supplierId: string,
  userInfo: User | undefined,
  isSuccessGetUser: boolean,
): Return => {
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (isSuccessGetUser && userInfo) {
      if ((userInfo.role === Role.admin) || (userInfo.role === Role.movring)) {
        setVisible(true);
      }
    }
  }, [isSuccessGetUser, userInfo]);

  const [disableSupplier, {
    isLoading,
  }] = useDisableSupplierMutation();

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const onClickDisableButton = () => {
    setOpen(true);
  };

  const onClickCancel = () => {
    setOpen(false);
  };
  const onClickOk = async () => {
    setOpen(false);

    if (!isLoading) {
      try {
        await disableSupplier(supplierId).unwrap();
        history.push(pageUrl.TOPPAN_ACCOUNT_LIST);
        enqueueSnackbar(SUCCESS, { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(ERROR, { variant: 'error' });
      }
    }
  };

  return {
    isLoading,
    open,
    visible,
    onClickDisableButton,
    onClickCancel,
    onClickOk,
  };
};

export default useDisableAccount;
