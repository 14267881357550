import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import strings from 'common/utils/freeze/strings';
import maxLength from 'common/utils/freeze/maxLength';
import AppHeader from 'common/components/molecules/AppHeader';
import Loading from 'common/components/molecules/Loading';
import PrimaryRoundButton from 'common/components/atoms/PrimaryRoundButton';
import InputField from './components/InputField';
import ResetPassword from './components/ResetPassword';
import useSendMail from './hooks/useSendMail';

const {
  TITLE,
  DESCRIPTION,
  BUTTON_SEND,
  BUTTON_TO_LOGIN,
  LABEL_MAIL,
  PLACEHOLDER,
} = strings.forgotPassword;

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-1);
  color: var(--font-color-1);
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const MainArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TopArea = styled.div`
  flex: 1;
  padding: 3rem 3rem 0;
  overflow: scroll;
  display: flex;
  margin: auto;
  justify-content: center;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const DescriptionArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomArea = styled.div`
  background-color: var(--background-color-2);
  padding: 2rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ForgotPassword: React.FC = () => {
  const {
    send,
    toLogin,
    isLoading,
    isSuccess,
    mail,
  } = useSendMail();

  return (
    <>
      {(isSuccess) ? (
        <ResetPassword mail={mail.value} />
      ) : (
        <PageContainer>
          <AppHeader title={TITLE} />
          <Body>
            <MainArea>
              <Loading open={isLoading} />
              <TopArea>
                <div>
                  <DescriptionArea>
                    <Typography variant="body1">
                      {DESCRIPTION}
                    </Typography>
                  </DescriptionArea>
                  <InputField
                    value={mail.value}
                    label={LABEL_MAIL}
                    placeholder={PLACEHOLDER}
                    error={mail.error}
                    onChange={mail.onChange}
                    maxLength={maxLength.EMAIL}
                  />
                </div>
              </TopArea>
              <BottomArea>
                <PrimaryRoundButton variant="outlined" onClick={toLogin}>
                  {BUTTON_TO_LOGIN}
                </PrimaryRoundButton>
                <PrimaryRoundButton onClick={send}>
                  {BUTTON_SEND}
                </PrimaryRoundButton>
              </BottomArea>
            </MainArea>
          </Body>
        </PageContainer>
      )}
    </>
  );
};

export default ForgotPassword;
