import Typography, { TypographyProps } from '@material-ui/core/Typography';

type MultiLineTypographyProps = TypographyProps & {
  text: string
};

const MultiLineTypography: React.FC<MultiLineTypographyProps> = (
  props: MultiLineTypographyProps,
) => {
  const { text, ...typographyProps } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Typography {...typographyProps}>
      {text.split('\n').map((i, key) => (<div key={`line-${key + 1}`}>{i}</div>))}
    </Typography>
  );
};

export default MultiLineTypography;
