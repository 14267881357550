import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { Role } from 'common/types/Role';
import { UserStatus } from 'common/types/UserStatus';
import { useGetCustomerQuery, useEditCustomerMutation } from 'features/api/apiSlice';
import { User, CustomerBody } from 'features/api/types';
import strings from 'common/utils/freeze/strings';
import regex from 'common/utils/freeze/regex';
import pageUrl from 'common/utils/freeze/pageUrl';
import checkBlankAndBlankConvertNull from 'common/utils/checkBlankAndBlankConvertNull';

const { REQUIRED, EMAIL_FORMAT, CUSTOMER_CODE_FORMAT } = strings.editCustomer.validationError;
const {
  SUCCESS,
  ERROR_PERMISSION,
  ERROR_GET_CUSTOMER,
  ERROR_EDIT_CUSTOMER,
  ERROR_EDIT_CUSTOMER_404,
} = strings.editCustomer.completionMessage;

type TextFieldData = {
  value: string,
  onChange: (value: string) => void,
}

type ValidationTextFieldData = TextFieldData & {
  error: string
}

type Return = {
  isLoadingGetCustomer: boolean,
  isDisabledSalesMailField: boolean,
  isLoadingEditCustomer: boolean,
  visibleResendTmpPass: boolean,
  save: () => void,
  errorSupplierMailNotRegisterd: string,
  customerName: ValidationTextFieldData,
  customerCD: ValidationTextFieldData,
  department: TextFieldData,
  manager: TextFieldData,
  accountName: ValidationTextFieldData,
  email: ValidationTextFieldData,
  salesEmail: ValidationTextFieldData,
  movEmail: ValidationTextFieldData,
  tgcEmail: ValidationTextFieldData,
}

const useEditCustomer = (
  customerId: string,
  userInfo: User | undefined,
  isSuccessGetUser: boolean,
): Return => {
  const [customerName, setCustomerName] = useState('');
  const [customerCD, setCustomerCD] = useState('');
  const [department, setDepartment] = useState('');
  const [manager, setManager] = useState('');
  const [accountName, setAccountName] = useState('');
  const [email, setEmail] = useState('');
  const [salesEmail, setSalesEmail] = useState('');
  const [movEmail, setMovEmail] = useState('');
  const [tgcEmail, setTgcEmail] = useState('');

  const [customerNameError, setCustomerNameError] = useState('');
  const [customerCDError, setCustomerCDError] = useState('');
  const [accountNameError, setAccountNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [salesEmailError, setSalesEmailError] = useState('');
  const [movEmailError, setMovEmailError] = useState('');
  const [tgcEmailError, setTgcEmailError] = useState('');

  const [errorSupplierMailNotRegisterd, setErrorSupplierMailNotRegisterd] = useState('');

  const [isDisabledSalesMailField, setIsDisabledSalesMailField] = useState(false);
  const [visibleResendTmpPass, setVisibleResendTmpPass] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  // RTK queryにType Guardが実装されていなかったので、自前で実装しました。そのため `any` を使用。
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isFetchBaseQueryError = (err: any): err is FetchBaseQueryError => err.status !== undefined;

  useEffect(() => {
    if (isSuccessGetUser && userInfo) {
      if (userInfo.role === Role.sales) {
        setIsDisabledSalesMailField(true);
      }
    }
  }, [isSuccessGetUser, userInfo]);

  const {
    data: customer,
    isLoading: isLoadingGetCustomer,
    isSuccess: isSuccessGetCustomer,
    isError: isErrorGetCustomer,
    error: errorGetCustomer,
  } = useGetCustomerQuery(customerId);

  useEffect(() => {
    if (isSuccessGetCustomer && customer) {
      setCustomerName(customer.company);
      setCustomerCD(customer.code);
      setDepartment(customer.department || '');
      if (customer.manager) {
        setManager(customer.manager);
      }
      setAccountName(customer.name);
      setEmail(customer.mail);
      const sales = customer.suppliers.find((supplier) => supplier.role === Role.sales);
      if (sales) {
        setSalesEmail(sales.mail);
      }
      const movring = customer.suppliers.find((supplier) => supplier.role === Role.movring);
      if (movring) {
        setMovEmail(movring.mail);
      }
      const admin = customer.suppliers.find((supplier) => supplier.role === Role.admin);
      if (admin) {
        setTgcEmail(admin.mail);
      }

      if (customer.status && customer.status === UserStatus.forceChangePassword) {
        setVisibleResendTmpPass(true);
      }
    }
  }, [isSuccessGetCustomer, customer]);

  useEffect(() => {
    if (isErrorGetCustomer) {
      if (isFetchBaseQueryError(errorGetCustomer) && errorGetCustomer.status === 403) {
        enqueueSnackbar(ERROR_PERMISSION, { variant: 'error' });
        history.replace({ pathname: pageUrl.TOP });
      } else {
        enqueueSnackbar(ERROR_GET_CUSTOMER, { variant: 'error' });
        history.goBack();
      }
    }
  }, [isErrorGetCustomer, errorGetCustomer, enqueueSnackbar, history]);

  const [editCustomer, {
    isLoading: isLoadingEditCustomer,
  }] = useEditCustomerMutation();

  const handleChangeCustomerName = (value: string) => {
    setCustomerName(value);
  };

  const handleChangeCustomerCD = (value: string) => {
    setCustomerCD(value);
  };

  const handleChangeDepartment = (value: string) => {
    setDepartment(value);
  };

  const handleChangeManager = (value: string) => {
    setManager(value);
  };

  const handleChangeAccountName = (value: string) => {
    setAccountName(value);
  };

  const handleChangeEmail = (value: string) => {
    setEmail(value);
  };

  const handleChangeSalesEmail = (value: string) => {
    setSalesEmail(value);
  };

  const handleChangeMovEmail = (value: string) => {
    setMovEmail(value);
  };

  const handleChangeTgcEmail = (value: string) => {
    setTgcEmail(value);
  };

  const validateRequired = (value: string): string => {
    let errorMessage = '';

    if (value.trim() === '') {
      errorMessage = REQUIRED;
    }

    return errorMessage;
  };

  const validateMail = (value: string): string => {
    let errorMessage = '';

    errorMessage = validateRequired(value);
    if (errorMessage !== '') {
      return errorMessage;
    }

    if (!value.match(regex.EMAIL)) {
      errorMessage = EMAIL_FORMAT;
    }

    return errorMessage;
  };

  const validationCustomerCD = (value: string): string => {
    let errorMessage = '';

    errorMessage = validateRequired(value);
    if (errorMessage !== '') {
      return errorMessage;
    }

    if (!regex.CUSTOMER_CODE.test(value)) {
      errorMessage = CUSTOMER_CODE_FORMAT;
    }

    return errorMessage;
  };

  const validate = (): boolean => {
    const errorCustomerName = validateRequired(customerName);
    const errorCustomerCD = validationCustomerCD(customerCD);
    const errorAccountName = validateRequired(accountName);
    const errorEmail = '';
    const errorSalesEmail = (isDisabledSalesMailField) ? '' : validateMail(salesEmail);
    const errorMovEmail = validateMail(movEmail);
    const errorTgcEmail = validateMail(tgcEmail);

    setCustomerNameError(errorCustomerName);
    setCustomerCDError(errorCustomerCD);
    setAccountNameError(errorAccountName);
    setEmailError(errorEmail);
    setSalesEmailError(errorSalesEmail);
    setMovEmailError(errorMovEmail);
    setTgcEmailError(errorTgcEmail);

    return ((errorCustomerName === '')
      && (errorCustomerCD === '')
      && (errorAccountName === '')
      && (errorEmail === '')
      && (errorSalesEmail === '')
      && (errorMovEmail === '')
      && (errorTgcEmail === ''));
  };

  const save = async () => {
    setErrorSupplierMailNotRegisterd('');

    if (!validate()) {
      return;
    }

    const customerData: CustomerBody = {
      code: customerCD.trim(),
      company: customerName.trim(),
      name: accountName.trim(),
      mail: email.trim(),
      supplier: {
        sales: salesEmail.trim(),
        movring: movEmail.trim(),
        tgc: tgcEmail.trim(),
      },
      department: checkBlankAndBlankConvertNull(department.trim()),
      manager: checkBlankAndBlankConvertNull(manager.trim()),
    };

    if (!isLoadingEditCustomer) {
      try {
        await editCustomer({ customerId, ...customerData }).unwrap();
        enqueueSnackbar(SUCCESS, { variant: 'success' });
        history.push(pageUrl.CUSTOMER_ACCOUNT_LIST);
      } catch (err) {
        if (isFetchBaseQueryError(err) && err.status === 404) {
          setErrorSupplierMailNotRegisterd(ERROR_EDIT_CUSTOMER_404);
          return;
        }
        enqueueSnackbar(ERROR_EDIT_CUSTOMER, { variant: 'error' });
      }
    }
  };

  return {
    isLoadingGetCustomer,
    isDisabledSalesMailField,
    isLoadingEditCustomer,
    visibleResendTmpPass,
    save,
    errorSupplierMailNotRegisterd,
    customerName: {
      value: customerName,
      error: customerNameError,
      onChange: handleChangeCustomerName,
    },
    customerCD: {
      value: customerCD,
      error: customerCDError,
      onChange: handleChangeCustomerCD,
    },
    department: {
      value: department,
      onChange: handleChangeDepartment,
    },
    manager: {
      value: manager,
      onChange: handleChangeManager,
    },
    accountName: {
      value: accountName,
      error: accountNameError,
      onChange: handleChangeAccountName,
    },
    email: {
      value: email,
      error: emailError,
      onChange: handleChangeEmail,
    },
    salesEmail: {
      value: salesEmail,
      error: salesEmailError,
      onChange: handleChangeSalesEmail,
    },
    movEmail: {
      value: movEmail,
      error: movEmailError,
      onChange: handleChangeMovEmail,
    },
    tgcEmail: {
      value: tgcEmail,
      error: tgcEmailError,
      onChange: handleChangeTgcEmail,
    },
  };
};

export default useEditCustomer;
