import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';

const StyledButton = withStyles(() => ({
  root: {
    marginLeft: '-0.5rem',
    height: '100%',
    width: '97px',
    backgroundColor: 'var(--progress-button-dark-grey)',
    borderRadius: '3px',
    color: 'var(--font-color-1)',
    fontSize: 'var(--button-vy-small-font-size)',
    padding: '0.2rem 0.55rem',
    '&:hover': {
      backgroundColor: 'var(--progress-button-dark-grey)',
    },
  },
}))(Button);

type SaveButtonWithCheckIconProps = {
  children: React.ReactNode,
  onClick?: () => void,
};

const SaveButtonWithCheckIcon: React.FC<SaveButtonWithCheckIconProps> = (
  props: SaveButtonWithCheckIconProps,
) => {
  const { children, onClick } = props;

  return (
    <StyledButton
      startIcon={<CheckIcon style={{ color: '#fff' }} />}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

SaveButtonWithCheckIcon.defaultProps = {
  onClick: undefined,
};

export default SaveButtonWithCheckIcon;
