import styled from 'styled-components';
import { useState } from 'react';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import { makeStyles, createStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';

import { Role } from 'common/types/Role';
import strings from 'common/utils/freeze/strings';
import maxLength from 'common/utils/freeze/maxLength';
import SaveButton from 'common/components/atoms/SaveButtonWithCheckIcon';
import Loading from 'common/components/molecules/Loading';
import { ProjectStatus } from 'common/types/ProjectStatus';
import useVideoConfirmation from '../hooks/useVideoConfirmation';
import { confirmationUrlSaveDisabled } from '../utils/videoConfirmation';
import VideoConfirmationBottomContent from './VideoConfirmationBottomContent';
import AcceptAndSendBackDialog from './AcceptAndSendBackDialog';
import Alert from './Alert';
import useCustomerAcceptAlert from '../hooks/useCustomerAcceptAlert';
import useSalesAcceptDialog from '../hooks/useSalesAcceptDialog';
import useSalesSendBackWarningAlert from '../hooks/useSalesSendBackWarningAlert';
import useSalesSendBackDialog from '../hooks/useSalesSendBackDialog';
import useCustomerSendBackDialog from '../hooks/useCustomerSendBackDialog';
import useMovringAndAdminSendBackAlert from '../hooks/useMovringAndAdminSendBackAlert';

const useStyles = makeStyles(() => createStyles({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: '0.7rem 0.5rem',
    backgroundColor: 'var(--download-template-dialog-background)',
    border: '1px solid var(--download-template-dialog-border)',
    boxShadow: '0px 3px 6px var(--download-template-dialog-shadow)',
  },
  iconButton: {
    padding: '0 10px 0 0',
  },
}));

const Container = styled.div`
  width: 70%;
  margin-left: auto;   
  margin-right: auto;
  margin-bottom: 50px;
`;

const TopContent = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
`;

const TextField = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  border-radius: 10px;
  flex: 1;
`;

const Input = styled.input<{ disabledStyled: boolean, error: boolean }>`
  font-size: var(--input-font-size);
  border: 1px solid;
  padding-right: 0.6rem;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  text-indent: 0.5rem;
  border-right: ${(props) => (props.disabledStyled ? '1px solid var(--progress-input-border-color)' : 'none')};
  border-color: ${(props) => (props.error ? '#f44336' : 'var(--progress-input-border-color)')};
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: var(--progress-input-placeholder-color);
  }
`;

const ErrorMessage = styled.div`
  color: #f44336;
  font-size: 12px;
  margin-left: 44px;
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 2rem;
  &::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
`;

const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const VideoConfirmationBottomContentWrapper = styled.div`
  margin: 1.5rem 0 2rem 0;
`;

type VideoConfirmationProps = {
  userRole: Role
  confirmationUrl: string
  projectId: string
  projectStatus: ProjectStatus
}

const VideoConfirmation: React.FC<VideoConfirmationProps> = (props: VideoConfirmationProps) => {
  const {
    userRole, confirmationUrl, projectId, projectStatus,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const classes = useStyles();
  const {
    POPOVER_MESSAGE,
    BUTTON_SAVE,
  } = strings.projectDetail.videoConfirmation;

  const {
    value,
    url,
    isSavaConfirmationUrl,
    urlValidationError,
    urlValidationErrorMessage,
    onChange,
    handleCopy,
    handleSave,
  } = useVideoConfirmation(confirmationUrl, userRole, projectId);

  const [
    customerAcceptAlertProps, customerAcceptAlertOpen,
  ] = useCustomerAcceptAlert(projectId);
  const [
    customerSendBackDialogProps, customerSendBackDialogOpen,
  ] = useCustomerSendBackDialog(projectId);
  const [
    salesAcceptDialogProps, salesAcceptDialogOpen,
  ] = useSalesAcceptDialog(projectId);
  const [
    salesSendBackDialogProps, salesSendBackDialogOpen,
  ] = useSalesSendBackDialog(projectId);
  const [
    salesSendBackWarningAlert, salesSendBackWaringAlertOpen,
  ] = useSalesSendBackWarningAlert(salesSendBackDialogOpen);
  const [
    movringAndAdminSendBackAlertProps, movringAndAdminSendBackAlertOpen,
  ] = useMovringAndAdminSendBackAlert(projectId);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <AcceptAndSendBackDialog
        {...salesAcceptDialogProps}
      />
      <AcceptAndSendBackDialog
        {...salesSendBackDialogProps}
      />
      <AcceptAndSendBackDialog
        {...customerSendBackDialogProps}
      />
      <Alert
        {...salesSendBackWarningAlert}
      />
      <Alert
        {...customerAcceptAlertProps}
      />
      <Alert
        {...movringAndAdminSendBackAlertProps}
      />
      <Loading open={isSavaConfirmationUrl} />
      <TopContent>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          disableAutoFocus
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          {POPOVER_MESSAGE}
        </Popover>
        {/* TODO: アイコン差し替え */}
        <IconButton
          onClick={handleCopy}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          classes={{
            root: classes.iconButton,
          }}
        >
          <FileCopyIcon />
        </IconButton>
        <TextField>
          <Input
            value={value}
            onChange={onChange}
            placeholder="URLを貼り付けてください"
            disabled={confirmationUrlSaveDisabled(projectStatus, userRole)}
            disabledStyled={confirmationUrlSaveDisabled(projectStatus, userRole)}
            error={urlValidationError}
            maxLength={maxLength.CONFIRMATION_URL}
          />
          {!confirmationUrlSaveDisabled(projectStatus, userRole) && (
            <SaveButton
              onClick={handleSave}
            >
              {BUTTON_SAVE}
            </SaveButton>
          )}
        </TextField>
      </TopContent>
      {urlValidationError && <ErrorMessage>{urlValidationErrorMessage}</ErrorMessage>}
      <VideoWrapper>
        <Video src={url} />
      </VideoWrapper>
      <VideoConfirmationBottomContentWrapper>
        <VideoConfirmationBottomContent
          projectStatus={projectStatus}
          userRole={userRole}
          onClickAcceptSales={salesAcceptDialogOpen}
          onClickAcceptCustomer={customerAcceptAlertOpen}
          onClickSendBackSales={salesSendBackWaringAlertOpen}
          onClickSendBackCustomer={customerSendBackDialogOpen}
          onClickSendBackMovringAndAdmin={movringAndAdminSendBackAlertOpen}
        />
      </VideoConfirmationBottomContentWrapper>
    </Container>
  );
};

export default VideoConfirmation;
