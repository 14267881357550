import styled from 'styled-components';
import back from 'assets/images/back.png';

const BackgroundPageStyle = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${back});
  background-color: #fff;
`;

export default BackgroundPageStyle;
