import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import strings from 'common/utils/freeze/strings';

type ReCAPTCHAProps = {
  onChange: ((token: string | null) => void) | undefined
  inputError: string
}

const ReCAPTCHAError = styled.p`
  margin: 0;
  padding-left: 16px;
  font-size: 0.75rem;
  color: #f44336;
`;

const CustomReCAPTCHA: React.FC<ReCAPTCHAProps> = (props: ReCAPTCHAProps) => {
  const { onChange, inputError } = props;

  return (
    <>
      <ReCAPTCHA
        sitekey="6Lfig58cAAAAAMLjPgK9e1VTd6yNeOPUhybkrJlx"
        onChange={onChange}
      />
      {inputError !== ''
          && (
          <ReCAPTCHAError>
            {strings.signIn.validationError.REQUIRED_RECAPTCHA}
          </ReCAPTCHAError>
          )}
    </>
  );
};

export default CustomReCAPTCHA;
