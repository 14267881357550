import { createStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const StyledButton = withStyles(() => createStyles({
  root: {
    borderRadius: 50,
    minWidth: '190px',
    height: '46px',
    fontSize: '22px',
    marginLeft: '5px',
    marginRight: '5px',
    backgroundColor: 'red',
    color: 'var(--background-color-2)',
  },
}))(Button);

type DisableButtonProps = {
  children: React.ReactNode,
  onClick?: () => void,
};

const DisableButton: React.FC<DisableButtonProps> = (props: DisableButtonProps) => {
  const {
    children,
    onClick,
  } = props;

  return (
    <StyledButton
      variant="contained"
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

DisableButton.defaultProps = {
  onClick: undefined,
};

export default DisableButton;
