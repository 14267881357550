import {
  FileRejection, ErrorCode,
} from 'react-dropzone';
import strings from 'common/utils/freeze/strings';

type FileUploadErrorCase = [boolean, boolean, boolean]

const FileUploadError = {
  INVALID_AND_TOO_LARGE: JSON.stringify([true, true, false]),
  INVALID_AND_TOO_SMALL: JSON.stringify([true, false, true]),
  INVALID: JSON.stringify([true, false, false]),
  TOO_LARGE: JSON.stringify([false, true, false]),
  TOO_SMALL: JSON.stringify([false, false, true]),
} as const;

type FileUploadError = typeof FileUploadError[keyof typeof FileUploadError];

const {
  FILE_INVALID_TYPE, FILE_TOO_LARGE, FILE_TOO_SMALL, DEFAULT,
} = strings.projectDetail.completionMessage.uploadFile;

export const createFileUploadErrorCase = (rejectFile: FileRejection): FileUploadErrorCase => {
  const isInvalidType = rejectFile.errors.some(
    (err) => err.code === ErrorCode.FileInvalidType,
  );

  const isTooLarge = rejectFile.errors.some(
    (err) => err.code === ErrorCode.FileTooLarge,
  );

  const isTooSmall = rejectFile.errors.some(
    (err) => err.code === ErrorCode.FileTooSmall,
  );

  return [isInvalidType, isTooLarge, isTooSmall];
};

export const createFileUploadError = (
  fileUploadErrorCase: FileUploadErrorCase,
  rejectFile: FileRejection,
): string => {
  switch (JSON.stringify(fileUploadErrorCase)) {
    case FileUploadError.INVALID_AND_TOO_LARGE:
      return `${rejectFile.file.name}: ${FILE_INVALID_TYPE} ${FILE_TOO_LARGE}`;
    case FileUploadError.INVALID_AND_TOO_SMALL:
      return `${rejectFile.file.name}: ${FILE_INVALID_TYPE} ${FILE_TOO_SMALL}`;
    case FileUploadError.INVALID:
      return `${rejectFile.file.name}: ${FILE_INVALID_TYPE}`;
    case FileUploadError.TOO_LARGE:
      return `${rejectFile.file.name}: ${FILE_TOO_LARGE}`;
    case FileUploadError.TOO_SMALL:
      return `${rejectFile.file.name}: ${FILE_TOO_SMALL}`;
    default:
      return `${rejectFile.file.name}: ${DEFAULT}`;
  }
};
