import { useHistory } from 'react-router-dom';

import TableCell from '@material-ui/core/TableCell';

import pageUrl from 'common/utils/freeze/pageUrl';

type LinkedSupplierCellProps = {
  align: 'center' | 'left' | 'right',
  id: number,
  children: React.ReactNode,
};

const LinkedSupplierCell: React.FC<LinkedSupplierCellProps> = (props: LinkedSupplierCellProps) => {
  const { align, id, children } = props;

  const history = useHistory();
  const onClick = (supplierId: number) => {
    history.push(`${pageUrl.TOPPAN_ACCOUNT_LIST}/${supplierId}`);
  };

  return (
    <TableCell
      align={align}
      onClick={() => onClick(id)}
    >
      {children}
    </TableCell>
  );
};

export default LinkedSupplierCell;
