import { withStyles, createStyles, Theme } from '@material-ui/core/styles';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const StyledBackdrop = withStyles((theme: Theme) => createStyles({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
  },
}))(Backdrop);

type LoadingProps = {
  open: boolean,
  // eslint-disable-next-line react/require-default-props
  progress?: string,
};

const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
  const { open, progress = null } = props;

  return (
    <StyledBackdrop open={open}>
      <CircularProgress color="inherit" />
      <div style={{ position: 'absolute', fontSize: 12 }}>
        {progress}
      </div>
    </StyledBackdrop>
  );
};

export default Loading;
