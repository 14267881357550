import styled from 'styled-components';

import AppButton from 'common/components/atoms/AppButton';
import AppHeader from 'common/components/molecules/AppHeader';
import strings from 'common/utils/freeze/strings';
import Loading from 'common/components/molecules/Loading';
import CustomReCAPTCHA from 'features/auth/components/CustomReCAPTCHA';
import useNewPasswordForm from './hooks/useNewPasswordForm';
import NewPasswordForm from './components/NewPasswordForm';
import useChangePassword from './hooks/useChangePassword';

const Container = styled.div`
  width: 100%;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-1);
  color: var(--font-color-1);
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MainArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  gap: 1.5rem;
`;

const Text = styled.div`
  font-size: var(--big-font-size);
`;

const BottomArea = styled.p`
  background-color: var(--background-color-2);
  margin: 0;
  text-align: end;
  padding: 1.5rem 3rem 1.5rem 0;
`;

const ChangeTemporaryPassword: React.FC = () => {
  const { TEXT_1, TEXT_2 } = strings.changeTemporaryPassword;

  const {
    newPassword,
    newPasswordCheck,
    reCAPTCHAToken,
    handleChangeNewPassword,
    handleChangeNewPasswordCheck,
    handleChangeReCAPTCHAToken,
    newPasswordError,
    newPasswordCheckError,
    reCAPTCHATokenError,
    validationCheck,
  } = useNewPasswordForm();

  const {
    isLoading,
    changePassword,
  } = useChangePassword();

  const handleClick = (password: string) => {
    if (!validationCheck() && reCAPTCHAToken !== null) {
      changePassword(password, reCAPTCHAToken);
    }
  };

  return (
    <Container>
      <Loading open={isLoading} />
      <AppHeader title="仮パスワード変更" />
      <Body>
        <MainArea>
          <Text>{TEXT_1}</Text>
          <Text>{TEXT_2}</Text>
          <NewPasswordForm
            newPassword={newPassword}
            newPasswordCheck={newPasswordCheck}
            handleChangeNewPassword={handleChangeNewPassword}
            handleChangeNewPasswordCheck={handleChangeNewPasswordCheck}
            newPasswordError={newPasswordError}
            newPasswordCheckError={newPasswordCheckError}
          />
          <CustomReCAPTCHA
            onChange={handleChangeReCAPTCHAToken}
            inputError={reCAPTCHATokenError}
          />
        </MainArea>
        <BottomArea>
          <AppButton
            value="登録"
            width="240px"
            onClick={() => handleClick(newPassword)}
          />
        </BottomArea>
      </Body>
    </Container>
  );
};

export default ChangeTemporaryPassword;
