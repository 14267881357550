import styled from 'styled-components';
import { withStyles, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';

import strings from 'common/utils/freeze/strings';
import { Role } from 'common/types/Role';
import AutoCompleteTextField, { AutoCompleteData } from './AutoCompleteTextField';

const { LABEL_ACCOUNT_NAME } = strings.createProjectPage;

const InputComponent = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledTextField = withStyles(() => createStyles({
  root: {
    backgroundColor: grey[300],
  },
}))(TextField);

type AccountNameFieldProps = {
  role: Role,
  data: AutoCompleteData[],
  value: AutoCompleteData,
  onChange: (value: AutoCompleteData) => void,
  errorMessage?: string,
};

const AccountNameField: React.FC<AccountNameFieldProps> = (props: AccountNameFieldProps) => {
  const {
    role,
    value,
    data,
    onChange,
    errorMessage,
  } = props;

  return (
    <InputComponent>
      <Typography variant="body1">
        {LABEL_ACCOUNT_NAME}
      </Typography>
      {(role === Role.customer) ? (
        <StyledTextField
          value={value.label}
          disabled
          variant="outlined"
          fullWidth
        />
      ) : (
        <AutoCompleteTextField
          data={data}
          value={value}
          onChange={onChange}
          errorMessage={errorMessage}
        />
      )}
    </InputComponent>
  );
};

AccountNameField.defaultProps = {
  errorMessage: '',
};

export default AccountNameField;
