import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  PayloadAction,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { apiSlice } from 'features/api/apiSlice';
import { unAuthApiSlice } from 'features/api/unAuthApiSlice';
import projectsReducer from 'features/projects/projectsSlice';
import customersReducer from 'features/customers/customersSlice';
import suppliersReducer from 'features/suppliers/suppliersSlice';
import firstLoginUserInfoReducer from 'features/auth/firstLoginUserInfoSlice';

const appReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  [unAuthApiSlice.reducerPath]: unAuthApiSlice.reducer,
  projects: projectsReducer,
  customers: customersReducer,
  suppliers: suppliersReducer,
  firstLoginuserInfo: firstLoginUserInfoReducer,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: PayloadAction) => {
  if (action.type === 'RESET_STATE') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['firstLoginUserInfo/changeFirstLoginUserInfo'],
    },
  }).concat(apiSlice.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof appReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
