import CustomTextField from 'common/components/molecules/CustomTextField';
import strings from 'common/utils/freeze/strings';
import maxLength from 'common/utils/freeze/maxLength';

type NewPasswordFormProps = {
  newPassword: string
  newPasswordCheck: string
  handleChangeNewPassword: (value: string) => void
  handleChangeNewPasswordCheck: (value: string) => void
  newPasswordError: string
  newPasswordCheckError: string
}

const NewPasswordForm: React.FC<NewPasswordFormProps> = (props: NewPasswordFormProps) => {
  const {
    newPassword,
    newPasswordCheck,
    handleChangeNewPassword,
    handleChangeNewPasswordCheck,
    newPasswordError,
    newPasswordCheckError,
  } = props;

  const { NEW_PASSWORD, NEW_PASSWORD_CHECK } = strings.changeTemporaryPassword.INPUT_LABEL;

  return (
    <>
      <CustomTextField
        value={newPassword}
        onChange={handleChangeNewPassword}
        label={NEW_PASSWORD}
        width="60%"
        error={newPasswordError !== ''}
        helperText={newPasswordError}
        type="password"
        maxLength={maxLength.PASSWORD}
        placeholder={strings.INPUT_PLACEHOLDER}
      />
      <CustomTextField
        value={newPasswordCheck}
        onChange={handleChangeNewPasswordCheck}
        label={NEW_PASSWORD_CHECK}
        width="60%"
        error={newPasswordCheckError !== ''}
        helperText={newPasswordCheckError}
        type="password"
        maxLength={maxLength.PASSWORD}
        placeholder={strings.INPUT_PLACEHOLDER}
      />
    </>
  );
};

export default NewPasswordForm;
