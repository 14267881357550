import CustomTextField from 'common/components/molecules/CustomTextField';

type InputFieldProps = {
  value: string,
  label: string,
  placeholder: string,
  error: string,
  onChange: (value: string) => void,
  type?: string,
  maxLength?: number
};

const InputField: React.FC<InputFieldProps> = (
  props: InputFieldProps,
) => {
  const {
    value,
    label,
    placeholder,
    error,
    onChange,
    type,
    maxLength,
  } = props;

  return (
    <CustomTextField
      value={value}
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      error={(error !== '')}
      helperText={error}
      type={type}
      width="613px"
      margin="50px"
      maxLength={maxLength}
    />
  );
};

InputField.defaultProps = {
  type: undefined,
  maxLength: undefined,
};

export default InputField;
