import {
  Stepper, StepButton, Step, makeStyles, StepLabel, withStyles, StepConnector,
} from '@material-ui/core';
import { ProjectStatus } from 'common/types/ProjectStatus';
import { Role } from 'common/types/Role';
import { StepStatus } from '../type';
import { checkActiveStep, stepButtonDisabled } from '../utils/step';

const styles = makeStyles(() => ({
  root: {
    width: '35px',
    height: '35px',
    '&$active': {
      color: 'var(--stepper-active-color)',
    },
  },
  active: {},
}));

const StepperConnector = withStyles({
  active: {
    '& $line': {
      borderColor: 'var(--stepper-active-color)',
    },
  },
  line: {
    borderColor: 'var(--stepper-line-color)',
  },
})(StepConnector);

type StepperProps = {
  activeStep: StepStatus
  handleStep: (step: StepStatus) => void
  projectStatus: ProjectStatus
  userRole: Role
}

const StepperComponent: React.FC<StepperProps> = (props: StepperProps) => {
  const {
    activeStep, handleStep, projectStatus, userRole,
  } = props;
  const classes = styles();
  const steps = ['入稿', '動画確認', '納品'];

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={<StepperConnector />}
      nonLinear
    >
      {steps.map((label, index) => (
        <Step key={label} active={checkActiveStep(activeStep, index)}>
          <StepButton
            onClick={() => handleStep(index)}
            disabled={stepButtonDisabled(projectStatus, userRole, index)}
          >
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.root,
                  active: classes.active,
                },
              }}
            >
              {label}
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepperComponent;
