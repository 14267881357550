import styled from 'styled-components';
import { withStyles, createStyles } from '@material-ui/core/styles';
import MuiTablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const PaginationButton = styled.div`
  flex-shrink: 0;
`;

type TablePaginationActionsProps = {
  count: number,
  page: number,
  rowsPerPage: number,
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void,
};

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const {
    count,
    page,
    rowsPerPage,
    onPageChange,
  } = props;

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  return (
    <PaginationButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      {page + 1}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </PaginationButton>
  );
};

const Patination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
`;

const StypedTablePagination = withStyles(() => createStyles({
  root: {
    '& .MuiToolbar-gutters': {
      padding: 0,
    },
  },
}))(MuiTablePagination);

type TablePaginationProps = {
  count: number,
  page: number,
  rowsPerPage: number,
  onPageChange: (event: unknown, newPage: number) => void,
};

const TablePagination: React.FC<TablePaginationProps> = (props: TablePaginationProps) => {
  const {
    count,
    page,
    rowsPerPage,
    onPageChange,
  } = props;

  return (
    <Patination>
      <PageAction>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={4} />
          <Grid item xs={4}>
            <StypedTablePagination
              rowsPerPageOptions={[]}
              labelRowsPerPage=""
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onPageChange}
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={() => ''}
            />
          </Grid>
          <Grid item xs={4} alignItems="center">
            <Typography variant="body2">
              {
                (count === 0) ? '' : `${page * rowsPerPage + 1}-${(page * rowsPerPage + rowsPerPage) <= count ? page * rowsPerPage + rowsPerPage : count} of ${count}`
              }
            </Typography>
          </Grid>
        </Grid>
      </PageAction>
    </Patination>
  );
};

export default TablePagination;
