import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { Button, withStyles } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Loading from 'common/components/molecules/Loading';
import { Submission } from 'features/api/types';
import SubmissionFileTable from './SubmissionFileTable';
import useDropFile from '../hooks/useDropFile';
import useSubmission from '../hooks/useSubmission';
import SubmitDialog from './SubmitDialog';
import SubmitFileList from './SubmitFileList';
import { findSubmission } from '../utils/createDisplayFiles';
import useDownloadZip from '../hooks/useDownloadZip';

const Container = styled.div`
  margin-left: auto;   
  margin-right: auto;
  margin-bottom: 50px;
  width: 88%;
  background-color: inherit;
  border: 1px solid var(--progress-file-form-border-color);
  border-radius: 5px;
`;

const Header = styled.div`
  padding: 0.15rem 0.4rem;
  border-bottom: 1px solid var(--progress-file-form-border-color);
`;

const GreyButton = withStyles({
  root: {
    fontSize: 'var(--button-vy-small-font-size)',
    color: 'var(--theme-color)',
    backgroundColor: 'var(--progress-button-grey)',
    border: '1px solid var(--progress-button-white)',
    marginRight: '0.5rem',
  },
})(Button);

const FileTableWrapper = styled.div`
  height: 198px;
`;

type SubmissionFileFormProps = {
  submissions: Submission[];
  projectId: string;
  refetchProjectData: () => void
}

const SubmissionFileForm: React.FC<SubmissionFileFormProps> = (props: SubmissionFileFormProps) => {
  const {
    submissions, projectId, refetchProjectData,
  } = props;

  const [selectedRadio, setSelectedRadio] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState<string | null>(null);

  const {
    uploadFiles, getRootProps, getInputProps, open, resetFiles,
  } = useDropFile();

  const {
    isLoading, progress, submit,
  } = useSubmission(projectId, uploadFiles, refetchProjectData);

  const {
    downloadZipLoading, downloadZip,
  } = useDownloadZip(projectId, 'DRAFT', selectedRadio);

  const handleClose = () => {
    setDialogOpen(false);
    resetFiles();
  };

  const handleSaveFile = async () => {
    setDialogOpen(false);
    await submit();
    resetFiles();
  };

  return (

    <Container>
      <Loading open={isLoading || downloadZipLoading} progress={progress} />
      <SubmitDialog
        dialogOpen={dialogOpen}
        handleClose={handleClose}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        handleSaveFile={handleSaveFile}
        files={uploadFiles}
        open={open}
        isLoading={isLoading}
      />
      <SubmitFileList
        dialogOpen={!!selectedSubmission}
        submission={
          selectedSubmission ? findSubmission(submissions, selectedSubmission) : undefined
        }
        handleClose={() => { setSelectedSubmission(null); }}
        projectId={projectId}
        refetchProjectData={refetchProjectData}
      />
      <Header>
        <GreyButton
          startIcon={<CloudUploadIcon />}
          onClick={() => { setDialogOpen(true); }}
        >
          入稿
        </GreyButton>
        <GreyButton
          startIcon={<CloudDownloadIcon />}
          onClick={downloadZip}
          disabled={selectedRadio === ''}
        >
          ダウンロード
        </GreyButton>
      </Header>
      <FileTableWrapper>
        <SubmissionFileTable
          submissions={submissions}
          selectedRadio={selectedRadio}
          handleSelectedRadio={setSelectedRadio}
          handleSelectedSubmisson={setSelectedSubmission}
        />
      </FileTableWrapper>
    </Container>
  );
};

export default SubmissionFileForm;
