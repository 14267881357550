import { useState } from 'react';
import { useSnackbar } from 'notistack';

import getFileUploadUrl from 'features/api/axios/getFileUploadUrl';
import presignedUrlUploadFile from 'features/api/axios/presignedUrlUploadFIle';

type UseDeliveryFileReturn = {
  isLoading: boolean
  progress: string
  deliveryFile: () => Promise<void>
}

const useDeliveryFile = (
  projectId: string,
  files: File[],
  refetchProjectData: () => void,
): UseDeliveryFileReturn => {
  const [progress, setProgress] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const deliveryFile = async () => {
    if (files.length === 0 || isLoading) {
      return;
    }

    setIsLoading(true);
    // eslint-disable-next-line no-restricted-syntax, no-plusplus
    for (let i = 0; i < files.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      const res1 = await getFileUploadUrl(projectId, 'PRODUCTION', files[i].name, null);
      if (res1.error) {
        enqueueSnackbar(res1.error, { variant: 'error' });
        break;
      }

      if (res1.response) {
        // eslint-disable-next-line no-await-in-loop
        const res2 = await presignedUrlUploadFile(files[i], res1.response.url);
        if (res2.error) {
          enqueueSnackbar(res2.error, { variant: 'error' });
          break;
        }
      }
      setProgress(`${Math.ceil(((i + 1) / files.length) * 100)}%`);
    }

    setIsLoading(false);
    setProgress('');
    enqueueSnackbar('納品完了しました', { variant: 'success' });
    refetchProjectData();
  };

  return {
    isLoading, progress, deliveryFile,
  };
};

export default useDeliveryFile;
