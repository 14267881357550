import AppButton from 'common/components/atoms/AppButton';
import styled from 'styled-components';
import strings from 'common/utils/freeze/strings';
import ActionDialog from './ActionDialog';

const DialogContent = styled.div`
  padding: 0 3rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
`;

const Text = styled.div`
  color: var(--theme-color);
  font-size: var(--medium-font-size);
  margin: 3rem 0;
`;

const BottomContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

type Props = {
  open: boolean
  onClose: () => void
  handleDeleteFile: () => Promise<void>
}

const FileDeleteDialog: React.FC<Props> = (props: Props) => {
  const { open, onClose, handleDeleteFile } = props;
  const { DIALOG_TITLE, TEXT } = strings.projectDetail.fileDeleteDialog;

  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={DIALOG_TITLE}
      titleContent={null}
    >
      <DialogContent>
        <Text>{TEXT}</Text>
        <BottomContent>
          <AppButton
            value="キャンセル"
            border="1px solid var(--theme-color)"
            backgroundColor="var(--cancel-button-backgroud-color)"
            color="var(--theme-color)"
            width="190px"
            onClick={onClose}
          />
          <AppButton
            value="OK"
            border="1px solid var(--theme-color)"
            backgroundColor="var(--theme-color)"
            width="190px"
            onClick={handleDeleteFile}
          />
        </BottomContent>
      </DialogContent>
    </ActionDialog>
  );
};

export default FileDeleteDialog;
