import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TextField from '@material-ui/core/TextField';
import Loading from 'common/components/molecules/Loading';
import SaveButton from 'common/components/atoms/SaveButtonWithCheckIcon';
import PopoverComponent from 'common/components/molecules/PopoverComponent';
import { ProjectNote } from 'features/api/types';
import { format } from 'date-fns';
import maxLength from 'common/utils/freeze/maxLength';

import strings from 'common/utils/freeze/strings';
import useMessageNote from '../hooks/useMessageNote';

const {
  MESSAGE_NOTE,
  BUTTON_SEND,
  MESSAGE_NOTE_POPOVER_MESSAGE,
  LAST_UPDATE,
} = strings.projectDetail.projectInfo;

const LabelAndButtonArea = styled.div`
  display: flex;
  align-items: right;
`;

const ButtonArea = styled.div`
  margin: 0 0 0 10px;
  height: 30px;
  padding: 5px;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
`;

const StyledTextField = withStyles(() => ({
  root: {
    marginBottom: '40px',
    backgroundColor: 'white',
  },
}))(TextField);

const LastStatus = styled.div`
  margin: 0 0 0 auto;
`;

const useStyles = makeStyles(() => ({
  inputRoot: {
    '&$disabled': {
      color: 'grey',
    },
  },
  disabled: {},
}));

type ProjectInfoTextboxProps = {
  projectNote: ProjectNote | null,
  projectId: number,
  setErrorDialogOpen: (conflict: boolean) => void,
}

const ProjectInfoTextbox: React.FC<ProjectInfoTextboxProps> = (props: ProjectInfoTextboxProps) => {
  const classes = useStyles();
  const {
    projectNote,
    projectId,
    setErrorDialogOpen,
  } = props;

  const {
    value,
    onChange,
    onClickSave,
    isLoading,
  } = useMessageNote({
    content: projectNote?.content ?? 'プロジェクト作成ありがとうございます。\n「入稿」をクリックして、指示書・素材をアップロードしてください。',
    id: projectId,
    updateAt: projectNote?.updated_at,
    setErrorDialogOpen,
  });

  const epochToString = (epoch: number) => format(new Date(epoch * 1000), 'yyyy/MM/dd HH:mm:ss');

  return (
    <>
      <Loading open={isLoading} />
      <LabelAndButtonArea>
        <Typography variant="body1">
          {MESSAGE_NOTE}
        </Typography>
        <PopoverComponent
          message={MESSAGE_NOTE_POPOVER_MESSAGE}
        >
          <Icon>
            <InfoOutlinedIcon />
          </Icon>
        </PopoverComponent>
        {(projectNote !== null) ? (
          <LastStatus>
            {LAST_UPDATE}
            {epochToString(projectNote.updated_at)}
            {' '}
            {projectNote.written_by}
          </LastStatus>
        ) : (<LastStatus />)}
        <ButtonArea>
          <SaveButton onClick={onClickSave}>
            {BUTTON_SEND}
          </SaveButton>
        </ButtonArea>
      </LabelAndButtonArea>
      <StyledTextField
        multiline
        variant="outlined"
        fullWidth
        minRows="5"
        maxRows="5"
        value={value}
        onChange={onChange}
        inputProps={{
          maxLength: maxLength.NOTE,
        }}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            disabled: classes.disabled,
          },
        }}
      />
    </>
  );
};

export default ProjectInfoTextbox;
