import { useState } from 'react';
import { useSnackbar } from 'notistack';

import strings from 'common/utils/freeze/strings';
import { useResendTmpPasswordSupplierMutation } from 'features/api/apiSlice';

const { SUCCESS, ERROR } = strings.editSupplier.resendTmpPassword.completionMessage;

type Return = {
  isLoading: boolean,
  open: boolean,
  onClickResendButton: () => void,
  onClickCancel: () => void,
  onClickOk: () => void,
}

const useResendTmpPassword = (
  supplierId: string,
): Return => {
  const [open, setOpen] = useState(false);

  const [resendTmpPasswordSupplier, {
    isLoading,
  }] = useResendTmpPasswordSupplierMutation();

  const { enqueueSnackbar } = useSnackbar();

  const onClickResendButton = () => {
    setOpen(true);
  };

  const onClickCancel = () => {
    setOpen(false);
  };
  const onClickOk = async () => {
    setOpen(false);

    if (!isLoading) {
      try {
        await resendTmpPasswordSupplier(supplierId).unwrap();
        enqueueSnackbar(SUCCESS, { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(ERROR, { variant: 'error' });
      }
    }
  };

  return {
    isLoading,
    open,
    onClickResendButton,
    onClickCancel,
    onClickOk,
  };
};

export default useResendTmpPassword;
