import { useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { useSnackbar } from 'notistack';

import regex from 'common/utils/freeze/regex';
import strings from 'common/utils/freeze/strings';
import { useChangePasswordMutation } from 'features/api/apiSlice';
import { ChangePasswordBody } from 'features/api/types';

const {
  REQUIRED, PASSWORD_FORMAT, CONFIRM_ERROR_NEW_PASSWORD, CONFIRM_ERROR_CONFIRM_PASSWORD,
} = strings.changePassword.validationError;
const { SUCCESS, ERROR_400, ERROR_OTHER } = strings.changePassword;

type useChangePasswordReturn = {
  save: () => void,
  isLoading: boolean,
  currentPassword: {
    value: string,
    error: string,
    onChange: (value: string) => void,
  },
  newPassword: {
    value: string,
    error: string,
    onChange: (value: string) => void,
  },
  confirmPassword: {
    value: string,
    error: string,
    onChange: (value: string) => void,
  },
};

const useChangePassword = (): useChangePasswordReturn => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [errorCurrentPassword, setErrorCurrentPassword] = useState('');

  const [newPassword, setNewPassword] = useState('');
  const [errorNewPassword, setErrorNewPassword] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');

  const [changePassword, {
    isLoading,
  }] = useChangePasswordMutation();

  const { enqueueSnackbar } = useSnackbar();

  const validateCurrentPassword = (value: string): boolean => {
    if (value === '') {
      setErrorCurrentPassword(REQUIRED);
      return false;
    }

    setErrorCurrentPassword('');
    return true;
  };

  const handleChangeCurrentPassword = (value: string) => {
    setCurrentPassword(value);
  };

  const validateNewPassword = (value: string): boolean => {
    if (value === '') {
      setErrorNewPassword(REQUIRED);
      return false;
    }

    if (!regex.PASSWORD.test(value)) {
      setErrorNewPassword(PASSWORD_FORMAT);
      return false;
    }

    setErrorNewPassword('');
    return true;
  };

  const handleChangeNewPassword = (value: string) => {
    setNewPassword(value);
  };

  const validateConfirmPassword = (value: string): boolean => {
    if (value === '') {
      setErrorConfirmPassword(REQUIRED);
      return false;
    }

    if (value !== newPassword) {
      setErrorNewPassword(CONFIRM_ERROR_NEW_PASSWORD);
      setErrorConfirmPassword(CONFIRM_ERROR_CONFIRM_PASSWORD);
      setNewPassword('');
      setConfirmPassword('');
      return false;
    }

    setErrorConfirmPassword('');
    return true;
  };

  const handleChangeConfirmPassword = (value: string) => {
    setConfirmPassword(value);
  };

  const validate = (): boolean => {
    const resultCurrent = validateCurrentPassword(currentPassword);
    const resultNew = validateNewPassword(newPassword);
    const resultConfirm = validateConfirmPassword(confirmPassword);
    return resultCurrent && resultNew && resultConfirm;
  };

  const clean = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const handleSave = async () => {
    if (!validate()) {
      return;
    }

    const requestBody: ChangePasswordBody = {
      previous_password: currentPassword,
      proposed_password: newPassword,
    };

    if (!isLoading) {
      try {
        await changePassword(requestBody).unwrap();
        clean();
        enqueueSnackbar(SUCCESS, { variant: 'success' });
      } catch (e) {
        const error = e as FetchBaseQueryError;
        if (error.status === 400) {
          enqueueSnackbar(ERROR_400, { variant: 'error' });
        } else {
          enqueueSnackbar(ERROR_OTHER, { variant: 'error' });
        }
      }
    }
  };

  return {
    save: handleSave,
    isLoading,
    currentPassword: {
      value: currentPassword,
      error: errorCurrentPassword,
      onChange: handleChangeCurrentPassword,
    },
    newPassword: {
      value: newPassword,
      error: errorNewPassword,
      onChange: handleChangeNewPassword,
    },
    confirmPassword: {
      value: confirmPassword,
      error: errorConfirmPassword,
      onChange: handleChangeConfirmPassword,
    },
  };
};

export default useChangePassword;
