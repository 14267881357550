import Button from '@material-ui/core/Button';

import AlertDialog from 'common/components/molecules/AlertDialog';
import strings from 'common/utils/freeze/strings';

const { CANCEL, OK } = strings.confirmDialog;

type ConfirmDialogProps = {
  title: string,
  description: string,
  open: boolean,
  onClickCancel: () => void,
  onClickOk: () => void,
};

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props: ConfirmDialogProps) => {
  const {
    title,
    description,
    open,
    onClickCancel,
    onClickOk,
  } = props;

  return (
    <AlertDialog
      title={title}
      description={description}
      open={open}
    >
      <Button onClick={onClickCancel} color="primary" autoFocus>
        {CANCEL}
      </Button>
      <Button onClick={onClickOk} color="primary" autoFocus>
        {OK}
      </Button>
    </AlertDialog>
  );
};

export default ConfirmDialog;
