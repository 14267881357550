import { useMemo, useState } from 'react';
import {
  Route, RouteProps, Redirect,
} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Loading from 'common/components/molecules/Loading';
import pageUrl from 'common/utils/freeze/pageUrl';

const AuthenticatedRoute: React.FC<RouteProps> = (props) => {
  const [isSingedIn, setIsSignedIn] = useState(true);
  const [loadingScreen, setLoadingScreen] = useState(true);

  useMemo(() => {
    const checkAuth = async () => {
      setLoadingScreen(true);
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        setIsSignedIn(true);
      } catch {
        setIsSignedIn(false);
      }
      setLoadingScreen(false);
    };
    checkAuth();
  }, []);

  if (loadingScreen) {
    return <Loading open />;
  }
  return (
    isSingedIn
      // eslint-disable-next-line react/jsx-props-no-spreading
      ? <Route {...props} /> : (
        <Redirect to={{
          pathname: pageUrl.SIGNIN,
        }}
        />
      )
  );
};

export default AuthenticatedRoute;
