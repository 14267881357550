import styled from 'styled-components';
import MailIcon from '@material-ui/icons/Mail';
import { useHistory } from 'react-router-dom';

import AppButton from 'common/components/atoms/AppButton';
import AppHeader from 'common/components/molecules/AppHeader';
import AppSidebar from 'common/components/orgnisms/AppSidebar';
import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: var(--background-color-2);
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const Area = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopArea = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: var(--background-color-1);
  overflow: scroll;
`;

const Contents = styled.div`
  height: 65%;
  min-height: 340px;
  width: 70%;
  border: 1px solid var(--border-color);
  box-shadow: 3px 3px 6px var(--border-shadow-color);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 1rem;
`;

const TopContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`;

const Title = styled.p`
  font-size: var(--big-font-size);
  font-weight: lighter;
  margin: 0;
`;

const Text = styled.p`
  text-align: left;
  display: inline-block;
  margin: 0;
  font-size: var(--medium-font-size);
`;

const BottomArea = styled.div`
  height: 100px;
`;

const TentativeSignUpComplete: React.FC<{ text: string }> = (props: { text: string }) => {
  const { text } = props;
  const {
    TENTATIVE_SIGN_UP_COMPLETE_TITLE,
    TENTATIVE_SIGN_UP_COMPLETE_1,
    TENTATIVE_SIGN_UP_COMPLETE_2,
    MOVE_TOP_BUTTON_TEXT,
  } = strings.tentativeSignUpComplete;

  const history = useHistory();

  const handleClick = () => {
    history.push({ pathname: pageUrl.TOP });
  };

  return (
    <PageContainer>
      <AppHeader />
      <Body>
        <AppSidebar initState={false} />
        <Area>
          <TopArea>
            <Contents>
              <TopContents>
                <Title>{TENTATIVE_SIGN_UP_COMPLETE_TITLE}</Title>
                {/* TODO: アイコンが共有され次第変更 */}
                <MailIcon style={{ fontSize: 42, margin: '0.3rem 0' }} />
                <Text>{`${text}${TENTATIVE_SIGN_UP_COMPLETE_1}`}</Text>
                <Text>{TENTATIVE_SIGN_UP_COMPLETE_2}</Text>
              </TopContents>
              <AppButton
                width="250px"
                border="1px solid var(--border-color)"
                value={MOVE_TOP_BUTTON_TEXT}
                onClick={handleClick}
              />
            </Contents>
          </TopArea>
          <BottomArea />
        </Area>
      </Body>
    </PageContainer>
  );
};

export default TentativeSignUpComplete;
