import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import BackgroundPageStyle from 'common/styles/BackgroundPageStyle';
import AppHeader from 'common/components/molecules/AppHeader';
import AppSidebar from 'common/components/orgnisms/AppSidebar';
import { useGetProjectQuery, useGetUserQuery } from 'features/api/apiSlice';
import Loading from 'common/components/molecules/Loading';
import strings from 'common/utils/freeze/strings';
import Progress from './components/Progress';
import ProjectInfo from './components/ProjectInfo';

const Body = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const MainArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
`;

const ScrollArea = styled.div`
  min-width: 1024px;
`;

const TopArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const UpdatedBy = styled.div`
  margin: 15px 30px 5px 0;
  font-size: 14px;
  text-align: right;
`;

type ProjectDetailProps = RouteComponentProps<{
  projectId: string;
}>;

const ProjectDetail: React.FC<ProjectDetailProps> = (props: ProjectDetailProps) => {
  const { match } = props;
  const { projectId } = match.params;
  const { getProject, getUser } = strings.projectDetail.completionMessage;
  const { enqueueSnackbar } = useSnackbar();

  const {
    data,
    isLoading: isGetProjectLoading,
    isSuccess,
    isError,
    refetch,
  } = useGetProjectQuery(projectId);

  const {
    data: userInfo,
    isSuccess: fetchUserIsSuccess,
    isError: fetchUserError,
    isLoading: isGetUserLoading,
  } = useGetUserQuery();

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(getProject.ERROR, { variant: 'error' });
    }
  }, [isError, enqueueSnackbar, getProject.ERROR]);

  useEffect(() => {
    if (fetchUserError) {
      enqueueSnackbar(getUser.ERROR, { variant: 'error' });
    }
  }, [fetchUserError, enqueueSnackbar, getUser.ERROR]);

  return (
    <BackgroundPageStyle>
      <AppHeader title="プロジェクト詳細" />
      <Body>
        <AppSidebar initState={false} />
        <MainArea>
          <ScrollArea>
            <Loading open={isGetProjectLoading || isGetUserLoading} />
            {isSuccess && data && fetchUserIsSuccess && userInfo && (
              <>
                <TopArea>
                  <UpdatedBy>{`最終更新: ${data.updated_by}`}</UpdatedBy>
                </TopArea>
                <ProjectInfo
                  project={data}
                  userRole={userInfo.role}
                  refetch={refetch}
                />
                <Progress
                  projectStatus={data.status}
                  projectId={projectId}
                  refetchProjectData={refetch}
                  submissions={data.submissions}
                  productions={data.productions}
                  userRole={userInfo.role}
                  confirmationUrl={data.confirmation_url || ''}
                />
              </>
            )}
          </ScrollArea>
        </MainArea>
      </Body>
    </BackgroundPageStyle>
  );
};

export default ProjectDetail;
