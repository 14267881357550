import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

type AlertDialogProps = {
  title: string,
  description: string,
  open: boolean,
  onClose?: () => void,
  children: React.ReactNode
};

const AlertDialog: React.FC<AlertDialogProps> = (props: AlertDialogProps) => {
  const {
    title,
    description,
    open,
    onClose,
    children,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {children}
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.defaultProps = {
  onClose: undefined,
};

export default AlertDialog;
