import styled from 'styled-components';
import { Link } from 'react-router-dom';

import pageUrl from 'common/utils/freeze/pageUrl';
import logo from 'assets/images/logo.png';

const HeaderWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
  font-weight: 100;
  height: 90px;
  font-size: var(--header-title-font-size);
  color: var(--theme-color);
  box-shadow: 0px 3px 6px var(--border-shadow-color);
  background-color: var(--header-background-color);
  position: fixed;
  width: 100%;
  z-index: 3;
`;

const AppHeaderMargin = styled.div`
  height: 90px;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
`;

const Title = styled.p`
  margin: 0;
  margin-left: 4rem;
`;

const RightContent = styled.div`
  margin-right: 1.6rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;
  margin-left: 0.5rem;
`;

type AppHeaderProps = {
  title?: string
  children?: React.ReactNode
}

const AppHeader: React.FC<AppHeaderProps> = (props: AppHeaderProps) => {
  const {
    title, children,
  } = props;

  return (
    <>
      <HeaderWrapper>
        <LeftContent>
          <StyledLink to={pageUrl.TOP}>
            <img src={logo} alt="logo" height="50px" />
          </StyledLink>
          <Title>{title}</Title>
        </LeftContent>
        <RightContent>
          {children}
        </RightContent>
      </HeaderWrapper>
      <AppHeaderMargin />
    </>
  );
};

AppHeader.defaultProps = {
  title: '',
  children: '',
};

export default AppHeader;
