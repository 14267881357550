import styled from 'styled-components';
import { Button, withStyles } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Submission } from 'features/api/types';
import { useState } from 'react';
import AppButton from 'common/components/atoms/AppButton';
import Loading from 'common/components/molecules/Loading';
import ActionDialog from './ActionDialog';
import SubmitFileListTable from './SubmitFileListTable';
import FileDeleteField from './FileDeleteButtonField';
import useDownloadZip from '../hooks/useDownloadZip';
import useDownloadFile from '../hooks/useDownloadFile';

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 75vh;
  overflow: hidden;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  gap: 0.6rem;
  padding: 2px 0px 2px 0.6rem;
`;

const TableContainer = styled.div`
  overflow: scroll;
  flex: 1;
  width: 100%;
`;

const GreyButton = withStyles({
  root: {
    fontSize: 'var(--button-vy-small-font-size)',
    color: 'var(--theme-color)',
    backgroundColor: 'var(--progress-button-grey)',
    border: '1px solid var(--progress-button-white)',
  },
})(Button);

type SubmitFileListProps = {
  dialogOpen: boolean;
  handleClose: () => void;
  submission: Submission | undefined;
  projectId: string;
  refetchProjectData: () => void;
}

const SubmitFileList: React.FC<SubmitFileListProps> = (props: SubmitFileListProps) => {
  const {
    dialogOpen, handleClose, submission, projectId, refetchProjectData,
  } = props;
  const [selectedFileId, setSelectedFileId] = useState(0);

  const {
    downloadZip, downloadZipLoading,
  } = useDownloadZip(projectId, 'DRAFT', submission ? submission.submit_id : null);

  const {
    downloadFileLoading, downloadFile,
  } = useDownloadFile(projectId, selectedFileId);

  const onClose = () => {
    setSelectedFileId(0);
    handleClose();
  };

  return (
    <ActionDialog
      open={dialogOpen}
      onClose={onClose}
      maxWidth="lg"
      title="入稿ファイルリスト"
      titleContent={null}
    >
      <Loading open={downloadZipLoading || downloadFileLoading} />
      <DialogContent>
        <Header>
          <GreyButton
            startIcon={<CloudDownloadIcon />}
            onClick={downloadZip}
          >
            全てダウンロード
          </GreyButton>
          <GreyButton
            startIcon={<CloudDownloadIcon />}
            disabled={selectedFileId === 0}
            onClick={downloadFile}
          >
            ダウンロード
          </GreyButton>
          <FileDeleteField
            projectId={projectId}
            selected={selectedFileId}
            refetchProjectData={refetchProjectData}
            resetSelected={() => { setSelectedFileId(0); }}
          />
        </Header>
        <TableContainer>
          <SubmitFileListTable
            files={submission ? submission.drafts : []}
            selectedFileId={selectedFileId}
            handleSelectedFile={(fileId: number) => { setSelectedFileId(fileId); }}
          />
        </TableContainer>
        <div style={{ margin: '20px' }}>
          <AppButton
            value="閉じる"
            width="192px"
            onClick={onClose}
            backgroundColor="white"
            color="black"
            border="1px solid black"
          />
        </div>
      </DialogContent>
    </ActionDialog>
  );
};

export default SubmitFileList;
