import styled, { css } from 'styled-components';
import strings from 'common/utils/freeze/strings';

type SignInButtonProps = {
  onClick: () => void
  disabled?: boolean
}

const BottomContents = styled.div`
  padding-top: 1.4rem;
  text-align: end;
`;

const disabledButtonStyle = css`
  opacity: 0.5;
  cursor: default;
`;
const SubmitButton = styled.button`
  padding: 0.8rem 0;
  background-color: var(--background-color-2);
  color: var(--font-color-black);
  font-size: var(--font-size-16);
  width: 100%;
  line-height: 1;
  border-radius: 1.6rem;
  border: none;
  box-shadow: 0px 3px 6px var(--border-shadow-color);
  cursor: pointer;
  ${(props) => props.disabled && disabledButtonStyle}
`;

const SignInButton: React.FC<SignInButtonProps> = (props: SignInButtonProps) => {
  const { onClick, disabled } = props;
  return (
    <BottomContents>
      <SubmitButton onClick={onClick} disabled={disabled}>
        {strings.signIn.SUBMIT_SIGNIN}
      </SubmitButton>
    </BottomContents>
  );
};

SignInButton.defaultProps = {
  disabled: false,
};

export default SignInButton;
