import CustomTextField from 'common/components/molecules/CustomTextField';
import strings from 'common/utils/freeze/strings';
import maxLength from 'common/utils/freeze/maxLength';
import PopoverInfoTextField from 'common/components/molecules/PopoverInfoTextField';

type UserDataFromProps = {
  company: string
  department: string
  manager: string
  name: string
  email: string
  handleChangeDepartment: (value: string) => void
  handleChangeManager: (value: string) => void
  managerError: string
}

const UserDataForm: React.FC<UserDataFromProps> = (props: UserDataFromProps) => {
  const {
    COMPANY, DEPARTMENT, RESPONSIBLE_PARTY, PERSON_IN_CHARGE, EMAIL,
  } = strings.editAccountData.TEXT_LABEL;
  const
    { MANAGER_MESSAGE } = strings.editAccountData;
  const width = '60%';

  const {
    company,
    department,
    manager,
    name,
    email,
    handleChangeDepartment,
    handleChangeManager,
    managerError,
  } = props;

  return (
    <>
      <CustomTextField
        value={company}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={() => { }}
        label={COMPANY}
        width={width}
        disabled
      />
      <CustomTextField
        value={department}
        onChange={handleChangeDepartment}
        label={DEPARTMENT}
        width={width}
        maxLength={maxLength.CUSTOMER_DEPARTMENT}
      />
      <>
        <div style={{ width }}>
          <PopoverInfoTextField
            value={manager}
            onChange={handleChangeManager}
            label={RESPONSIBLE_PARTY}
            error={managerError !== ''}
            helperText={managerError}
            maxLength={maxLength.CUSTOMER_MANAGER}
            popoverMessage={MANAGER_MESSAGE}
          />
        </div>
      </>
      <CustomTextField
        value={name}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={() => { }}
        label={PERSON_IN_CHARGE}
        width={width}
        disabled
      />
      <CustomTextField
        value={email}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={() => { }}
        label={EMAIL}
        width={width}
        disabled
      />
    </>
  );
};

export default UserDataForm;
