import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import strings from 'common/utils/freeze/strings';
import maxLength from 'common/utils/freeze/maxLength';
import AppHeader from 'common/components/molecules/AppHeader';
import Loading from 'common/components/molecules/Loading';
import PrimaryRoundButton from 'common/components/atoms/PrimaryRoundButton';
import InputField from './InputField';
import useResetPassword from '../hooks/useResetPassword';

const {
  TITLE,
  DESCRIPTION,
  BUTTON_SEND,
  LABEL_CODE,
  LABEL_NEW_PASS,
  LABEL_CONFIRM_PASS,
  PLACEHOLDER,
} = strings.resetPassword;

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-1);
  color: var(--font-color-1);
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const MainArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TopArea = styled.div`
  flex: 1;
  padding: 3rem 3rem 0;
  overflow: scroll;
  display: flex;
  margin: auto;
  justify-content: center;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const DescriptionArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomArea = styled.div`
  background-color: var(--background-color-2);
  text-align: end;
  padding: 2rem 0;
  padding-right: 3rem;
`;

type ResetPasswordProps = {
  mail: string,
};

const ResetPassword: React.FC<ResetPasswordProps> = (props: ResetPasswordProps) => {
  const { mail } = props;
  const {
    send,
    isLoading,
    code,
    newPassword,
    confirmPassword,
  } = useResetPassword(mail);

  return (
    <PageContainer>
      <AppHeader title={TITLE} />
      <Body>
        <MainArea>
          <Loading open={isLoading} />
          <TopArea>
            <div>
              <DescriptionArea>
                <Typography variant="body1">
                  {DESCRIPTION}
                </Typography>
              </DescriptionArea>
              <InputField
                value={code.value}
                label={LABEL_CODE}
                placeholder={PLACEHOLDER}
                error={code.error}
                onChange={code.onChange}
              />
              <InputField
                value={newPassword.value}
                label={LABEL_NEW_PASS}
                placeholder={PLACEHOLDER}
                error={newPassword.error}
                onChange={newPassword.onChange}
                type="password"
                maxLength={maxLength.PASSWORD}
              />
              <InputField
                value={confirmPassword.value}
                label={LABEL_CONFIRM_PASS}
                placeholder={PLACEHOLDER}
                error={confirmPassword.error}
                onChange={confirmPassword.onChange}
                type="password"
                maxLength={maxLength.PASSWORD}
              />
            </div>
          </TopArea>
          <BottomArea>
            <PrimaryRoundButton onClick={send}>
              {BUTTON_SEND}
            </PrimaryRoundButton>
          </BottomArea>
        </MainArea>
      </Body>
    </PageContainer>
  );
};

export default ResetPassword;
