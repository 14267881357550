import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useGetUserQuery } from 'features/api/apiSlice';
import AppButton from 'common/components/atoms/AppButton';
import AppHeader from 'common/components/molecules/AppHeader';
import Loading from 'common/components/molecules/Loading';
import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';
import { Role } from 'common/types/Role';
import UserDataForm from './components/UserDataForm';
import AuthForm from './components/AuthForm';
import useEditAccount from './hooks/useEditAccount';

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: var(--font-color-1);
  background-color: var(--background-color-1);
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const MainContents = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  gap: 2rem 0;
  padding: 3rem 0 1.5rem;
`;

const BottomContents = styled.div`
  background-color: var(--background-color-2);
  text-align: end;
  padding: 1.5rem 0;
  padding-right: 3rem;
`;

const EditAccountData: React.FC = () => {
  const { SAVE } = strings.editAccountData.BUTTON;
  const {
    PERMISSION_ERROR, ERROR_GET_USER,
  } = strings.editAccountData.completionMessage;

  const [isCheckPermission, setIsCheckPermission] = useState(true);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: userInfo, isSuccess, isError,
  } = useGetUserQuery();

  const {
    department,
    manager,
    preferredId,
    handleChangeDepartment,
    handleChangeManager,
    handleChangePreferredId,
    managerError,
    preferredIdError,
    handleSave,
    isLoadingEditUser,
  } = useEditAccount({ userInfo });

  useEffect(() => {
    if (isError) {
      setIsCheckPermission(false);
      enqueueSnackbar(ERROR_GET_USER, { variant: 'error' });
      return;
    }

    if (isSuccess && userInfo) {
      if (userInfo.role === Role.customer) {
        setIsCheckPermission(false);
      } else {
        enqueueSnackbar(PERMISSION_ERROR, { variant: 'error' });
        history.replace({ pathname: pageUrl.TOP });
      }
    }
  }, [
    isSuccess,
    userInfo,
    history,
    setIsCheckPermission,
    isError,
    enqueueSnackbar,
    PERMISSION_ERROR,
    ERROR_GET_USER,
  ]);

  return (
    <PageContainer>
      <AppHeader />
      {isCheckPermission
        ? <Loading open />
        : (
          <Main>
            <Loading open={isLoadingEditUser} />
            {isSuccess && (
              <>
                <MainContents>
                  <UserDataForm
                    company={userInfo ? userInfo.company : ''}
                    department={department}
                    manager={manager}
                    name={userInfo ? userInfo.name || '' : ''}
                    email={userInfo ? userInfo.mail : ''}
                    handleChangeDepartment={handleChangeDepartment}
                    handleChangeManager={handleChangeManager}
                    managerError={managerError}
                  />
                  <AuthForm
                    preferredId={preferredId}
                    handleChangePreferredId={handleChangePreferredId}
                    preferredIdError={preferredIdError}
                  />
                </MainContents>
                <BottomContents>
                  <AppButton
                    width="240px"
                    value={SAVE}
                    onClick={handleSave}
                  />
                </BottomContents>
              </>
            )}
          </Main>
        )}
    </PageContainer>
  );
};

export default EditAccountData;
