import axios from 'axios';

import strings from 'common/utils/freeze/strings';
import ApiUrl from 'common/utils/freeze/ApiUrl';
import getToken from 'features/auth/getToken';

const { BASE_URL } = ApiUrl;

type ResponseType = {
  // eslint-disable-next-line camelcase
  project_id: number
}

type GetSubmitReturn = {
  response: ResponseType | null
  error: string | null
}

const getSubmit = async (projectId: string, submitId: string): Promise<GetSubmitReturn> => {
  try {
    const { idToken, accessToken } = await getToken();
    const response = await axios.get<ResponseType>(
      `${BASE_URL}/projects/${projectId}/submit`,
      {
        headers: {
          authorization: idToken,
          accesstoken: accessToken,
          Accept: 'application/json',
        },
        params: {
          submitId,
        },
      },
    );
    return { response: response.data, error: null };
  } catch (e) {
    return { response: null, error: strings.ERROR_DESCRIPTION_SUBMIT };
  }
};

export default getSubmit;
