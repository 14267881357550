import { withStyles, createStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';

import { RootState } from 'app/store';
import { useAppSelector, useAppDispatch } from 'common/hooks';
import { sortSuppliers } from 'features/suppliers/suppliersSlice';
import { SuppliersListViewModel, TableHeadCell } from 'features/suppliers/types';

const StyledTableCell = withStyles(() => createStyles({
  head: {
    backgroundColor: '#dadbe6',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTypography = withStyles(() => createStyles({
  root: {
    whiteSpace: 'nowrap',
  },
}))(Typography);

type SuppliersTableHeadProps = {
  headCells: TableHeadCell[],
};

const SuppliersTableHead: React.FC<SuppliersTableHeadProps> = (props: SuppliersTableHeadProps) => {
  const { headCells } = props;
  const dispatch = useAppDispatch();
  const order = useAppSelector((state: RootState) => state.suppliers.order);
  const orderBy = useAppSelector((state: RootState) => state.suppliers.orderBy);

  const sortHandler = (property: keyof SuppliersListViewModel) => () => {
    dispatch(sortSuppliers(property));
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.align}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={sortHandler(headCell.id)}
            >
              <StyledTypography variant="body2">
                {headCell.label}
              </StyledTypography>
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default SuppliersTableHead;
