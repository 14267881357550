import { format } from 'date-fns';
import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import strings from 'common/utils/freeze/strings';
import { descendingComparator, stableSort } from 'common/utils/util';
import { Role } from 'common/types/Role';
import { Supplier } from 'features/api/types';
import { SuppliersListViewModel, Order } from 'features/suppliers/types';

const { SALES, MOVRING, ADMIN } = strings.suppliers.role;

export type Filtering = {
  selected: keyof SuppliersListViewModel,
  text: string,
}

export type SuppliersState = {
  suppliers: SuppliersListViewModel[],
  order: Order,
  orderBy: keyof SuppliersListViewModel,
  filtering: Filtering[],
  page: number,
};

export const initialState: SuppliersState = {
  suppliers: [],
  order: 'asc',
  orderBy: 'company',
  filtering: [
    {
      selected: 'name',
      text: '',
    },
    {
      selected: 'company',
      text: '',
    },
  ],
  page: 0,
};

const epochToString = (epoch: number) => format(new Date(epoch * 1000), 'yyyy/MM/dd HH:mm:ss');
const getDisplayRole = (role: Role): string => {
  let text = '';

  switch (role) {
    case Role.customer:
      // ここが来ることはありえない
      text = '';
      break;
    case Role.sales:
      text = SALES;
      break;
    case Role.movring:
      text = MOVRING;
      break;
    case Role.admin:
      text = ADMIN;
      break;
    default:
      text = '';
      break;
  }

  return text;
};

const getComparator = <Key extends keyof SuppliersListViewModel>(order: Order, orderBy: Key): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number => ((order === 'desc')
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy));

const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    setSuppliers: (state, action: PayloadAction<Supplier[]>) => {
      const suppliers: SuppliersListViewModel[] = action.payload.map((supplier) => ({
        id: supplier.id,
        company: supplier.company,
        businessUnit: supplier.business_unit,
        department: supplier.department,
        name: supplier.name,
        role: getDisplayRole(supplier.role),
        createdAt: epochToString(supplier.created_at),
      }));

      state.suppliers = stableSort(suppliers, getComparator(state.order, state.orderBy));
      state.page = 0;
    },
    sortSuppliers: (state, action: PayloadAction<keyof SuppliersListViewModel>) => {
      const isAsc = state.orderBy === action.payload && state.order === 'asc';
      state.order = isAsc ? 'desc' : 'asc';
      state.orderBy = action.payload;
      state.suppliers = stableSort(state.suppliers, getComparator(state.order, state.orderBy));
      state.page = 0;
    },
    filterSuppliers: (state, action: PayloadAction<Filtering[]>) => {
      state.filtering = action.payload;
      state.page = 0;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const {
  setSuppliers,
  sortSuppliers,
  filterSuppliers,
  setPage,
} = suppliersSlice.actions;

export const selectAllSuppliers = (
  state: RootState,
): SuppliersListViewModel[] => state.suppliers.suppliers;
export const selectSuppliersTotal = (state: RootState): number => state.suppliers.suppliers.length;

const filterdSuppliers = (state: RootState): SuppliersListViewModel[] => state.suppliers.suppliers
  .filter((supplier) => (
    String(supplier[state.suppliers.filtering[0].selected])
      .indexOf(state.suppliers.filtering[0].text.trim()) >= 0))
  .filter((supplier) => (
    String(supplier[state.suppliers.filtering[1].selected])
      .indexOf(state.suppliers.filtering[1].text.trim()) >= 0));

export const selectFilteredSuppliers = (
  state: RootState,
): SuppliersListViewModel[] => filterdSuppliers(state);
export const selectFilteredSuppliersTotal = (
  state: RootState,
): number => filterdSuppliers(state).length;

export default suppliersSlice.reducer;
