import { useHistory } from 'react-router-dom';

import TableCell from '@material-ui/core/TableCell';

import pageUrl from 'common/utils/freeze/pageUrl';

type LinkedProjectCellProps = {
  align: 'center' | 'left' | 'right',
  id: number,
  children: React.ReactNode,
};

const LinkedProjectCell: React.FC<LinkedProjectCellProps> = (props: LinkedProjectCellProps) => {
  const { align, id, children } = props;

  const history = useHistory();
  const onClick = (projectId: number) => {
    history.push(`${pageUrl.PROJECTS_LIST}/${projectId}`);
  };

  return (
    <TableCell
      align={align}
      onClick={() => onClick(id)}
    >
      {children}
    </TableCell>
  );
};

export default LinkedProjectCell;
