import { Table, TableContainer } from '@material-ui/core';
import { ProjectFile } from 'features/api/types';
import FileTableHead from './FileTableHead';
import DeliveryFileTableBody from './DeliveryFileTableBody';
import { FileTableHeadCell } from '../type';

type DeliveryFileTableProps = {
  productions: ProjectFile[]
  selected: number
  handleSelectedFile: (fileId: number) => void
}

const DeliveryFileTable: React.FC<DeliveryFileTableProps> = (props: DeliveryFileTableProps) => {
  const {
    productions, selected, handleSelectedFile,
  } = props;
  const tableHeadCells: FileTableHeadCell[] = [
    { align: 'left', label: 'ファイル名' },
    { align: 'left', label: 'ファイル形式' },
    { align: 'left', label: 'サイズ' },
    { align: 'left', label: 'アップロード日時' },
    { align: 'left', label: 'ユーザ名' },
    { align: 'left', label: 'ウイルスチェック' },
  ];

  return (
    <TableContainer style={{ height: '198px' }}>
      <Table stickyHeader>
        <FileTableHead cells={tableHeadCells} />
        <DeliveryFileTableBody
          productions={productions}
          selected={selected}
          handleSelectedFile={handleSelectedFile}
        />
      </Table>
    </TableContainer>
  );
};

export default DeliveryFileTable;
