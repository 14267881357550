import styled from 'styled-components';
import Loading from 'common/components/molecules/Loading';
import AppButton from 'common/components/atoms/AppButton';
import ActionDialog from './ActionDialog';

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
  color: var(--progress-button-dark-grey);
`;

const BodyText = styled.p`
  white-space: pre-line;
`;

const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
  margin: 20px 0;
`;

export type AlertProps = {
  dialogOpen: boolean;
  isAgree: boolean;
  agreeButtonText: string;
  dialogTitle: string;
  body: string;
  handleClose: () => void;
  onAgree: () => void;
}

const Alert: React.FC<AlertProps> = (
  props: AlertProps,
) => {
  const {
    dialogOpen, isAgree, handleClose, onAgree, agreeButtonText, dialogTitle, body,
  } = props;

  return (
    <ActionDialog
      open={dialogOpen}
      onClose={handleClose}
      maxWidth="sm"
      title={dialogTitle}
      titleContent={null}
    >
      <Loading open={isAgree} />
      <DialogContent>
        <Body>
          <BodyText>{body}</BodyText>
        </Body>
        <ButtonArea>
          <AppButton
            value="キャンセル"
            backgroundColor="white"
            color="var(--progress-button-dark-grey)"
            border="1px solid var(--progress-button-dark-grey)"
            onClick={handleClose}
            width="180px"
          />
          <AppButton
            value={agreeButtonText}
            width="180px"
            onClick={onAgree}
          />
        </ButtonArea>
      </DialogContent>
    </ActionDialog>
  );
};

export default Alert;
