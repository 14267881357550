import strings from 'common/utils/freeze/strings';
import { useState } from 'react';
import { AlertProps } from '../components/Alert';

const {
  SEND_BACK_TO_TGC,
  CONFIRMATION_VIDEO_SEND_BACK,
  SALES_SEND_BACK_WARNING_ALERT_BODY,
} = strings.projectDetail.videoConfirmation;

const useSalesSendBackWarningAlert = (sendBackDialogHandleOpen: () => void)
: [AlertProps, () => void] => {
  const agreeButtonText = SEND_BACK_TO_TGC;
  const dialogTitle = CONFIRMATION_VIDEO_SEND_BACK;
  const body = SALES_SEND_BACK_WARNING_ALERT_BODY;

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => setDialogOpen(false);

  const onAgree = () => {
    setDialogOpen(false);
    sendBackDialogHandleOpen();
  };

  return [{
    dialogOpen,
    isAgree: false,
    agreeButtonText,
    dialogTitle,
    body,
    handleClose,
    onAgree,
  }, handleOpen];
};

export default useSalesSendBackWarningAlert;
