import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import getFileUploadUrl from 'features/api/axios/getFileUploadUrl';
import presignedUrlUploadFile from 'features/api/axios/presignedUrlUploadFIle';
import getSubmit from 'features/api/axios/getSubmit';

type useSubmissionReturn = {
  isLoading: boolean;
  progress: string;
  submit: () => Promise<void>;
}

const useSubmission = (
  projectId: string,
  files: File[],
  refetchProjectData: () => void,
): useSubmissionReturn => {
  const [progress, setProgress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const submit = async () => {
    if (files.length === 0 || isLoading) {
      return;
    }
    setIsLoading(true);

    const submitId = uuidv4().slice(0, 8);
    try {
      // eslint-disable-next-line no-restricted-syntax, no-plusplus
      for (let i = 0; i < files.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const res1 = await getFileUploadUrl(projectId, 'DRAFT', files[i].name, submitId);
        if (res1.error) {
          enqueueSnackbar(res1.error, { variant: 'error' });
          throw Error('file upload error');
        }

        if (res1.response) {
          // eslint-disable-next-line no-await-in-loop
          const res2 = await presignedUrlUploadFile(files[i], res1.response.url);
          if (res2.error) {
            enqueueSnackbar(res2.error, { variant: 'error' });
            throw Error('file upload error');
          }
        }
        setProgress(`${Math.ceil(((i + 1) / files.length) * 100)}%`);
      }

      const result = await getSubmit(projectId, submitId);
      if (result.error) {
        enqueueSnackbar(result.error, { variant: 'error' });
        throw Error('submit error');
      }

      if (result.response) {
        enqueueSnackbar('入稿完了しました', { variant: 'success' });
      }
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
    setProgress('');
    refetchProjectData();
  };

  return {
    isLoading,
    progress,
    submit,
  };
};

export default useSubmission;
