import { useState } from 'react';
import {
  FileRejection, useDropzone, DropzoneRootProps, DropzoneInputProps,
} from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { createFileUploadError, createFileUploadErrorCase } from '../utils/rejectedFile';

type UserDropFileProps = {
  uploadFiles: File[]
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T
  open: () => void
  resetFiles: () => void
}

const useDropFile = (): UserDropFileProps => {
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const resetFiles = () => setUploadFiles([]);
  const { enqueueSnackbar } = useSnackbar();

  const onDrop = (dropFiles: File[]) => {
    const fileArray: File[] = uploadFiles.concat();
    dropFiles.forEach((file: File) => {
      // 同じファイル名のファイルがもう既に存在する場合は、新しいやつに置き換える
      const checkdouble = fileArray.findIndex((el) => el.name === file.name);
      if (checkdouble !== -1) {
        fileArray.splice(checkdouble, 1, file);
        return;
      }
      fileArray.push(file);
    });
    setUploadFiles(fileArray);
  };

  const onDropRejected = (rejectFiles: FileRejection[]) => {
    rejectFiles.forEach((rejectFile) => {
      const errorCase = createFileUploadErrorCase(rejectFile);
      const errorMessage = createFileUploadError(errorCase, rejectFile);
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    });
  };

  const {
    getRootProps, getInputProps, open,
  } = useDropzone({
    // 容量制限: 1G
    maxSize: 1073741824,
    minSize: 1,
    multiple: true,
    onDrop,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
  });

  return {
    uploadFiles, getRootProps, getInputProps, open, resetFiles,
  };
};

export default useDropFile;
