const regex = Object.freeze({
  EMAIL: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  PASSWORD: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,50}$/,
  LOGIN_ID: /^[\w]{1,128}$/,
  URL: /^https?:\/\/(api01-platform\.stream\.co\.jp|eqm\.page\.link|www\.mandai-cp\.jp).*$/,
  // URL: /^https:\/\/api01-platform.stream.co.jp.*$/,
  CUSTOMER_CODE: /^[a-zA-Z0-9]{6}$/,
});

export default regex;
