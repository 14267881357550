import styled from 'styled-components';
import DehazeIcon from '@material-ui/icons/Dehaze';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { grey } from '@material-ui/core/colors';

import { User } from 'features/api/types';
import { Role } from 'common/types/Role';

const CloseSidebarWrapper = styled.div`
  box-sizing: border-box;
  background-color: var(--theme-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 0 0.2rem;
`;

type CloseSidebarProps = {
  handleToggle: () => void
  userInfo: User | undefined
}

const CloseSidebar: React.FC<CloseSidebarProps> = (props: CloseSidebarProps) => {
  const { handleToggle, userInfo } = props;

  return (
    <CloseSidebarWrapper>
      <DehazeIcon
        onClick={handleToggle}
        style={{
          color: grey[100], fontSize: 30, margin: '1rem 0 0.3rem', cursor: 'pointer',
        }}
      />
      {userInfo && userInfo.role !== Role.customer && (
        <AccountCircleIcon style={{ fontSize: 35, color: grey[50] }} />
      )}
      <LocalParkingIcon style={{ fontSize: 35, color: grey[50], marginTop: '0.5rem' }} />
    </CloseSidebarWrapper>
  );
};

export default CloseSidebar;
