import { useState } from 'react';
import { useSnackbar } from 'notistack';

import getFileDownloadUrl from 'features/api/axios/getFileDownloadUrl';
import download from '../utils/download';

type UseDownloadFileReturn = {
  downloadFileLoading: boolean
  downloadFile: () => Promise<void>
}

const useDownloadFile = (projectId: string, selected: number): UseDownloadFileReturn => {
  const [downloadFileLoading, setDownloadFileLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const downloadFile = async () => {
    if (selected === 0) {
      return;
    }

    setDownloadFileLoading(true);
    const res = await getFileDownloadUrl(projectId, selected);
    if (res.error) {
      enqueueSnackbar(res.error, { variant: 'error' });
      setDownloadFileLoading(false);
      return;
    }

    if (res.response) {
      download(res.response.url);
    }

    setDownloadFileLoading(false);
  };

  return {
    downloadFileLoading, downloadFile,
  };
};

export default useDownloadFile;
