import styled from 'styled-components';
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  RadioGroup, FormControl, FormLabel, FormHelperText,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import Loading from 'common/components/molecules/Loading';
import AppHeader from 'common/components/molecules/AppHeader';
import AppSidebar from 'common/components/orgnisms/AppSidebar';
import CustomRadio from 'common/components/molecules/CustomRadio';
import PrimaryRoundButton from 'common/components/atoms/PrimaryRoundButton';
import strings from 'common/utils/freeze/strings';
import { useGetUserQuery } from 'features/api/apiSlice';
import { Role } from 'common/types/Role';

import maxLength from 'common/utils/freeze/maxLength';
import TextField from './components/TextField';
import DisableButton from './components/DisableButton';
import DisableDialog from './components/DisableDialog';
import ResendTmpPasswordDialog from './components/ResendTmpPasswordDialog';
import useEditSupplier from './hooks/useEditSupplier';
import useDisableAccount from './hooks/useDisableAccount';
import useResendTmpPassword from './hooks/useResendTmpPassword';

const { HEADER_TITLE } = strings.editSupplier;
const { SAVE, DISABLE, RESEND_PASSWORD } = strings.editSupplier.BUTTON;
const { ERROR_GET_USER } = strings.editSupplier.completionMessage;
const { RADIO, TEXT_LABEL } = strings.editSupplier;

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-1);
  color: var(--font-color-1);
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const MainArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopArea = styled.div`
  flex: 1;
  padding: 1rem 3rem 0;
  overflow: scroll;
`;

const BottomArea = styled.div`
  display: flex;
  background-color: var(--background-color-2);
  padding: 1.8rem;
`;

const NormalButtonArea = styled.div`
  margin: 0 0 0 auto;
`;

const useStyles = makeStyles(() => createStyles({
  formControl: {
    marginBottom: '1rem',
  },
  formLabel: {
    color: '#fff',
  },
  formHelperText: {
    marginLeft: '14px',
  },
}));

type EditSupplierProps = RouteComponentProps<{
  userId: string;
}>;

const EditSupplier: React.FC<EditSupplierProps> = (props: EditSupplierProps) => {
  const { match } = props;
  const { userId } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: userInfo,
    isLoading: isLoadingGetUser,
    isSuccess: isSuccessGetUser,
    isError: isErrorGetUser,
  } = useGetUserQuery();
  const classes = useStyles();

  useEffect(() => {
    if (isErrorGetUser) {
      enqueueSnackbar(ERROR_GET_USER, { variant: 'error' });
    }
  }, [isErrorGetUser, enqueueSnackbar]);

  const {
    isLoadingGetSupplier,
    isLoadingEditSupplier,
    visibleResendTmpPass,
    save,
    role,
    company,
    businessUnit,
    department,
    name,
    email,
  } = useEditSupplier(userId);

  const {
    isLoading: isLoadingResendTmpPassword,
    open: openResendTmpPasswordDialog,
    onClickResendButton,
    onClickCancel: onClickResendCancel,
    onClickOk: onClickResendOk,
  } = useResendTmpPassword(userId);

  const {
    isLoading: isLoadingDisableAccount,
    open: openDisableDialog,
    visible: visibleDisableButton,
    onClickDisableButton,
    onClickCancel: onClickDisableCancel,
    onClickOk: onClickDisableOk,
  } = useDisableAccount(userId, userInfo, isSuccessGetUser);

  const isLoadingReceive = isLoadingGetUser || isLoadingGetSupplier;
  const isLoadingSend = isLoadingEditSupplier
    || isLoadingDisableAccount
    || isLoadingResendTmpPassword;

  return (
    isLoadingReceive ? <Loading open /> : (
      <PageContainer>
        <ResendTmpPasswordDialog
          open={openResendTmpPasswordDialog}
          onClickCancel={onClickResendCancel}
          onClickOk={onClickResendOk}
        />
        <DisableDialog
          open={openDisableDialog}
          onClickCancel={onClickDisableCancel}
          onClickOk={onClickDisableOk}
        />
        <Loading open={isLoadingSend} />
        <AppHeader title={HEADER_TITLE} />
        <Body>
          <AppSidebar initState={false} />
          <MainArea>
            <TopArea>
              <FormControl
                error={role.error !== ''}
                classes={{ root: classes.formControl }}
              >
                <FormLabel
                  focused={false}
                  error={false}
                  classes={{ root: classes.formLabel }}
                >
                  {RADIO.TITLE}
                </FormLabel>
                <RadioGroup row aria-label="role" value={role.value} onChange={role.onChange}>
                  <CustomRadio radioValue={Role.sales} radioLabel={RADIO.SALES} />
                  <CustomRadio radioValue={Role.movring} radioLabel={RADIO.MOVRING} />
                  <CustomRadio radioValue={Role.admin} radioLabel={RADIO.ADMIN} />
                </RadioGroup>
                <FormHelperText
                  classes={{ root: classes.formHelperText }}
                >
                  {role.error}
                </FormHelperText>
              </FormControl>
              <TextField
                value={company.value}
                onChange={company.onChange}
                label={TEXT_LABEL.COMPANY}
                error={company.error}
                maxLength={maxLength.SUPPLIER_COMPANY_NAME}
              />
              <TextField
                value={businessUnit.value}
                onChange={businessUnit.onChange}
                label={TEXT_LABEL.BUSINESS_UNIT}
                error={businessUnit.error}
                maxLength={maxLength.SUPPLIER_BUSINESS_UNIT}
              />
              <TextField
                value={department.value}
                onChange={department.onChange}
                label={TEXT_LABEL.DEPARTMENT}
                error={department.error}
                maxLength={maxLength.SUPPLIER_DEPARTMENT}
              />
              <TextField
                value={name.value}
                onChange={name.onChange}
                label={TEXT_LABEL.NAME}
                error={name.error}
                maxLength={maxLength.SUPPLIER_USER_NAME}
              />
              <TextField
                value={email.value}
                onChange={email.onChange}
                label={TEXT_LABEL.EMAIL}
                error={email.error}
                disabled
              />
            </TopArea>
            <BottomArea>
              {(visibleDisableButton) && (
                <DisableButton onClick={onClickDisableButton}>
                  {DISABLE}
                </DisableButton>
              )}
              <NormalButtonArea>
                {(visibleResendTmpPass) && (
                  <PrimaryRoundButton onClick={onClickResendButton}>
                    {RESEND_PASSWORD}
                  </PrimaryRoundButton>
                )}
                <PrimaryRoundButton onClick={save}>
                  {SAVE}
                </PrimaryRoundButton>
              </NormalButtonArea>
            </BottomArea>
          </MainArea>
        </Body>
      </PageContainer>
    )
  );
};

export default EditSupplier;
