import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';

import strings from 'common/utils/freeze/strings';
import { Role } from 'common/types/Role';
import Loading from 'common/components/molecules/Loading';
import AlertDialog from 'common/components/molecules/AlertDialog';
import { Project } from 'features/api/types';
import getProject from 'features/api/axios/getProject';
import { useEditProjectMutation } from 'features/api/apiSlice';
import ProjectInfoHeader from './ProjectInfoHeader';
import ProjectInfoBody from './ProjectInfoBody';

const {
  UPDATE_SUCCESS: NOTIFICATION_UPDATE_SUCCESS,
  UPDATE_ERROR: NOTIFICATION_UPDATE_ERROR,
  UPDATE_SUCCESS_TOPPAN,
  UPDATE_ERROR_TOPPAN,
} = strings.projectDetail.projectInfo.notifications;

const {
  UPDATE_SUCCESS,
  UPDATE_ERROR,
  ERROR_CONFLICT_TITLE,
  ERROR_CONFLICT_DESCRIPTION,
  ERROR_CONFLICT_RELOAD,
} = strings.projectDetail.projectInfo;

const Container = styled.div`
  width: 96%;
  margin: 0 auto 20px;
  background-color: var(--project-detail-container-background-color);
  box-shadow: 0 3px 6px var(--border-shadow-color);
  border: 1px solid var(--project-detail-container-border-color);
  border-radius: 5px;
`;

const Header = styled.div`
  background-color: var(--theme-color);
  padding: 0.2rem 1rem;
  color: var(--font-color-1);
  border-radius: 0.6rem 0.6rem 0 0;
  .title {
    font-size: var(--big-font-size);
    margin: 0;
  }
  display: flex;
`;

const ProjectInfoField = styled.div`
  padding: 20px;
`;

const epochToString = (epoch: number) => format(new Date(epoch * 1000), 'yyyy-MM-dd');

type ProjectInfoProps = {
  project: Project,
  userRole: Role,
  refetch: () => void
}

const ProjectInfo: React.FC<ProjectInfoProps> = (props: ProjectInfoProps) => {
  const { project, userRole, refetch } = props;
  const [edit, setEdit] = useState(false);
  const [projectName, setProjectName] = useState(project.name);
  const [editProject, { isLoading }] = useEditProjectMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  useEffect(() => {
    setProjectName(project.name);
  }, [project]);

  const detectConflict = async (): Promise<boolean> => {
    const response = await getProject(project.id);

    if (response.error || !response.data) {
      throw new Error('APIエラー');
    }

    const updatedAt = response.data.updated_at;
    if (updatedAt !== project.updated_at) {
      return true;
    }

    return false;
  };

  const handleOnClickEdit = () => {
    setEdit(true);
  };

  const handleOnClickCancel = () => {
    setEdit(false);
    setProjectName(project.name);
  };

  const handleOnClickSave = async () => {
    try {
      if (await detectConflict()) {
        setErrorDialogOpen(true);
        return;
      }
    } catch (e) {
      enqueueSnackbar(strings.ERROR_DESCRIPTION_PROJECT, { variant: 'error' });
      return;
    }

    if (!isLoading) {
      try {
        await editProject(
          {
            projectId: project.id,
            name: projectName.trim(),
          },
        ).unwrap();

        setEdit(false);
        enqueueSnackbar(UPDATE_SUCCESS, { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(UPDATE_ERROR, { variant: 'error' });
      }
    }
  };

  const handleOnChangeProjectName = (value: string) => {
    setProjectName(value);
  };

  const saveEmails = async (newEmails: string[]) => {
    try {
      if (await detectConflict()) {
        setErrorDialogOpen(true);
        return;
      }
    } catch (e) {
      enqueueSnackbar(strings.ERROR_DESCRIPTION_PROJECT, { variant: 'error' });
      return;
    }

    if (!isLoading) {
      try {
        await editProject(
          {
            projectId: project.id,
            customer_notifications: newEmails.map((newEmail) => ({ mail: newEmail })),
          },
        ).unwrap();
        enqueueSnackbar(NOTIFICATION_UPDATE_SUCCESS, { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(NOTIFICATION_UPDATE_ERROR, { variant: 'error' });
      }
    }
  };

  const saveEmailsToppan = async (newEmails: string[]) => {
    try {
      if (await detectConflict()) {
        setErrorDialogOpen(true);
        return;
      }
    } catch (e) {
      enqueueSnackbar(strings.ERROR_DESCRIPTION_PROJECT, { variant: 'error' });
      return;
    }

    if (!isLoading) {
      try {
        await editProject(
          {
            projectId: project.id,
            supplier_notifications: newEmails.map((newEmail) => ({ mail: newEmail })),
          },
        ).unwrap();
        enqueueSnackbar(UPDATE_SUCCESS_TOPPAN, { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(UPDATE_ERROR_TOPPAN, { variant: 'error' });
      }
    }
  };

  const handleClick = () => {
    setErrorDialogOpen(false);
    setEdit(false);
    refetch();
  };

  const ownerName = (project.customer.department)
    ? `${project.customer.company} ${project.customer.department}`
    : project.customer.company;

  const copiedId = (project.copied_id);
  const copiedName = (project.copied_name);

  return (
    <Container>
      <Loading open={isLoading} />
      <AlertDialog
        title={ERROR_CONFLICT_TITLE}
        description={ERROR_CONFLICT_DESCRIPTION}
        open={errorDialogOpen}
      >
        <Button onClick={handleClick} color="primary" autoFocus>
          {ERROR_CONFLICT_RELOAD}
        </Button>
      </AlertDialog>
      <Header>
        <ProjectInfoHeader
          edit={edit}
          projectId={project.id}
          projectName={projectName}
          onClickEdit={handleOnClickEdit}
          onClickSave={handleOnClickSave}
          onClickCancel={handleOnClickCancel}
          onChangeProjectName={handleOnChangeProjectName}
        />
      </Header>
      <ProjectInfoField>
        <ProjectInfoBody
          userRole={userRole}
          ownerName={ownerName}
          copiedId={copiedId}
          copiedName={copiedName}
          submittedDate={
            ((project) && (project.submitted_at))
              ? epochToString(project.submitted_at)
              : ''
          }
          confirmationReqDate={
            ((project) && (project.confirmation_requested_at))
              ? epochToString(project.confirmation_requested_at)
              : ''
          }
          notifications={project.notifications}
          saveEmails={saveEmails}
          saveEmailsToppan={saveEmailsToppan}
          projectNote={project.note}
          projectId={project.id}
          setErrorDialogOpen={setErrorDialogOpen}
        />
      </ProjectInfoField>
    </Container>
  );
};

export default ProjectInfo;
