type ReturnType = '画像' | '動画' | '音声' | 'Office' | 'Adobe' | '不明'

const getFileType = (filename: string): ReturnType => {
  const extensionIndex = filename.lastIndexOf('.');
  if (extensionIndex === -1) return '不明';
  const fileType = filename.slice(extensionIndex + 1);

  if (fileType === 'jpg' || fileType === 'png' || fileType === 'eps' || fileType === 'psd') {
    return '画像';
  }

  if (fileType === 'mp4' || fileType === 'wmv' || fileType === 'mts' || fileType === 'mov' || fileType === 'heic') {
    return '動画';
  }

  if (fileType === 'mp3' || fileType === 'wav') {
    return '音声';
  }

  if (fileType === 'xlsx' || fileType === 'pptx' || fileType === 'docx' || fileType === 'pptm' || fileType === 'xls' || fileType === 'key') {
    return 'Office';
  }

  if (fileType === 'xd' || fileType === 'indd' || fileType === 'pdf') {
    return 'Adobe';
  }

  return '不明';
};

export default getFileType;
