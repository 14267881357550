import { useMemo, useState } from 'react';
import {
  Route, RouteProps, useHistory, Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectFirstLoginUserInfo } from 'features/auth/firstLoginUserInfoSlice';
import { Auth } from 'aws-amplify';

import Loading from 'common/components/molecules/Loading';
import pageUrl from 'common/utils/freeze/pageUrl';

const FirstAuthenticatedRoute: React.FC<RouteProps> = (props) => {
  const [isFisrtSignedIn, setIsFirstSignedIn] = useState(true);
  const [isSingedIn, setIsSignedIn] = useState(true);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const firstLoginUserInfo = useSelector(selectFirstLoginUserInfo);

  const history = useHistory();

  useMemo(() => {
    const checkAuth = async () => {
      setLoadingScreen(true);
      if (firstLoginUserInfo.session === '' || firstLoginUserInfo.id === '') {
        setIsFirstSignedIn(false);
        try {
          await Auth.currentAuthenticatedUser();
        } catch {
          setIsSignedIn(false);
        }
      }
      setLoadingScreen(false);
    };
    checkAuth();
  }, [firstLoginUserInfo]);
  if (loadingScreen) {
    return <Loading open />;
  }
  if (isFisrtSignedIn) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Route {...props} />;
  }
  // 初回ログイン画面に移行する場合のみ場合のみ2重レンダリングが発生するので Redirect で対応
  return (isSingedIn
    ? <>{history.goBack()}</>
    : <Redirect to={pageUrl.FIRST_SIGNIN} />
  );
};

export default FirstAuthenticatedRoute;
