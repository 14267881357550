import styled from 'styled-components';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

type CustomRadioSetProps = {
  radioValue: string | number
  radioLabel: string
}

const useStyles = makeStyles(() => createStyles({
  root: {
    color: '#fff',
  },
  checkedIcon: {
    color: '#fff',
  },
}));

const PermissionRadioWrapper = styled.div`
  margin-right: 2.8rem;
`;

const CustomRadio: React.FC<RadioProps> = (props: RadioProps) => {
  const classes = useStyles();
  return (
    <Radio
      classes={{ root: classes.root }}
      disableRipple
      color="default"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

const CustomRadioSet: React.FC<CustomRadioSetProps> = (props: CustomRadioSetProps) => {
  const {
    radioValue, radioLabel,
  } = props;

  return (
    <PermissionRadioWrapper>
      <FormControlLabel
        value={radioValue}
        control={(
          <CustomRadio />
        )}
        label={radioLabel}
      />
    </PermissionRadioWrapper>
  );
};

export default CustomRadioSet;
