import { withStyles, createStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';

import { RootState } from 'app/store';
import { useAppSelector, useAppDispatch } from 'common/hooks';
import { sortProjects } from 'features/projects/projectsSlice';
import { ProjectsListViewModel, TableHeadCell } from 'features/projects/types';

const StyledTableCell = withStyles(() => createStyles({
  head: {
    backgroundColor: '#dadbe6',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTypography = withStyles(() => createStyles({
  root: {
    whiteSpace: 'nowrap',
  },
}))(Typography);

type ProjectsTableHeadProps = {
  headCells: TableHeadCell[],
};

const ProjectsTableHead: React.FC<ProjectsTableHeadProps> = (props: ProjectsTableHeadProps) => {
  const { headCells } = props;
  const dispatch = useAppDispatch();
  const order = useAppSelector((state: RootState) => state.projects.order);
  const orderBy = useAppSelector((state: RootState) => state.projects.orderBy);

  const sortHandler = (property: keyof ProjectsListViewModel) => () => {
    dispatch(sortProjects(property));
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.align}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={sortHandler(headCell.id)}
            >
              <StyledTypography variant="body2">
                {headCell.label}
              </StyledTypography>
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ProjectsTableHead;
