import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  RadioGroup, FormControl, FormLabel, FormHelperText,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import CustomTextField from 'common/components/molecules/CustomTextField';
import Loading from 'common/components/molecules/Loading';
import AppButton from 'common/components/atoms/AppButton';
import AppHeader from 'common/components/molecules/AppHeader';
import AppSidebar from 'common/components/orgnisms/AppSidebar';
import CustomRadio from 'common/components/molecules/CustomRadio';
import PopoverInfoTextField from 'common/components/molecules/PopoverInfoTextField';
import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';
import maxLength from 'common/utils/freeze/maxLength';

import useSignUp from 'pages/toppanSignUp/hooks/useSignUp';
import { useGetUserQuery } from 'features/api/apiSlice';
import { Role } from 'common/types/Role';

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-1);
  color: var(--font-color-1);
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const MainArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopArea = styled.div`
  flex: 1;
  padding: 1rem 3rem 0;
  overflow: scroll;
`;

const BottomArea = styled.div`
  background-color: var(--background-color-2);
  text-align: end;
  padding: 1.4rem 0;
  padding-right: 3rem;
`;

const useStyles = makeStyles(() => createStyles({
  formControl: {
    marginBottom: '1rem',
  },
  formLabel: {
    color: '#fff',
  },
  formHelperText: {
    marginLeft: '14px',
  },
}));

const ToppanSignUp: React.FC = () => {
  const {
    TITLE, RADIO, TEXT_LABEL, BUTTON, completionMessage, popover,
  } = strings.toppanSignUp;
  const [role, setRole] = useState(0);
  const [company, setConmapny] = useState('');
  const [businessUnit, setBusinessUnit] = useState('');
  const [department, setDepartment] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [checkRole, setCheckRole] = useState(true);
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const textFieldMargin = '0 0 1rem 0';

  const history = useHistory();

  const {
    data: userInfo, isLoading, isSuccess, isError,
  } = useGetUserQuery();

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(completionMessage.ERROR_GET_USER, { variant: 'error' });
    }
  }, [isError, enqueueSnackbar, completionMessage.ERROR_GET_USER]);

  useEffect(() => {
    if (isSuccess) {
      if (userInfo) {
        if ((userInfo.role !== Role.movring) && (userInfo.role !== Role.admin)) {
          enqueueSnackbar(completionMessage.ERROR_PERMISSION, { variant: 'error' });
          history.replace({ pathname: pageUrl.TOP });
        }
      }
    }
    if (!isLoading) {
      setCheckRole(false);
    }
  }, [
    userInfo,
    isSuccess,
    isLoading,
    enqueueSnackbar,
    completionMessage.ERROR_PERMISSION,
    history,
  ]);

  const handleChangeRole = (e: React.ChangeEvent<HTMLInputElement>) => {
    const roleNum = Number(e.target.value);
    setRole(roleNum);
  };

  const handleChangeCompany = (value: string) => {
    setConmapny(value);
  };

  const handleChangeBusinessUnit = (value: string) => {
    setBusinessUnit(value);
  };

  const handleChangedepartment = (value: string) => {
    setDepartment(value);
  };

  const handleChangeName = (value: string) => {
    setName(value);
  };

  const handleChangeEmail = (value: string) => {
    setEmail(value);
  };

  const clearForm = () => {
    setRole(0);
    setConmapny('');
    setBusinessUnit('');
    setDepartment('');
    setName('');
    setEmail('');
  };

  const success = () => {
    clearForm();
    history.push(pageUrl.TENTATIVE_TOPPAN_SIGNUP_COMPLETE);
  };

  const error = () => {
    enqueueSnackbar(completionMessage.ERROR_ADD_SUPPLIER, { variant: 'error' });
  };

  const {
    isAddSupplier, signUp, inputError,
  } = useSignUp(success, error);

  const handleSignUp = () => {
    signUp(
      role,
      company,
      businessUnit,
      department,
      name,
      email,
    );
  };

  return (
    checkRole ? <Loading open /> : (
      <PageContainer>
        <Loading open={isAddSupplier} />
        <AppHeader title={TITLE} />
        <Body>
          <AppSidebar initState={false} />
          <MainArea>
            <TopArea>
              <FormControl
                error={inputError.role !== ''}
                classes={{ root: classes.formControl }}
              >
                <FormLabel
                  focused={false}
                  error={false}
                  classes={{ root: classes.formLabel }}
                >
                  {RADIO.TITLE}
                </FormLabel>
                <RadioGroup row aria-label="role" value={role} onChange={handleChangeRole}>
                  <CustomRadio radioValue={Role.sales} radioLabel={RADIO.SALES} />
                  <CustomRadio radioValue={Role.movring} radioLabel={RADIO.MOVRING} />
                  <CustomRadio radioValue={Role.admin} radioLabel={RADIO.ADMIN} />
                </RadioGroup>
                <FormHelperText
                  classes={{ root: classes.formHelperText }}
                >
                  {inputError.role}
                </FormHelperText>
              </FormControl>
              <div style={{ margin: textFieldMargin }}>
                <PopoverInfoTextField
                  value={company}
                  onChange={handleChangeCompany}
                  label={TEXT_LABEL.COMPANY}
                  error={inputError.company !== ''}
                  helperText={inputError.company}
                  placeholder={strings.INPUT_PLACEHOLDER}
                  maxLength={maxLength.SUPPLIER_COMPANY_NAME}
                  popoverMessage={popover.POPOVER_MESSAGE}
                />
              </div>
              <CustomTextField
                value={businessUnit}
                onChange={handleChangeBusinessUnit}
                label={TEXT_LABEL.BUSINESS_UNIT}
                error={inputError.businessUnit !== ''}
                helperText={inputError.businessUnit}
                placeholder={strings.INPUT_PLACEHOLDER}
                margin={textFieldMargin}
                maxLength={maxLength.SUPPLIER_BUSINESS_UNIT}
              />
              <CustomTextField
                value={department}
                onChange={handleChangedepartment}
                label={TEXT_LABEL.DEPARTMENT}
                error={inputError.department !== ''}
                helperText={inputError.department}
                placeholder={strings.INPUT_PLACEHOLDER}
                margin={textFieldMargin}
                maxLength={maxLength.SUPPLIER_DEPARTMENT}
              />
              <CustomTextField
                value={name}
                onChange={handleChangeName}
                label={TEXT_LABEL.NAME}
                error={inputError.name !== ''}
                helperText={inputError.name}
                placeholder={strings.INPUT_PLACEHOLDER}
                margin={textFieldMargin}
                maxLength={maxLength.SUPPLIER_USER_NAME}
              />
              <CustomTextField
                value={email}
                onChange={handleChangeEmail}
                label={TEXT_LABEL.EMAIL}
                error={inputError.email !== ''}
                helperText={inputError.email}
                placeholder={strings.INPUT_PLACEHOLDER}
                margin={textFieldMargin}
                maxLength={maxLength.EMAIL}
              />
            </TopArea>
            <BottomArea>
              <AppButton
                value={BUTTON.SIGN_UP}
                width="190px"
                onClick={handleSignUp}
              />
            </BottomArea>
          </MainArea>
        </Body>
      </PageContainer>
    )
  );
};

export default ToppanSignUp;
