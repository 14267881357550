import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { useAppSelector } from 'common/hooks';
import {
  selectFilteredSuppliers,
  selectFilteredSuppliersTotal,
} from 'features/suppliers/suppliersSlice';
import LinkedSupplierCell from './LinkedSupplierCell';

type SuppliersTableBodyProps = {
  page: number,
  rowsPerPage: number,
};

const SuppliersTableBody: React.FC<SuppliersTableBodyProps> = (props: SuppliersTableBodyProps) => {
  const { page, rowsPerPage } = props;

  const count = useAppSelector(selectFilteredSuppliersTotal);
  const suppliers = useAppSelector(selectFilteredSuppliers);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  return (
    <TableBody>
      {suppliers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => (
          <TableRow
            tabIndex={-1}
            key={row.id}
          >
            <LinkedSupplierCell align="left" id={row.id}>
              {row.company}
            </LinkedSupplierCell>
            <LinkedSupplierCell align="left" id={row.id}>
              {row.businessUnit}
            </LinkedSupplierCell>
            <LinkedSupplierCell align="left" id={row.id}>
              {row.department}
            </LinkedSupplierCell>
            <LinkedSupplierCell align="left" id={row.id}>
              {row.name}
            </LinkedSupplierCell>
            <LinkedSupplierCell align="left" id={row.id}>
              {row.role}
            </LinkedSupplierCell>
            <LinkedSupplierCell align="left" id={row.id}>
              {row.createdAt}
            </LinkedSupplierCell>
          </TableRow>
        ))}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
};

export default SuppliersTableBody;
