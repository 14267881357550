import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import strings from 'common/utils/freeze/strings';
import { User } from 'features/api/types';
import Loading from 'common/components/molecules/Loading';
import pageUrl from 'common/utils/freeze/pageUrl';
import { AutoCompleteData } from './AutoCompleteTextField';
import CreateProjectForm from './CreateProjectForm';
import useCreateProject from '../hooks/useCreateProject';

const {
  SUCCESS,
  ERROR,
} = strings.createProjectPage.completionMessage;

type CustomerPageProps = {
  userInfo: User,
};

const CustomerPage: React.FC<CustomerPageProps> = (props: CustomerPageProps) => {
  const { userInfo } = props;
  const accountLabel = (userInfo.department)
    ? `${userInfo.company} / ${userInfo.department} / ${userInfo.name}`
    : `${userInfo.company} / ${userInfo.name}`;
  const accountData: AutoCompleteData[] = [
    {
      id: userInfo.id,
      label: accountLabel,
    },
  ];
  const fixedEmails = [userInfo.mail];
  const fixedEmailsToppan: string[] = [];
  const [projectName, setProjectName] = useState('');
  const [accountName, setAccountName] = useState<AutoCompleteData>(accountData[0]);
  const [emails, setEmails] = useState<string[]>([]);
  const [emailsToppan, setEmailsToppan] = useState<string[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleChangeProjectName = (value: string) => {
    setProjectName(value);
  };

  const handleChangeAccountName = (value: AutoCompleteData) => {
    setAccountName(value);
  };

  const saveEmails = (newEmails: string[]) => {
    setEmails(newEmails);
  };

  const saveEmailsToppan = (newEmails: string[]) => {
    setEmailsToppan(newEmails);
  };

  const clearForm = () => {
    setProjectName('');
    setAccountName(accountData[0]);
    setEmails([]);
    setEmailsToppan([]);
  };

  const success = (projectId: number) => {
    clearForm();
    history.push(`${pageUrl.PROJECTS_LIST}/${projectId}`);
    enqueueSnackbar(SUCCESS, {
      variant: 'success',
    });
  };

  const error = () => {
    enqueueSnackbar(ERROR, { variant: 'error' });
  };

  const {
    isLoading,
    createProject,
    inputError,
  } = useCreateProject(success, error);

  const handleClickSaveButton = async () => {
    createProject(
      projectName,
      accountName,
      emails,
      emailsToppan,
    );
  };

  return (
    <>
      <Loading open={isLoading} />
      <CreateProjectForm
        role={userInfo.role}
        accountData={accountData}
        fixedEmails={fixedEmails}
        fixedEmailsToppan={fixedEmailsToppan}
        projectName={projectName}
        projectNameError={inputError.projectName}
        accountName={accountName}
        accountNameError={inputError.accountName}
        emails={emails}
        emailsToppan={emailsToppan}
        handleChangeProjectName={handleChangeProjectName}
        handleChangeAccountName={handleChangeAccountName}
        saveEmails={saveEmails}
        saveEmailsToppan={saveEmailsToppan}
        handleClickSaveButton={handleClickSaveButton}
      />
    </>
  );
};

export default CustomerPage;
