import { createStyles, withStyles } from '@material-ui/core/styles';
import MuiMenuItem from '@material-ui/core/MenuItem';

const MenuItem = withStyles(() => createStyles({
  root: {
    fontSize: 'var(--small-font-size)',
  },
}))(MuiMenuItem);

export default MenuItem;
