import styled from 'styled-components';

import CustomTextField from 'common/components/molecules/CustomTextField';
import strings from 'common/utils/freeze/strings';
import maxLength from 'common/utils/freeze/maxLength';
import { Role } from 'common/types/Role';

const Form = styled.div`
  display: flex;
  margin: 1.2rem 1.4rem 0 1.4rem;
  gap: 0 1.2rem;
`;

type ManagerFormProps = {
  salesEmail: string
  movEmail: string
  tgcEmail: string
  handleChangeSalesEmail: (value: string) => void
  handleChangeMovEmail: (value: string) => void
  handleChangeTgcEmail: (value: string) => void
  salesEmailError: string
  movEmailError: string
  tgcEmailError: string
  userRole: Role
}

const ManagerForm: React.FC<ManagerFormProps> = (props: ManagerFormProps) => {
  const {
    salesEmail,
    movEmail,
    tgcEmail,
    handleChangeSalesEmail,
    handleChangeMovEmail,
    handleChangeTgcEmail,
    salesEmailError,
    movEmailError,
    tgcEmailError,
    userRole,
  } = props;
  const {
    SALES_EMAIL, MOVRING_EMAIL, TGC_EMAIL,
  } = strings.customerSignUp.TEXT_LABEL;

  return (
    <Form>
      <CustomTextField
        label={SALES_EMAIL}
        value={salesEmail}
        onChange={handleChangeSalesEmail}
        error={salesEmailError !== ''}
        helperText={salesEmailError}
        placeholder={strings.INPUT_PLACEHOLDER}
        disabled={userRole === Role.sales}
        maxLength={maxLength.EMAIL}
      />
      <CustomTextField
        label={MOVRING_EMAIL}
        value={movEmail}
        onChange={handleChangeMovEmail}
        error={movEmailError !== ''}
        helperText={movEmailError}
        placeholder={strings.INPUT_PLACEHOLDER}
        maxLength={maxLength.EMAIL}
      />
      <CustomTextField
        label={TGC_EMAIL}
        value={tgcEmail}
        onChange={handleChangeTgcEmail}
        error={tgcEmailError !== ''}
        helperText={tgcEmailError}
        placeholder={strings.INPUT_PLACEHOLDER}
        maxLength={maxLength.EMAIL}
      />
    </Form>
  );
};

export default ManagerForm;
