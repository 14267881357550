import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useGetUserQuery } from 'features/api/apiSlice';
import AppButton from 'common/components/atoms/AppButton';
import Loading from 'common/components/molecules/Loading';
import AppHeader from 'common/components/molecules/AppHeader';
import AppSidebar from 'common/components/orgnisms/AppSidebar';
import { Role } from 'common/types/Role';
import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';

import CustomerForm from './components/CustomerForm';
import ManagerForm from './components/ManagerForm';
import useSignUp from './hooks/useSignUp';

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Main = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const Contents = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-1);
  color: var(--font-color-1);
`;

const TopContents = styled.div`
  padding: 2rem 3.5rem;
  flex: 1;
  overflow: scroll;
`;

const ToppanDataTitle = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  font-size: var(--medium-font-size);
  margin: 0;
`;

const ErrorText = styled.p`
  margin: 0;
  margin-left: 1rem;
  color: #f44336;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  margin: 0 auto;
  background-color: var(--line-color);
`;

const BottomContents = styled.div`
  background-color: var(--background-color-2);
  padding: 1.8rem;
  text-align: end;
`;

const CustomerSignUp: React.FC = () => {
  const { CUSTOMER_DATA, TOPPAN_DATA } = strings.customerSignUp.TITLE;
  const { SIGN_UP } = strings.customerSignUp.BUTTON;
  const {
    ERROR_PERMISSION,
    ERROR_GET_USER,
    ERROR_ADD_CUSTOMER,
    ERROR_ADD_CUSTOMER_404,
  } = strings.customerSignUp.completionMessage;

  const [customerName, setCustomerName] = useState('');
  const [customerCD, setCustomerCD] = useState('');
  const [accountName, setAccountName] = useState('');
  const [email, setEmail] = useState('');
  const [salesEmail, setSalesEmail] = useState('');
  const [movEmail, setMovEmail] = useState('');
  const [tgcEmail, setTgcEmail] = useState('');
  const [errorMessage404, setErrorMessage404] = useState('');
  const [checkRole, setCheckRole] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const {
    data: userInfo, isLoading, isSuccess, isError,
  } = useGetUserQuery();

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(ERROR_GET_USER, { variant: 'error' });
    }
  }, [isError, enqueueSnackbar, ERROR_GET_USER]);

  useEffect(() => {
    if (isSuccess) {
      if (userInfo && userInfo.role === Role.sales) {
        setSalesEmail(userInfo.mail);
      } else if (userInfo && userInfo.role === Role.customer) {
        enqueueSnackbar(ERROR_PERMISSION, { variant: 'error' });
        history.replace({ pathname: pageUrl.TOP });
      }
    }

    if (!isLoading) {
      setCheckRole(false);
    }
  }, [userInfo, isSuccess, isLoading, enqueueSnackbar, ERROR_PERMISSION, history]);

  const handleChangeCustomerName = (value: string) => {
    setCustomerName(value);
  };

  const handleChangeCustomerCD = (value: string) => {
    setCustomerCD(value);
  };

  const handleChangeAccountName = (value: string) => {
    setAccountName(value);
  };

  const handleChangeEmail = (value: string) => {
    setEmail(value);
  };

  const handleChangeSalesEmail = (value: string) => {
    setSalesEmail(value);
  };

  const handleChangeMovEmail = (value: string) => {
    setMovEmail(value);
  };

  const handleChangeTgcEmail = (value: string) => {
    setTgcEmail(value);
  };

  const clearForm = () => {
    setCustomerName('');
    setCustomerCD('');
    setAccountName('');
    setEmail('');
    setSalesEmail('');
    setMovEmail('');
    setTgcEmail('');
  };

  const success = () => {
    clearForm();
    history.push(pageUrl.TENTATIVE_CUSTOMER_SIGNUP_COMPLETE);
  };

  const error = (status: number) => {
    if (status === 404) {
      setErrorMessage404(ERROR_ADD_CUSTOMER_404);
      return;
    }
    enqueueSnackbar(ERROR_ADD_CUSTOMER, { variant: 'error' });
  };

  const {
    isAddCustomer, signUp, inputError,
  } = useSignUp(success, error);

  const handleSignUp = () => {
    setErrorMessage404('');
    signUp(
      customerName,
      customerCD,
      accountName,
      email,
      salesEmail,
      movEmail,
      tgcEmail,
    );
  };

  return (
    checkRole ? <Loading open /> : (
      <PageContainer>
        <Loading open={isAddCustomer} />
        <AppHeader title={strings.customerSignUp.HEADER_TITLE} />
        <Main>
          <AppSidebar initState={false} />
          <Contents>
            <TopContents>
              <div>
                <Title>{CUSTOMER_DATA}</Title>
                <Line />
              </div>
              <CustomerForm
                customerName={customerName}
                customerCD={customerCD}
                accountName={accountName}
                email={email}
                handleChangeCustomerName={handleChangeCustomerName}
                handleChangeCustomerCD={handleChangeCustomerCD}
                handleChangeAccountName={handleChangeAccountName}
                handleChangeEmail={handleChangeEmail}
                customerNameError={inputError.customerName}
                customerCDError={inputError.customerCD}
                accountNameError={inputError.accountName}
                emailError={inputError.email}
              />
              <div style={{ marginTop: '2.4rem' }}>
                <ToppanDataTitle>
                  <Title>{TOPPAN_DATA}</Title>
                  <ErrorText>{errorMessage404}</ErrorText>
                </ToppanDataTitle>
                <Line />
              </div>
              {isSuccess && userInfo && (
                <ManagerForm
                  salesEmail={salesEmail}
                  movEmail={movEmail}
                  tgcEmail={tgcEmail}
                  handleChangeSalesEmail={handleChangeSalesEmail}
                  handleChangeMovEmail={handleChangeMovEmail}
                  handleChangeTgcEmail={handleChangeTgcEmail}
                  salesEmailError={inputError.salesEmail}
                  movEmailError={inputError.movEmail}
                  tgcEmailError={inputError.tgcEmail}
                  userRole={userInfo.role}
                />
              )}
            </TopContents>
            <BottomContents>
              <AppButton
                value={SIGN_UP}
                width="190px"
                onClick={handleSignUp}
              />
            </BottomContents>
          </Contents>
        </Main>
      </PageContainer>
    )
  );
};

export default CustomerSignUp;
