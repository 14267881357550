import strings from 'common/utils/freeze/strings';
import maxLength from 'common/utils/freeze/maxLength';
import PopoverInfoTextField from 'common/components/molecules/PopoverInfoTextField';

type AuthFormProps = {
  preferredId: string
  handleChangePreferredId: (value: string) => void
  preferredIdError: string
}

const AuthForm: React.FC<AuthFormProps> = (props: AuthFormProps) => {
  const {
    LOGIN_ID,
  } = strings.editAccountData.TEXT_LABEL;
  const { MESSAGE } = strings.editAccountData.popover;
  const width = '60%';

  const {
    preferredId,
    handleChangePreferredId,
    preferredIdError,
  } = props;

  return (
    <>
      <div style={{ width }}>
        <PopoverInfoTextField
          value={preferredId}
          onChange={handleChangePreferredId}
          label={LOGIN_ID}
          placeholder={strings.INPUT_PLACEHOLDER}
          error={preferredIdError !== ''}
          helperText={preferredIdError}
          maxLength={maxLength.LOGIN_ID}
          popoverMessage={MESSAGE}
        />
      </div>
    </>
  );
};

export default AuthForm;
