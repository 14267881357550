import { useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import strings from 'common/utils/freeze/strings';
import regex from 'common/utils/freeze/regex';

import { useAddSupplierMutation } from 'features/api/apiSlice';
import { SupplierBody } from 'features/api/types';

const { REQUIRED_ROLE, REQUIRED, EMAIL_FORMAT } = strings.toppanSignUp.validationError;
const { ERROR_DUPLICATE } = strings.toppanSignUp.completionMessage;

type InputError = {
  role: string
  company: string
  businessUnit: string
  department: string
  name: string
  email: string
}

type Return = {
  isAddSupplier: boolean
  signUp: (
    role: number,
    company: string,
    businessUnit: string,
    department: string,
    name: string,
    email: string,
  ) => void
  inputError: InputError
}

const useSignUp = (
  success: () => void,
  error: () => void,
): Return => {
  const [roleError, setRoleError] = useState('');
  const [companyError, setCompanyError] = useState('');
  const [businessUnitError, setBusinessUnitError] = useState('');
  const [departmentError, setDepartmentError] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');

  // RTK queryにType Guardが実装されていなかったので、自前で実装しました。そのため `any` を使用。
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isFetchBaseQueryError = (err: any): err is FetchBaseQueryError => err.status !== undefined;

  const [
    addSupplier,
    { isLoading: isAddSupplier },
  ] = useAddSupplierMutation();

  const validation = (
    role: number,
    company: string,
    businessUnit: string,
    department: string,
    name: string,
    email: string,
  ): boolean => {
    let requiredError = false;

    if (!role) {
      requiredError = true;
      setRoleError(REQUIRED_ROLE);
    } else {
      setRoleError('');
    }

    if (company.trim() === '') {
      requiredError = true;
      setCompanyError(REQUIRED);
    } else {
      setCompanyError('');
    }

    if (businessUnit.trim() === '') {
      requiredError = true;
      setBusinessUnitError(REQUIRED);
    } else {
      setBusinessUnitError('');
    }

    if (department.trim() === '') {
      requiredError = true;
      setDepartmentError(REQUIRED);
    } else {
      setDepartmentError('');
    }

    if (name.trim() === '') {
      requiredError = true;
      setNameError(REQUIRED);
    } else {
      setNameError('');
    }

    if (email.trim() === '') {
      requiredError = true;
      setEmailError(REQUIRED);
    } else if (!email.match(regex.EMAIL)) {
      requiredError = true;
      setEmailError(EMAIL_FORMAT);
    } else {
      setEmailError('');
    }

    return requiredError;
  };

  const signUp = async (
    role: number,
    company: string,
    businessUnit: string,
    department: string,
    name: string,
    email: string,
  ) => {
    const validationError = validation(
      role, company, businessUnit, department, name, email,
    );

    if (validationError) {
      return;
    }

    const supplierData: SupplierBody = {
      role,
      mail: email,
      name,
      company,
      business_unit: businessUnit,
      department,
    };

    if (!isAddSupplier) {
      try {
        await addSupplier(supplierData).unwrap();
        success();
      } catch (err) {
        if (isFetchBaseQueryError(err) && err.status === 409) {
          setEmailError(ERROR_DUPLICATE);
        } else {
          error();
        }
      }
    }
  };

  return {
    isAddSupplier,
    signUp,
    inputError: {
      role: roleError,
      company: companyError,
      businessUnit: businessUnitError,
      department: departmentError,
      name: nameError,
      email: emailError,
    },
  };
};

export default useSignUp;
