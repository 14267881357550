import styled from 'styled-components';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import strings from 'common/utils/freeze/strings';
import AppButton from 'common/components/atoms/AppButton';
import AppHeader from 'common/components/molecules/AppHeader';
import Loading from 'common/components/molecules/Loading';
import AppSidebar from 'common/components/orgnisms/AppSidebar';
import useChangePassword from './hooks/useChangePassword';
import PasswordInputField from './components/PasswordInputField';

const {
  TITLE,
  LABEL_CURRENT_PASS,
  LABEL_NEW_PASS,
  LABEL_CONFIRM_PASS,
  PLACEHOLDER,
  BUTTON_SAVE,
  TEXT,
} = strings.changePassword;

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-1);
  color: var(--font-color-1);
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const MainArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TopArea = styled.div`
  flex: 1;
  padding: 2rem 3rem 0;
  overflow: scroll;
  display: flex;
  margin: auto;
  justify-content: center;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const InputArea = styled.div`
  flex: 1;
  min-width: 600px;
`;

const IconArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  font-size: var(--big-font-size);
`;

const BottomArea = styled.div`
  background-color: var(--background-color-2);
  text-align: end;
  padding: 2rem 0;
  padding-right: 3rem;
`;

const ChangePassword: React.FC = () => {
  const {
    save,
    isLoading,
    currentPassword,
    newPassword,
    confirmPassword,
  } = useChangePassword();

  return (
    <PageContainer>
      <Loading open={isLoading} />
      <AppHeader title={TITLE} />
      <Body>
        <AppSidebar initState={false} />
        <MainArea>
          <TopArea>
            <InputArea>
              <IconArea>
                <VpnKeyIcon style={{ fontSize: 80 }} />
              </IconArea>
              <Text>{TEXT}</Text>
              <PasswordInputField
                value={currentPassword.value}
                label={LABEL_CURRENT_PASS}
                placeholder={PLACEHOLDER}
                error={currentPassword.error}
                onChange={currentPassword.onChange}
              />
              <PasswordInputField
                value={newPassword.value}
                label={LABEL_NEW_PASS}
                placeholder={PLACEHOLDER}
                error={newPassword.error}
                onChange={newPassword.onChange}
              />
              <PasswordInputField
                value={confirmPassword.value}
                label={LABEL_CONFIRM_PASS}
                placeholder={PLACEHOLDER}
                error={confirmPassword.error}
                onChange={confirmPassword.onChange}
              />
            </InputArea>
          </TopArea>
          <BottomArea>
            <AppButton
              value={BUTTON_SAVE}
              width="190px"
              onClick={save}
            />
          </BottomArea>
        </MainArea>
      </Body>
    </PageContainer>
  );
};

export default ChangePassword;
