import styled from 'styled-components';

import { ProjectFile, Submission } from 'features/api/types';
import { ProjectStatus } from 'common/types/ProjectStatus';
import { Role } from 'common/types/Role';

import StepperComponent from './StepperComponent';
import useStep from '../hooks/useStep';
import ProgressMainContent from './ProgressMainContent';

const Container = styled.div`
  width: 96%;
  margin: 0 auto 20px;
  background-color: var(--project-detail-container-background-color);
  box-shadow: 0 3px 6px var(--border-shadow-color);
  border: 1px solid var(--project-detail-container-border-color);
  border-radius: 5px;
`;

const Header = styled.div`
  background-color: var(--theme-color);
  padding: 0.2rem 1rem;
  color: var(--font-color-1);
  border-radius: 0.6rem 0.6rem 0 0;
  .title {
    font-size: var(--big-font-size);
    margin: 0;
  }
`;

type ProgressProps = {
  projectStatus: ProjectStatus
  submissions: Submission[]
  productions: ProjectFile[]
  confirmationUrl: string
  projectId: string
  refetchProjectData: () => void
  userRole: Role
}

const Progress: React.FC<ProgressProps> = (props: ProgressProps) => {
  const {
    projectStatus,
    projectId,
    refetchProjectData,
    submissions,
    productions,
    userRole,
    confirmationUrl,
  } = props;
  const {
    activeStep, handleStep,
  } = useStep(projectStatus, userRole);

  return (
    <Container>
      <Header>
        <p className="title">進行状況</p>
      </Header>
      <div>
        <StepperComponent
          projectStatus={projectStatus}
          handleStep={handleStep}
          activeStep={activeStep}
          userRole={userRole}
        />
        <ProgressMainContent
          activeStep={activeStep}
          submissions={submissions}
          projectId={projectId}
          refetchProjectData={refetchProjectData}
          productions={productions}
          userRole={userRole}
          confirmationUrl={confirmationUrl}
          projectStatus={projectStatus}
        />
      </div>
    </Container>
  );
};

export default Progress;
