import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSendBackConfirmationVideoMutation } from 'features/api/apiSlice';
import strings from 'common/utils/freeze/strings';
import { AlertProps } from '../components/Alert';

const {
  SEND_BACK_TO_TGC,
  CUSTOMER_ACCEPTED_VIDEO_SEND_BACK,
  MOVRING_AND_ADMIN_SEND_BACK_ALERT_BODY,
  MOVRING_AND_ADMIN_SEND_BACK_SUCCESS,
  MOVRING_AND_ADMIN_SEND_BACK_ERROR,
} = strings.projectDetail.videoConfirmation;

const useMovringAndAdminSendBackAlert = (projectId: string): [AlertProps, () => void] => {
  const agreeButtonText = SEND_BACK_TO_TGC;
  const dialogTitle = CUSTOMER_ACCEPTED_VIDEO_SEND_BACK;
  const body = MOVRING_AND_ADMIN_SEND_BACK_ALERT_BODY;

  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [sendBack, { isLoading }] = useSendBackConfirmationVideoMutation();

  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => setDialogOpen(false);

  const onAgree = async () => {
    try {
      await sendBack({ projectId, message: '' }).unwrap();
      enqueueSnackbar(MOVRING_AND_ADMIN_SEND_BACK_SUCCESS, { variant: 'success' });
      setDialogOpen(false);
    } catch {
      enqueueSnackbar(MOVRING_AND_ADMIN_SEND_BACK_ERROR, { variant: 'error' });
    }
  };

  return [{
    dialogOpen,
    isAgree: isLoading,
    agreeButtonText,
    dialogTitle,
    body,
    handleClose,
    onAgree,
  }, handleOpen];
};

export default useMovringAndAdminSendBackAlert;
