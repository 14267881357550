import styled from 'styled-components';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

type CustomTextFieldProps = {
  value: string
  error?: boolean
  label?: string
  placeholder?: string
  helperText?: string
  content?: React.ReactNode
  type?: string
  disabled?: boolean
  onChange: (value: string) => void
  margin?: string
  width?: string
  onFocus?: () => void
  onBlur?: () => void
  maxLength?: number
}

const useStyles = makeStyles(() => createStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#fff',
    },
  },
  input: {
    '&::placeholder': {
      color: '#545871',
      opacity: 1,
    },
    '&:disabled': {
      backgroundColor: '#CCCCCC',
      color: '#000',
      borderRadius: '4px',
    },
  },
}));

const Container = styled.div<Pick<CustomTextFieldProps, 'margin' | 'width'>>`
  width: ${(props) => (props.width)};
  margin: ${(props) => (props.margin)};
`;

const LabelContent = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
`;

const Label = styled.label`
  display: block;
  font-size: var(--text-field-label-font-size);
`;

const Content = styled.div`
  margin-left: 0.3rem;
`;

const CustomTextField: React.FC<CustomTextFieldProps> = (props: CustomTextFieldProps) => {
  const {
    value,
    error,
    label,
    placeholder,
    helperText,
    content,
    type,
    disabled,
    onChange,
    margin,
    width,
    onFocus,
    onBlur,
    maxLength,
  } = props;
  const classes = useStyles();

  return (
    <Container
      margin={margin}
      width={width}
    >
      <LabelContent>
        <Label>{label}</Label>
        <Content>{content}</Content>
      </LabelContent>
      <TextField
        className={classes.root}
        placeholder={placeholder}
        helperText={helperText}
        error={error}
        value={value}
        type={type}
        onChange={(e) => onChange(e.target.value)}
        variant="outlined"
        fullWidth
        disabled={disabled}
        inputProps={{
          className: classes.input,
          maxLength,
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </Container>
  );
};

CustomTextField.defaultProps = {
  label: '',
  placeholder: '',
  helperText: '',
  disabled: false,
  content: '',
  error: false,
  type: '',
  margin: '',
  width: '100%',
  onFocus: undefined,
  onBlur: undefined,
  maxLength: undefined,
};

export default CustomTextField;
