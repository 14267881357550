import { useHistory } from 'react-router-dom';

import { createStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import pageUrl from 'common/utils/freeze/pageUrl';

const StyledButton = withStyles(() => createStyles({
  root: {
    minWidth: 114,
    height: 40,
    fontSize: 'var(--small-font-size)',
    backgroundColor: '#F0F1F7',
  },
}))(Button);

const AddNewCustomerButton: React.FC = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push(pageUrl.CUSTOMER_SIGNUP);
  };

  return (
    <StyledButton
      onClick={handleClick}
      variant="outlined"
      endIcon={<PersonAddIcon />}
    >
      新規登録
    </StyledButton>
  );
};

export default AddNewCustomerButton;
