export const ProjectStatus = {
  preSubmission: 'pre_submission',
  tbcSales: 'TBC_sales',
  tbcCustomer: 'TBC_customer',
  wipFirst: 'WIP_first',
  wipSentBack: 'WIP_sent_back',
  wipProduction: 'WIP_production',
  done: 'done',
} as const;

export type ProjectStatus = typeof ProjectStatus[keyof typeof ProjectStatus]
