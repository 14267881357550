import axios from 'axios';

import { Project } from 'features/api/types';
import strings from 'common/utils/freeze/strings';
import ApiUrl from 'common/utils/freeze/ApiUrl';
import getToken from 'features/auth/getToken';

const { BASE_URL } = ApiUrl;

type ReturnType = {
  data: Project | null
  error: string | null
}

const getProject = async (projectId: number): Promise<ReturnType> => {
  try {
    const { idToken, accessToken } = await getToken();
    const response = await axios.get<Project>(
      `${BASE_URL}/projects/${projectId}`,
      {
        headers: {
          authorization: idToken,
          accesstoken: accessToken,
        },
      },
    );
    return {
      data: response.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: strings.ERROR_TITLE_PROJECT,
    };
  }
};

export default getProject;
