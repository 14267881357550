import styled from 'styled-components';
import { useState } from 'react';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Button, withStyles } from '@material-ui/core';
import { ProjectFile } from 'features/api/types';
import Loading from 'common/components/molecules/Loading';
import { Role } from 'common/types/Role';
import DeliveryDialog from './DeliveryDialog';
import DeliveryFileTable from './DeliveryFileTable';
import useDeliveryFile from '../hooks/useDeliveryFile';
import useDropFile from '../hooks/useDropFile';
import useDownloadZip from '../hooks/useDownloadZip';
import useSelectedFile from '../hooks/useSelectedFile';
import useDownloadFile from '../hooks/useDownloadFile';
import FileDeleteButtonField from './FileDeleteButtonField';

const Container = styled.div`
  margin-left: auto;   
  margin-right: auto;
  margin-bottom: 50px;
  width: 88%;
  background-color: inherit;
  border: 1px solid var(--progress-file-form-border-color);
  border-radius: 5px;
`;

const Header = styled.div`
  padding: 0.15rem 0.4rem;
  border-bottom: 1px solid var(--progress-file-form-border-color);
  display: flex;
  justify-content: space-between;
`;

const GreyButton = withStyles({
  root: {
    fontSize: 'var(--button-vy-small-font-size)',
    color: 'var(--theme-color)',
    backgroundColor: 'var(--progress-button-grey)',
    border: '1px solid var(--progress-button-white)',
    marginRight: '0.5rem',
  },
})(Button);

const FileTableWrapper = styled.div`
  height: 198px;
`;

type DeliveryFileFormProps = {
  productions: ProjectFile[]
  projectId: string
  refetchProjectData: () => void
  userRole: Role
}

const DeliveryFileForm: React.FC<DeliveryFileFormProps> = (props: DeliveryFileFormProps) => {
  const {
    projectId, refetchProjectData, productions, userRole,
  } = props;
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const {
    uploadFiles, getRootProps, getInputProps, open, resetFiles,
  } = useDropFile();

  const {
    isLoading, progress, deliveryFile,
  } = useDeliveryFile(projectId, uploadFiles, refetchProjectData);

  const {
    downloadZip, downloadZipLoading,
  } = useDownloadZip(projectId, 'PRODUCTION', null);

  const { selected, handleSelectedFile } = useSelectedFile();

  const {
    downloadFileLoading, downloadFile,
  } = useDownloadFile(projectId, selected);

  const handleClose = () => {
    setDialogOpen(false);
    resetFiles();
  };

  const handleSaveFile = async () => {
    setDialogOpen(false);
    await deliveryFile();
    resetFiles();
  };

  const checkRole = () => {
    if (userRole === Role.movring || userRole === Role.admin) {
      return true;
    }
    return false;
  };

  return (
    <Container>
      <Loading open={isLoading || downloadZipLoading || downloadFileLoading} progress={progress} />
      <DeliveryDialog
        dialogOpen={dialogOpen}
        handleClose={handleClose}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        handleSaveFile={handleSaveFile}
        files={uploadFiles}
        open={open}
        isLoading={isLoading}
      />
      <Header>
        <div>
          {checkRole() && (
            <GreyButton
              startIcon={<CloudUploadIcon />}
              onClick={() => setDialogOpen(true)}
            >
              アップロード
            </GreyButton>
          )}
          <GreyButton
            startIcon={<CloudDownloadIcon />}
            onClick={downloadZip}
          >
            すべてダウンロード
          </GreyButton>
          <GreyButton
            startIcon={<CloudDownloadIcon />}
            onClick={downloadFile}
            disabled={selected === 0}
          >
            ダウンロード
          </GreyButton>
          {checkRole() && (
            <FileDeleteButtonField
              projectId={projectId}
              selected={selected}
              refetchProjectData={refetchProjectData}
              resetSelected={() => { handleSelectedFile(0); }}
            />
          )}
        </div>
      </Header>
      <FileTableWrapper>
        <DeliveryFileTable
          productions={productions}
          selected={selected}
          handleSelectedFile={handleSelectedFile}
        />
      </FileTableWrapper>
    </Container>
  );
};

export default DeliveryFileForm;
