/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// NOTE:
//   Action Creator は基本的に Slice で作成されるので、このファイルで定義する必要はないが、
//   ステートを削除するような Action は Slice が生成した Reducer ではなく、 RootReducer で
//   受ける必要があるので、ここで定義する。

// eslint-disable-next-line import/prefer-default-export
export const resetState = () => ({
  type: 'RESET_STATE',
});
