export const StepStatus = {
  submission: 0,
  videoCheck: 1,
  delivery: 2,
};

export type StepStatus = typeof StepStatus[keyof typeof StepStatus]

export type FileTableHeadCell = {
  align: 'center' | 'inherit' | 'justify' | 'left' | 'right'
  label: string
}
