import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import ApiUrl from 'common/utils/freeze/ApiUrl';
import getToken from 'features/auth/getToken';
import {
  Projects,
  Project,
  User,
  Customer,
  Supplier,
  PatchUserBody,
  SubmitFile,
  Announcement,
  ProjectBody,
  AcceptConfirmationVideoRes,
  AcceptConfirmationVideoReq,
  SendBackConfirmationVideoRes,
  SendBackConfirmationVideoReq,
} from './types';

const { BASE_URL } = ApiUrl;

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers) => {
      const { idToken, accessToken } = await getToken();
      headers.set('authorization', idToken);
      headers.set('accesstoken', accessToken);

      return headers;
    },
  }),
  tagTypes: ['Project', 'User', 'Customer', 'Supplier', 'Announcement'],
  endpoints: (builder) => ({
    getProjects: builder.query<Projects[], void>({
      query: () => '/projects',
      providesTags: ['Project'],
    }),
    getProject: builder.query<Project, string>({
      query: (projectId) => `/projects/${projectId}`,
      providesTags: ['Project'],
    }),
    addNewProject: builder.mutation<Project, ProjectBody>({
      query: (initialProject) => ({
        url: '/projects',
        method: 'POST',
        body: initialProject,
      }),
      invalidatesTags: ['Project'],
    }),
    editProject: builder.mutation({
      query: ({ projectId, ...editProjectData }) => ({
        url: `/projects/${projectId}`,
        method: 'PATCH',
        body: editProjectData,
      }),
      invalidatesTags: ['Project'],
    }),
    editConfirmationURL: builder.mutation({
      query: ({ projectId, ...editConfirmationData }) => ({
        url: `/projects/${projectId}/confirmation-url`,
        method: 'PATCH',
        body: editConfirmationData,
      }),
      invalidatesTags: ['Project'],
    }),
    deleteProject: builder.mutation({
      query: (projectId) => ({
        url: `/projects/${projectId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Project'],
    }),
    addDiversionProject: builder.mutation({
      query: (projectId) => ({
        url: `/projects/diversion?projectId=${projectId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Project'],
    }),
    AcceptConfirmationVideo: builder.mutation<
      AcceptConfirmationVideoRes, AcceptConfirmationVideoReq
    >({
      query: ({ projectId, ...body }) => ({
        url: `/projects/${projectId}/accept`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Project'],
    }),
    SendBackConfirmationVideo: builder.mutation<
      SendBackConfirmationVideoRes, SendBackConfirmationVideoReq
    >({
      query: ({ projectId, ...body }) => ({
        url: `/projects/${projectId}/send-back`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Project'],
    }),
    getSubmitFile: builder.mutation<SubmitFile[], string>({
      query: (projectId) => ({
        url: `/projects/${projectId}/files`,
        method: 'GET',
      }),
    }),
    getUser: builder.query<User, void>({
      query: () => '/user',
      providesTags: ['User'],
    }),
    editUser: builder.mutation({
      query: (userData: PatchUserBody) => ({
        url: '/user',
        method: 'PATCH',
        body: userData,
        // // TODO: デバッグ用のコードなので、本番では削除する
        // // ここから
        // headers: {
        //   // mock でエラーを返したい場合、ここの数字を変更することで、
        //   // 特定のステータスコードのエラーを発生させることができる
        //   Prefer: 'code=409',
        // },
        // // ここまで
      }),
      invalidatesTags: (result, error, arg) => ((error) ? [] : ['User']),
    }),
    changePassword: builder.mutation({
      query: (passwordData) => ({
        url: '/user/change-password',
        method: 'PUT',
        body: passwordData,
        // TODO: デバッグ用のコードなので、本番では削除する
        // // ここから
        // headers: {
        //   // mock でエラーを返したい場合、ここの数字を変更することで、
        //   // 特定のステータスコードのエラーを発生させることができる
        //   Prefer: 'code=400',
        // },
        // // ここまで
      }),
    }),
    getCustomers: builder.query<Customer[], void>({
      query: () => '/customers',
      providesTags: ['Customer'],
    }),
    getCustomer: builder.query<Customer, string>({
      query: (customerId) => ({
        url: `/customers/${customerId}`,
        method: 'GET',
        // // TODO: デバッグ用のコードなので、本番では削除する
        // // ここから
        // headers: {
        //   // mock でエラーを返したい場合、ここの数字を変更することで、
        //   // 特定のステータスコードのエラーを発生させることができる
        //   Prefer: 'code=403',
        // },
        // // ここまで
      }),
      providesTags: ['Customer'],
    }),
    addCustomer: builder.mutation({
      query: (customerData) => ({
        url: '/customers',
        method: 'POST',
        body: customerData,
        // // TODO: デバッグ用のコードなので、本番では削除する
        // // ここから
        // headers: {
        //   // mock でエラーを返したい場合、ここの数字を変更することで、
        //   // 特定のステータスコードのエラーを発生させることができる
        //   Prefer: 'code=409',
        // },
        // // ここまで
      }),
      invalidatesTags: ['Customer'],
    }),
    editCustomer: builder.mutation({
      query: ({ customerId, ...customerData }) => ({
        url: `/customers/${customerId}`,
        method: 'PUT',
        body: customerData,
        // // TODO: デバッグ用のコードなので、本番では削除する
        // // ここから
        // headers: {
        //   // mock でエラーを返したい場合、ここの数字を変更することで、
        //   // 特定のステータスコードのエラーを発生させることができる
        //   Prefer: 'code=403',
        // },
        // // ここまで
      }),
      invalidatesTags: ['Customer'],
    }),
    disableCustomer: builder.mutation({
      query: (customerId) => ({
        url: `/customers/${customerId}/disable`,
        method: 'GET',
        // // TODO: デバッグ用のコードなので、本番では削除する
        // // ここから
        // headers: {
        //   // mock でエラーを返したい場合、ここの数字を変更することで、
        //   // 特定のステータスコードのエラーを発生させることができる
        //   Prefer: 'code=403',
        // },
        // // ここまで
      }),
      invalidatesTags: ['Customer'],
    }),
    resendTmpPasswordCustomer: builder.mutation({
      query: (customerId) => ({
        url: `/customers/${customerId}/resend-initiate-auth`,
        method: 'GET',
        // // TODO: デバッグ用のコードなので、本番では削除する
        // // ここから
        // headers: {
        //   // mock でエラーを返したい場合、ここの数字を変更することで、
        //   // 特定のステータスコードのエラーを発生させることができる
        //   Prefer: 'code=403',
        // },
        // // ここまで
      }),
    }),
    getSuppliers: builder.query<Supplier[], void>({
      query: () => '/suppliers',
      providesTags: ['Supplier'],
    }),
    getSupplier: builder.query<Supplier, string>({
      query: (supplierId) => ({
        url: `/suppliers/${supplierId}`,
        method: 'GET',
        // // TODO: デバッグ用のコードなので、本番では削除する
        // // ここから
        // headers: {
        //   // mock でエラーを返したい場合、ここの数字を変更することで、
        //   // 特定のステータスコードのエラーを発生させることができる
        //   Prefer: 'code=403',
        // },
        // // ここまで
      }),
      providesTags: ['Supplier'],
    }),
    addSupplier: builder.mutation({
      query: (supplierData) => ({
        url: '/suppliers',
        method: 'POST',
        body: supplierData,
        // // TODO: デバッグ用のコードなので、本番では削除する
        // // ここから
        // headers: {
        //   // mock でエラーを返したい場合、ここの数字を変更することで、
        //   // 特定のステータスコードのエラーを発生させることができる
        //   Prefer: 'code=409',
        // },
        // // ここまで
      }),
      invalidatesTags: ['Supplier'],
    }),
    editSupplier: builder.mutation({
      query: ({ supplierId, ...supplierData }) => ({
        url: `/suppliers/${supplierId}`,
        method: 'PUT',
        body: supplierData,
        // // TODO: デバッグ用のコードなので、本番では削除する
        // // ここから
        // headers: {
        //   // mock でエラーを返したい場合、ここの数字を変更することで、
        //   // 特定のステータスコードのエラーを発生させることができる
        //   Prefer: 'code=424',
        // },
        // // ここまで
      }),
      invalidatesTags: ['Supplier', 'User'],
    }),
    disableSupplier: builder.mutation({
      query: (supplierId) => ({
        url: `/suppliers/${supplierId}/disable`,
        method: 'GET',
        // // TODO: デバッグ用のコードなので、本番では削除する
        // // ここから
        // headers: {
        //   // mock でエラーを返したい場合、ここの数字を変更することで、
        //   // 特定のステータスコードのエラーを発生させることができる
        //   Prefer: 'code=403',
        // },
        // // ここまで
      }),
      invalidatesTags: ['Supplier', 'User'],
    }),
    resendTmpPasswordSupplier: builder.mutation({
      query: (supplierId) => ({
        url: `/suppliers/${supplierId}/resend-initiate-auth`,
        method: 'GET',
        // // TODO: デバッグ用のコードなので、本番では削除する
        // // ここから
        // headers: {
        //   // mock でエラーを返したい場合、ここの数字を変更することで、
        //   // 特定のステータスコードのエラーを発生させることができる
        //   Prefer: 'code=403',
        // },
        // // ここまで
      }),
    }),
    getAnnouncement: builder.query<Announcement, string>({
      query: (announcementId) => ({
        url: `/announcements/${announcementId}`,
        method: 'GET',
        // // TODO: デバッグ用のコードなので、本番では削除する
        // // ここから
        // headers: {
        //   // mock でエラーを返したい場合、ここの数字を変更することで、
        //   // 特定のステータスコードのエラーを発生させることができる
        //   Prefer: 'code=403',
        // },
        // // ここまで
      }),
      providesTags: ['Announcement'],
    }),
    editAnnouncement: builder.mutation({
      query: ({ announcementId, ...body }) => ({
        url: `/announcements/${announcementId}`,
        method: 'PUT',
        body,
        // // TODO: デバッグ用のコードなので、本番では削除する
        // // ここから
        // headers: {
        //   // mock でエラーを返したい場合、ここの数字を変更することで、
        //   // 特定のステータスコードのエラーを発生させることができる
        //   Prefer: 'code=403',
        // },
        // // ここまで
      }),
      invalidatesTags: ['Announcement'],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectQuery,
  useAddNewProjectMutation,
  useEditProjectMutation,
  useEditConfirmationURLMutation,
  useDeleteProjectMutation,
  useAddDiversionProjectMutation,
  useGetSubmitFileMutation,
  useGetUserQuery,
  useEditUserMutation,
  useChangePasswordMutation,
  useGetCustomersQuery,
  useGetCustomerQuery,
  useAddCustomerMutation,
  useEditCustomerMutation,
  useDisableCustomerMutation,
  useResendTmpPasswordCustomerMutation,
  useGetSuppliersQuery,
  useGetSupplierQuery,
  useAddSupplierMutation,
  useEditSupplierMutation,
  useDisableSupplierMutation,
  useResendTmpPasswordSupplierMutation,
  useGetAnnouncementQuery,
  useEditAnnouncementMutation,
  useAcceptConfirmationVideoMutation,
  useSendBackConfirmationVideoMutation,
} = apiSlice;
