import styled from 'styled-components';
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useGetUserQuery } from 'features/api/apiSlice';
import PrimaryRoundButton from 'common/components/atoms/PrimaryRoundButton';
import Loading from 'common/components/molecules/Loading';
import AppHeader from 'common/components/molecules/AppHeader';
import AppSidebar from 'common/components/orgnisms/AppSidebar';
import strings from 'common/utils/freeze/strings';

import CustomerInfo from './components/CustomerInfo';
import SuppliersInfo from './components/SuppliersInfo';
import DisableButton from './components/DisableButton';
import DisableDialog from './components/DisableDialog';
import ResendTmpPasswordDialog from './components/ResendTmpPasswordDialog';
import useEditCustomer from './hooks/useEditCustomer';
import useDisableAccount from './hooks/useDisableAccount';
import useResendTmpPassword from './hooks/useResendTmpPassword';

const { HEADER_TITLE } = strings.editCustomer;
const { CUSTOMER_DATA, TOPPAN_DATA } = strings.editCustomer.TITLE;
const { SAVE, DISABLE, RESEND_PASSWORD } = strings.editCustomer.BUTTON;
const { ERROR_GET_USER } = strings.editCustomer.completionMessage;

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Main = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const Contents = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-1);
  color: var(--font-color-1);
`;

const TopContents = styled.div`
  padding: 2rem 3.5rem;
  flex: 1;
  overflow: scroll;
`;

const ToppanDataTitle = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  font-size: var(--medium-font-size);
  margin: 0;
`;

const ErrorText = styled.p`
  margin: 0;
  margin-left: 1rem;
  color: #f44336;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  margin: 0 auto;
  background-color: var(--line-color);
`;

const BottomContents = styled.div`
  display: flex;
  background-color: var(--background-color-2);
  padding: 1.8rem;
`;

const NormalButtonArea = styled.div`
  margin: 0 0 0 auto;
`;

type EditCustomerProps = RouteComponentProps<{
  userId: string;
}>;

const EditCustomer: React.FC<EditCustomerProps> = (props: EditCustomerProps) => {
  const { match } = props;
  const { userId } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: userInfo,
    isLoading: isLoadingGetUser,
    isSuccess: isSuccessGetUser,
    isError: isErrorGetUser,
  } = useGetUserQuery();

  useEffect(() => {
    if (isErrorGetUser) {
      enqueueSnackbar(ERROR_GET_USER, { variant: 'error' });
    }
  }, [isErrorGetUser, enqueueSnackbar]);

  const {
    isLoadingGetCustomer,
    isDisabledSalesMailField,
    isLoadingEditCustomer,
    visibleResendTmpPass,
    save,
    errorSupplierMailNotRegisterd,
    customerName,
    customerCD,
    department,
    manager,
    accountName,
    email,
    salesEmail,
    movEmail,
    tgcEmail,
  } = useEditCustomer(userId, userInfo, isSuccessGetUser);

  const {
    isLoading: isLoadingResendTmpPassword,
    open: openResendTmpPasswordDialog,
    onClickResendButton,
    onClickCancel: onClickResendCancel,
    onClickOk: onClickResendOk,
  } = useResendTmpPassword(userId);

  const {
    isLoading: isLoadingDisableAccount,
    open: openDisableDialog,
    visible: visibleDisableButton,
    onClickDisableButton,
    onClickCancel: onClickDisableCancel,
    onClickOk: onClickDisableOk,
  } = useDisableAccount(userId, userInfo, isSuccessGetUser);

  const isLoadingReceive = isLoadingGetUser || isLoadingGetCustomer;
  const isLoadingSend = isLoadingEditCustomer
    || isLoadingDisableAccount
    || isLoadingResendTmpPassword;

  return (
    isLoadingReceive ? <Loading open /> : (
      <PageContainer>
        <ResendTmpPasswordDialog
          open={openResendTmpPasswordDialog}
          onClickCancel={onClickResendCancel}
          onClickOk={onClickResendOk}
        />
        <DisableDialog
          open={openDisableDialog}
          onClickCancel={onClickDisableCancel}
          onClickOk={onClickDisableOk}
        />
        <Loading open={isLoadingSend} />
        <AppHeader title={HEADER_TITLE} />
        <Main>
          <AppSidebar initState={false} />
          <Contents>
            <TopContents>
              <div>
                <Title>{CUSTOMER_DATA}</Title>
                <Line />
              </div>
              <CustomerInfo
                customerName={customerName.value}
                customerCD={customerCD.value}
                department={department.value}
                manager={manager.value}
                accountName={accountName.value}
                email={email.value}
                handleChangeCustomerName={customerName.onChange}
                handleChangeCustomerCD={customerCD.onChange}
                handleChangeDepartment={department.onChange}
                handleChangeManager={manager.onChange}
                handleChangeAccountName={accountName.onChange}
                handleChangeEmail={email.onChange}
                customerNameError={customerName.error}
                customerCDError={customerCD.error}
                accountNameError={accountName.error}
                emailError={email.error}
              />
              <div style={{ marginTop: '2.4rem' }}>
                <ToppanDataTitle>
                  <Title>{TOPPAN_DATA}</Title>
                  <ErrorText>{errorSupplierMailNotRegisterd}</ErrorText>
                </ToppanDataTitle>
                <Line />
              </div>
              <SuppliersInfo
                salesEmail={salesEmail.value}
                movEmail={movEmail.value}
                tgcEmail={tgcEmail.value}
                handleChangeSalesEmail={salesEmail.onChange}
                handleChangeMovEmail={movEmail.onChange}
                handleChangeTgcEmail={tgcEmail.onChange}
                salesEmailError={salesEmail.error}
                movEmailError={movEmail.error}
                tgcEmailError={tgcEmail.error}
                isDisabledSalesMailField={isDisabledSalesMailField}
              />
            </TopContents>
            <BottomContents>
              {(visibleDisableButton) && (
                <DisableButton onClick={onClickDisableButton}>
                  {DISABLE}
                </DisableButton>
              )}
              <NormalButtonArea>
                {(visibleResendTmpPass) && (
                  <PrimaryRoundButton onClick={onClickResendButton}>
                    {RESEND_PASSWORD}
                  </PrimaryRoundButton>
                )}
                <PrimaryRoundButton onClick={save}>
                  {SAVE}
                </PrimaryRoundButton>
              </NormalButtonArea>
            </BottomContents>
          </Contents>
        </Main>
      </PageContainer>
    )
  );
};

export default EditCustomer;
