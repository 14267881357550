import { useState } from 'react';

import regex from 'common/utils/freeze/regex';
import strings from 'common/utils/freeze/strings';

type ReturnType = {
  newPassword: string
  newPasswordCheck: string
  reCAPTCHAToken: string | null
  handleChangeNewPassword: (value: string) => void
  handleChangeNewPasswordCheck: (value: string) => void
  handleChangeReCAPTCHAToken: (value: string | null) => void
  newPasswordError: string
  newPasswordCheckError: string
  reCAPTCHATokenError: string
  validationCheck: () => boolean
}

const useNewPasswordForm = (): ReturnType => {
  const {
    REQUIRED,
    PASSWORD_FORMAT,
    CONFIRM_ERROR_NEW_PASSWORD,
    CONFIRM_ERROR_CONFIRM_PASSWORD,
    REQUIRED_RECAPTCHA,
  } = strings.changeTemporaryPassword.validationError;

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordCheck, setNewPasswordCheck] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [newPasswordCheckError, setNewPasswordCheckError] = useState('');
  const [reCAPTCHAToken, setReCAPTHCAToken] = useState<string | null>('');
  const [reCAPTCHATokenError, setReCAPTHCATokenError] = useState('');

  const handleChangeNewPassword = (value: string) => {
    setNewPassword(value);
  };

  const handleChangeNewPasswordCheck = (value: string) => {
    setNewPasswordCheck(value);
  };

  const handleChangeReCAPTCHAToken = (value: string | null) => {
    setReCAPTHCAToken(value);
  };

  const validationCheck = (): boolean => {
    let requiredError = false;

    if (newPassword.trim() === '') {
      setNewPasswordError(REQUIRED);
      requiredError = true;
    } else if (!regex.PASSWORD.test(newPassword)) {
      setNewPasswordError(PASSWORD_FORMAT);
      setNewPassword('');
      requiredError = true;
    } else {
      setNewPasswordError('');
    }

    if (newPasswordCheck.trim() === '') {
      setNewPasswordCheckError(REQUIRED);
      requiredError = true;
    } else if (newPasswordCheck !== newPassword) {
      setNewPasswordError(CONFIRM_ERROR_NEW_PASSWORD);
      setNewPasswordCheckError(CONFIRM_ERROR_CONFIRM_PASSWORD);
      setNewPassword('');
      setNewPasswordCheck('');
      requiredError = true;
    } else {
      setNewPasswordCheckError('');
    }

    /*     if (reCAPTCHAToken === '') {
      setReCAPTHCATokenError(REQUIRED_RECAPTCHA);
      requiredError = true;
    } else {
      setReCAPTHCATokenError('');
    } */

    return requiredError;
  };

  return {
    newPassword,
    newPasswordCheck,
    reCAPTCHAToken,
    handleChangeNewPassword,
    handleChangeNewPasswordCheck,
    handleChangeReCAPTCHAToken,
    newPasswordError,
    newPasswordCheckError,
    reCAPTCHATokenError,
    validationCheck,
  };
};

export default useNewPasswordForm;
