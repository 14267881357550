import styled from 'styled-components';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { VirusStatus } from 'features/api/types';

const VirusCheckWrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
`;

type VirusCheckProps = {
  status: VirusStatus
}

const VirusCheck: React.FC<VirusCheckProps> = (props: VirusCheckProps) => {
  const { status } = props;
  if (status === 'OK') {
    return (
      <VirusCheckWrapper>
        <CheckCircleOutlineIcon style={{ color: '#2EDE0D', marginRight: '0.2rem' }} />
        チェック済み
      </VirusCheckWrapper>
    );
  } if (status === 'NG') {
    return (
      <VirusCheckWrapper>
        <CancelIcon style={{ color: '#FF0000', marginRight: '0.2rem' }} />
        エラー
      </VirusCheckWrapper>
    );
  }
  return <>...ウイルスチェック中</>;
};

export default VirusCheck;
