import {
  TableBody, TableRow, TableCell, withStyles, createStyles,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import fileSizeUnit from 'pages/projectDetail/utils/fileSizeUnit';
import { ProjectFile } from 'features/api/types';
import VirusCheck from './VirusCheck';
import formatDate from '../utils/formatDate';
import getFileType from '../utils/getFileType';

const StyledTableCell = withStyles(() => createStyles({
  body: {
    fontSize: 12,
    color: '#707070',
  },
}))(TableCell);

type DeliveryFileTableBodyProps = {
  productions: ProjectFile[]
  selected: number
  handleSelectedFile: (fileId: number) => void
}

const DeliveryFileTableBody: React.FC<DeliveryFileTableBodyProps> = (
  props: DeliveryFileTableBodyProps,
) => {
  const {
    productions, selected, handleSelectedFile,
  } = props;

  return (
    <TableBody>
      {productions.map((file, index) => {
        const isItemSelected = (selected === file.id);
        const labelId = `file-table-checkbox-${index}`;

        return (
          <TableRow key={file.id}>
            <TableCell padding="checkbox">
              <Radio
                checked={isItemSelected}
                onClick={() => { handleSelectedFile(file.id); }}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </TableCell>
            <StyledTableCell>{file.name}</StyledTableCell>
            <StyledTableCell>{getFileType(file.name)}</StyledTableCell>
            <StyledTableCell>{file.size !== null ? fileSizeUnit(file.size) : '--B'}</StyledTableCell>
            <StyledTableCell>{formatDate(file.updated_at)}</StyledTableCell>
            <StyledTableCell>{file.uploaded_by}</StyledTableCell>
            <StyledTableCell>
              <VirusCheck status={file.status} />
            </StyledTableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default DeliveryFileTableBody;
