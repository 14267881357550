import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

import { useInitiateAuthMutation } from 'features/api/unAuthApiSlice';
import getUser from 'features/api/axios/getUser';
import { selectFirstLoginUserInfo, setFirstLoginUserInfo } from 'features/auth/firstLoginUserInfoSlice';
import { useAppSelector, useAppDispatch } from 'common/hooks';
import { Role } from 'common/types/Role';
import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';

type ReturnType = {
  isLoading: boolean
  changePassword: (newPassword: string, reCAPTCHAToken: string) => Promise<void>
}

const useChangePassword = (): ReturnType => {
  const {
    SUCCESS_CHANGE_PASSWORD,
    ERROR_CHANGE_PASSWORD,
  } = strings.changeTemporaryPassword.completionMessage;

  const [isLoading, setIsLoding] = useState(false);
  const firstLoginUserInfo = useAppSelector(selectFirstLoginUserInfo);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [initiateAuth] = useInitiateAuthMutation();

  const movePage = async () => {
    const res = await getUser();

    if (res.error) {
      throw new Error('APIエラー');
    }

    if (res.data) {
      if (res.data.role === Role.customer) {
        history.push({ pathname: pageUrl.EDIT_ACCOUNT_DATA });
      } else {
        history.push({ pathname: pageUrl.TOP });
      }
    }

    enqueueSnackbar(SUCCESS_CHANGE_PASSWORD, { variant: 'success' });
    dispatch(setFirstLoginUserInfo({ session: '', id: '' }));
  };

  const changePassword = async (newPassword: string, reCAPTCHAToken: string) => {
    if (isLoading) {
      return;
    }

    setIsLoding(true);
    try {
      await initiateAuth({
        session: firstLoginUserInfo.session,
        username: firstLoginUserInfo.id,
        proposed_password: newPassword,
      }).unwrap();
      await Auth.signIn(firstLoginUserInfo.id, newPassword, { captcha: reCAPTCHAToken });
      await movePage();
    } catch (e) {
      setIsLoding(false);
      enqueueSnackbar(ERROR_CHANGE_PASSWORD, { variant: 'error' });
    }
  };

  return {
    isLoading,
    changePassword,
  };
};

export default useChangePassword;
