import { Table, TableContainer } from '@material-ui/core';
import { ProjectFile } from 'features/api/types';
import { FileTableHeadCell } from '../type';
import FileTableHead from './FileTableHead';
import SubmitFileListTableBody from './SubmitFileListTableBody';

type SubmitFileListTableProps = {
  files: ProjectFile[];
  selectedFileId: number;
  handleSelectedFile: (fileId: number) => void;
}

const SubmitFileListTable: React.FC<SubmitFileListTableProps> = (
  props: SubmitFileListTableProps,
) => {
  const { files, selectedFileId, handleSelectedFile } = props;
  const tableHeadCells: FileTableHeadCell[] = [
    { align: 'left', label: 'ファイル名' },
    { align: 'left', label: 'ファイル形式' },
    { align: 'left', label: 'サイズ' },
    { align: 'left', label: 'アップロード日時' },
    { align: 'left', label: 'ユーザ名' },
    { align: 'left', label: 'ウイルスチェック' },
  ];

  return (
    <TableContainer style={{ height: '100%' }}>
      <Table stickyHeader>
        <FileTableHead cells={tableHeadCells} />
        <SubmitFileListTableBody
          files={files}
          selectedFileId={selectedFileId}
          handleSelectedFile={handleSelectedFile}
        />
      </Table>
    </TableContainer>
  );
};

export default SubmitFileListTable;
