import { ProjectStatus } from 'common/types/ProjectStatus';
import { Role } from 'common/types/Role';

export const confirmationUrlSaveDisabled = (
  projectStatus: ProjectStatus, userRole: Role,
): boolean => {
  if (userRole === Role.customer || userRole === Role.sales) return true;

  switch (projectStatus) {
    case 'pre_submission':
    case 'TBC_sales':
    case 'TBC_customer':
    case 'WIP_production':
    case 'done':
      return true;
    case 'WIP_first':
    case 'WIP_sent_back':
      return false;
    default: {
      const exhaustivenessCheck: never = projectStatus;
      throw new Error(`Unsupported type ${exhaustivenessCheck}`);
    }
  }
};

export const isDisplayApprovalAndReturnButton = (
  projectStatus: ProjectStatus, userRole: Role,
): boolean => {
  if (userRole === Role.customer) {
    return projectStatus === 'TBC_customer';
  }

  if (userRole === Role.sales) {
    return projectStatus === 'TBC_sales';
  }

  if (userRole === Role.movring) {
    return projectStatus === 'WIP_production' || projectStatus === 'TBC_sales';
  }

  if (userRole === Role.admin) {
    return projectStatus === 'WIP_production';
  }

  const exhaustivenessCheck: never = userRole;
  throw new Error(`Unsupported type ${exhaustivenessCheck}`);
};
