import axios from 'axios';

import strings from 'common/utils/freeze/strings';
import ApiUrl from 'common/utils/freeze/ApiUrl';
import getToken from 'features/auth/getToken';

const { BASE_URL } = ApiUrl;

type ResponseType = {
  message: string
  url: string
}

type GetFileDonwloadUrl = {
  response: { message: string, url: string } | null
  error: string | null
}

const getFileDownloadUrl = async (
  projectId: string,
  fileId: number,
): Promise<GetFileDonwloadUrl> => {
  try {
    const { idToken, accessToken } = await getToken();
    const response = await axios.get<ResponseType>(
      `${BASE_URL}/projects/${projectId}/file-download-url/${fileId}`,
      {
        headers: {
          authorization: idToken,
          accesstoken: accessToken,
        },
      },
    );
    return { response: response.data, error: null };
  } catch (e) {
    return { response: null, error: strings.ERROR_DESCRIPTION_DOWNLOAD_FILE };
  }
};

export default getFileDownloadUrl;
