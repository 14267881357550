import { createStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const StyledButton = withStyles(() => createStyles({
  root: {
    borderRadius: 50,
    minWidth: '190px',
    height: '46px',
    fontSize: '22px',
    marginLeft: '5px',
    marginRight: '5px',
  },
}))(Button);

type PrimaryRoundButtonProps = {
  children: React.ReactNode,
  variant?: 'outlined' | 'contained',
  onClick?: () => void,
};

const PrimaryRoundButton: React.FC<PrimaryRoundButtonProps> = (props: PrimaryRoundButtonProps) => {
  const {
    children,
    variant,
    onClick,
  } = props;

  return (
    <StyledButton
      color="primary"
      variant={variant}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

PrimaryRoundButton.defaultProps = {
  variant: 'contained',
  onClick: undefined,
};

export default PrimaryRoundButton;
