import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import ApiUrl from 'common/utils/freeze/ApiUrl';

const { BASE_URL } = ApiUrl;

export const unAuthApiSlice = createApi({
  reducerPath: 'unAuthApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: '/user/forgot-password',
        method: 'POST',
        body,
      }),
    }),
    confirmForgotPassword: builder.mutation({
      query: (body) => ({
        url: '/user/confirm-forgot-password',
        method: 'PUT',
        body,
      }),
    }),
    initiateAuth: builder.mutation({
      query: (authData) => ({
        url: '/user/initiate-auth',
        method: 'PUT',
        body: authData,
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useForgotPasswordMutation,
  useConfirmForgotPasswordMutation,
  useInitiateAuthMutation,
} = unAuthApiSlice;
