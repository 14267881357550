import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Radio from '@material-ui/core/Radio';

import { RootState } from 'app/store';
import { useAppSelector, useAppDispatch } from 'common/hooks';
import {
  selectFilteredProjects,
  selectFilteredProjectsTotal,
  setSelectedProject,
} from 'features/projects/projectsSlice';
import LinkedProjectCell from './LinkedProjectCell';

type ProjectsTableBodyProps = {
  page: number,
  rowsPerPage: number,
};

const ProjectsTableBody: React.FC<ProjectsTableBodyProps> = (props: ProjectsTableBodyProps) => {
  const { page, rowsPerPage } = props;

  const dispatch = useAppDispatch();
  const count = useAppSelector(selectFilteredProjectsTotal);
  const projects = useAppSelector(selectFilteredProjects);
  const selected = useAppSelector((state: RootState) => state.projects.selected);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  return (
    <TableBody>
      {projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const isItemSelected = (selected === row.projectId);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.projectId}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                <Radio
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                  onClick={() => dispatch(setSelectedProject(row.projectId))}
                />
              </TableCell>
              <LinkedProjectCell align="left" id={row.projectId}>
                {row.customerCompany}
              </LinkedProjectCell>
              <LinkedProjectCell align="left" id={row.projectId}>
                {row.customerDepartment}
              </LinkedProjectCell>
              <LinkedProjectCell align="left" id={row.projectId}>
                {row.status}
              </LinkedProjectCell>
              <LinkedProjectCell align="center" id={row.projectId}>
                {row.projectId}
              </LinkedProjectCell>
              <LinkedProjectCell align="left" id={row.projectId}>
                {row.projectName}
              </LinkedProjectCell>
              <LinkedProjectCell align="left" id={row.projectId}>
                {row.updatedAt}
              </LinkedProjectCell>
              <LinkedProjectCell align="left" id={row.projectId}>
                {row.deliveredAt}
              </LinkedProjectCell>
            </TableRow>
          );
        })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
};

export default ProjectsTableBody;
