import ConfirmDialog from 'common/components/molecules/ConfirmDialog';
import strings from 'common/utils/freeze/strings';

const { TITLE, DESCRIPTION } = strings.projects.deleteDialog;

type DeleteDialogProps = {
  open: boolean,
  onClickCancel: () => void,
  onClickOk: () => void,
};

const DeleteDialog: React.FC<DeleteDialogProps> = (props: DeleteDialogProps) => {
  const {
    open,
    onClickCancel,
    onClickOk,
  } = props;

  return (
    <ConfirmDialog
      title={TITLE}
      description={DESCRIPTION}
      open={open}
      onClickCancel={onClickCancel}
      onClickOk={onClickOk}
    />
  );
};

export default DeleteDialog;
