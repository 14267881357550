import { createRef } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

import { RootState } from 'app/store';
import { useAppSelector, useAppDispatch } from 'common/hooks';
import { selectFilteredSuppliersTotal, setPage } from 'features/suppliers/suppliersSlice';
import TablePagination from 'common/components/molecules/TablePagination';
import { TableHeadCell } from 'features/suppliers/types';
import SuppliersTableHead from './SuppliersTableHead';
import SuppliersTableBody from './SuppliersTableBody';

const StyledPaper = withStyles(() => createStyles({
  root: {
    width: '100%',
    marginBottom: '30px',
    backgroundColor: '#FFFFFFE6',
  },
}))(Paper);

const StyledTable = withStyles(() => createStyles({
  root: {
    minWidth: 800,
  },
}))(Table);

type SuppliersTableProps = {
  headCells: TableHeadCell[],
};

const SuppliersTable: React.FC<SuppliersTableProps> = (props: SuppliersTableProps) => {
  const { headCells } = props;

  const dispatch = useAppDispatch();
  const count = useAppSelector(selectFilteredSuppliersTotal);
  const rowsPerPage = 10;
  const page = useAppSelector((state: RootState) => state.suppliers.page);
  const tableRef = createRef<HTMLTableElement>();

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(setPage(newPage));
    tableRef.current?.scrollIntoView();
  };

  return (
    <StyledPaper>
      <TableContainer ref={tableRef}>
        <StyledTable
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
          stickyHeader
        >
          <SuppliersTableHead headCells={headCells} />
          <SuppliersTableBody
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </StyledTable>
      </TableContainer>
      <TablePagination
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
      />
    </StyledPaper>
  );
};

export default SuppliersTable;
