import styled from 'styled-components';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import AppHeader from 'common/components/molecules/AppHeader';
import AppSidebar from 'common/components/orgnisms/AppSidebar';
import Loading from 'common/components/molecules/Loading';
import BackgroundPageStyle from 'common/styles/BackgroundPageStyle';
import strings from 'common/utils/freeze/strings';
import maxLength from 'common/utils/freeze/maxLength';
import SaveButton from 'common/components/atoms/SaveButtonWithCheckIcon';
import { useGetUserQuery } from 'features/api/apiSlice';

import useAnnouncements from './hooks/useAnnouncements';

const {
  PAGE_TITLE,
  LABEL_ANNOUNCEMENTS,
  BUTTON_SAVE,
  ERROR_GET_USER,
} = strings.topPage;

const Body = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const MainArea = styled.div`
  flex: 1;
  margin: 40px;
`;

const LabelAndButtonArea = styled.div`
  display: flex;
`;

const ButtonArea = styled.div`
  margin: 0 0 0 auto;
  height: 30px;
  padding: 5px;
`;

const StyledTextField = withStyles(() => ({
  root: {
    marginBottom: '40px',
    backgroundColor: 'white',
  },
}))(TextField);

// NOTE: disabled だと文字が薄すぎる（とまた言われそうなので）ので変える
const useStyles = makeStyles(() => ({
  inputRoot: {
    '&$disabled': {
      color: 'grey',
    },
  },
  disabled: {},
}));

const Top: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: userInfo,
    isLoading: isLoadingGetUser,
    isSuccess: isSuccessGetUser,
    isError: isErrorGetUser,
  } = useGetUserQuery();

  const {
    isLoading: isLoadingAnnouncement,
    canEdit,
    value,
    onChange,
    onClickSave,
  } = useAnnouncements(userInfo, isSuccessGetUser, '1');

  useEffect(() => {
    if (isErrorGetUser) {
      enqueueSnackbar(ERROR_GET_USER, { variant: 'error' });
    }
  }, [isErrorGetUser, enqueueSnackbar]);

  const isLoading = isLoadingGetUser || isLoadingAnnouncement;

  return (
    <BackgroundPageStyle>
      <AppHeader title={PAGE_TITLE} />
      <Body>
        <AppSidebar initState />
        <MainArea>
          <Loading open={isLoading} />
          <LabelAndButtonArea>
            <Typography variant="body1">
              {LABEL_ANNOUNCEMENTS}
            </Typography>
            <ButtonArea>
              {canEdit && (
                <SaveButton onClick={onClickSave}>
                  {BUTTON_SAVE}
                </SaveButton>
              )}
            </ButtonArea>
          </LabelAndButtonArea>
          <StyledTextField
            multiline
            variant="outlined"
            fullWidth
            minRows="10"
            maxRows="10"
            value={value}
            onChange={onChange}
            disabled={!canEdit}
            inputProps={{
              maxLength: maxLength.ANNOUNCEMENT,
            }}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                disabled: classes.disabled,
              },
            }}
          />
        </MainArea>
      </Body>
    </BackgroundPageStyle>
  );
};

export default Top;
