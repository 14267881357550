import { useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { useAddCustomerMutation } from 'features/api/apiSlice';
import { CustomerBody } from 'features/api/types';
import strings from 'common/utils/freeze/strings';
import regex from 'common/utils/freeze/regex';

const { REQUIRED, EMAIL_FORMAT, CUSTOMER_CODE_FORMAT } = strings.customerSignUp.validationError;
const { ERROR_DUPLICATE } = strings.customerSignUp.completionMessage;

type InputError = {
  customerName: string
  customerCD: string
  accountName: string
  email: string
  salesEmail: string
  movEmail: string
  tgcEmail: string
}

type Return = {
  isAddCustomer: boolean
  signUp: (
    customerName: string,
    customerCD: string,
    accountName: string,
    email: string,
    salesEmail: string,
    movEmail: string,
    tgcEmail: string,
  ) => void
  inputError: InputError
}

const useSignUp = (
  success: () => void,
  error: (status: number) => void,
): Return => {
  const [customerNameError, setCustomerNameError] = useState('');
  const [customerCDError, setCustomerCDError] = useState('');
  const [accountNameError, setAccountNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [salesEmailError, setSalesEmailError] = useState('');
  const [movEmailError, setMovEmailError] = useState('');
  const [tgcEmailError, setTgcEmailError] = useState('');

  const [
    addCustomer,
    { isLoading: isAddCustomer },
  ] = useAddCustomerMutation();

  const validation = (
    customerName: string,
    customerCD: string,
    accountName: string,
    email: string,
    salesEmail: string,
    movEmail: string,
    tgcEmail: string,
  ): boolean => {
    let requiredError = false;

    if (customerName.trim() === '') {
      requiredError = true;
      setCustomerNameError(REQUIRED);
    } else {
      setCustomerNameError('');
    }

    if (customerCD.trim() === '') {
      requiredError = true;
      setCustomerCDError(REQUIRED);
    } else if (!regex.CUSTOMER_CODE.test(customerCD)) {
      requiredError = true;
      setCustomerCDError(CUSTOMER_CODE_FORMAT);
    } else {
      setCustomerCDError('');
    }

    if (accountName.trim() === '') {
      requiredError = true;
      setAccountNameError(REQUIRED);
    } else {
      setAccountNameError('');
    }

    if (email.trim() === '') {
      requiredError = true;
      setEmailError(REQUIRED);
    } else if (!email.match(regex.EMAIL)) {
      requiredError = true;
      setEmailError(EMAIL_FORMAT);
    } else {
      setEmailError('');
    }

    if (salesEmail.trim() === '') {
      requiredError = true;
      setSalesEmailError(REQUIRED);
    } else if (!salesEmail.match(regex.EMAIL)) {
      requiredError = true;
      setSalesEmailError(EMAIL_FORMAT);
    } else {
      setSalesEmailError('');
    }

    if (movEmail.trim() === '') {
      requiredError = true;
      setMovEmailError(REQUIRED);
    } else if (!movEmail.match(regex.EMAIL)) {
      requiredError = true;
      setMovEmailError(EMAIL_FORMAT);
    } else {
      setMovEmailError('');
    }

    if (tgcEmail.trim() === '') {
      requiredError = true;
      setTgcEmailError(REQUIRED);
    } else if (!tgcEmail.match(regex.EMAIL)) {
      requiredError = true;
      setTgcEmailError(EMAIL_FORMAT);
    } else {
      setTgcEmailError('');
    }

    return requiredError;
  };

  // qtk queryにType Guardが実装されていなかったので、自前で実装しました。そのため `any` を使用。
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isFetchBaseQueryError = (err: any): err is FetchBaseQueryError => err.status !== undefined;

  const signUp = async (
    customerName: string,
    customerCD: string,
    accountName: string,
    email: string,
    salesEmail: string,
    movEmail: string,
    tgcEmail: string,
  ) => {
    const validationError = validation(
      customerName, customerCD, accountName, email, salesEmail, movEmail, tgcEmail,
    );

    if (validationError) {
      return;
    }

    const customerData: CustomerBody = {
      code: customerCD,
      company: customerName,
      name: accountName,
      mail: email,
      supplier: {
        sales: salesEmail,
        movring: movEmail,
        tgc: tgcEmail,
      },
    };

    if (!isAddCustomer) {
      try {
        await addCustomer(customerData).unwrap();
        success();
      } catch (err) {
        if (isFetchBaseQueryError(err)) {
          if (err.status === 404) {
            error(err.status);
          } else if (err.status === 409) {
            setEmailError(ERROR_DUPLICATE);
          } else {
            error(0);
          }
        } else {
          error(0);
        }
      }
    }
  };

  return {
    isAddCustomer,
    signUp,
    inputError: {
      customerName: customerNameError,
      customerCD: customerCDError,
      accountName: accountNameError,
      email: emailError,
      salesEmail: salesEmailError,
      movEmail: movEmailError,
      tgcEmail: tgcEmailError,
    },
  };
};

export default useSignUp;
