import { useState, useEffect } from 'react';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import strings from 'common/utils/freeze/strings';
import regex from 'common/utils/freeze/regex';
import maxLength from 'common/utils/freeze/maxLength';
import AddValueListDialog from 'common/components/orgnisms/AddValueListDialog';

const {
  MESSAGE,
  TEXT_LABEL_MAIL,
  BUTTON_CANCEL,
  BUTTON_SAVE,
  ERROR_LIMIT,
  ERROR_FORMAT,
} = strings.createProjectPage.notificationDialog;

type MailDialogProps = {
  open: boolean,
  onClose: () => void,
  title: string,
  fixedEmails: string[],
  savedEmails: string[],
  saveEmails: (emails: string[]) => void,
};

const MailSettingsDialog: React.FC<MailDialogProps> = (props: MailDialogProps) => {
  const {
    open,
    onClose,
    title,
    fixedEmails,
    savedEmails,
    saveEmails,
  } = props;

  const maxMailNum = 20;
  const [value, setValue] = useState('');
  const [emails, setEmails] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setEmails(savedEmails);
  }, [savedEmails]);

  const clearError = () => {
    if (errorMessage !== '') {
      setErrorMessage('');
    }
  };

  const handleRemoveIconClick = (removeEmail: string) => {
    setEmails(emails.filter((email) => email !== removeEmail));
    clearError();
  };

  const validate = (): boolean => {
    if (emails.indexOf(value) >= 0) {
      setValue('');
      return false;
    }

    if (emails.length >= maxMailNum) {
      setErrorMessage(ERROR_LIMIT);
      return false;
    }

    if (!value.match(regex.EMAIL)) {
      setErrorMessage(ERROR_FORMAT);
      return false;
    }

    clearError();
    return true;
  };

  const handleChangeValue = (newValue: string) => {
    setValue(newValue);
  };

  const handleAddItem = () => {
    if (value === '') {
      setValue('');
      return;
    }

    if (validate()) {
      setEmails([...emails, value]);
      setValue('');
    }
  };

  const handleCancel = () => {
    onClose();
    clearError();
    setEmails(savedEmails);
  };

  const handleSave = () => {
    if (value !== '') {
      if (!validate()) {
        return;
      }

      onClose();
      clearError();
      saveEmails([...emails, value]);
      setValue('');
    } else {
      onClose();
      clearError();
      saveEmails(emails);
    }
  };

  return (
    <AddValueListDialog
      open={open}
      title={title}
      description={MESSAGE}
      label={TEXT_LABEL_MAIL}
      value={value}
      fixedListItems={fixedEmails}
      listItems={emails}
      errorMessage={errorMessage}
      icon={PersonAddIcon}
      saveText={BUTTON_SAVE}
      cancelText={BUTTON_CANCEL}
      handleChangeValue={handleChangeValue}
      handleRemoveIconClick={handleRemoveIconClick}
      handleAddItem={handleAddItem}
      handleCancel={handleCancel}
      handleSave={handleSave}
      maxLength={maxLength.EMAIL}
    />
  );
};

export default MailSettingsDialog;
