import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { descendingComparator, stableSort } from 'common/utils/util';
import { Role } from 'common/types/Role';
import { Customer } from 'features/api/types';
import { CustomersListViewModel, Order } from 'features/customers/types';

export type Filtering = {
  selected: keyof CustomersListViewModel,
  text: string,
}

export type CustomersState = {
  customers: CustomersListViewModel[],
  order: Order,
  orderBy: keyof CustomersListViewModel,
  filtering: Filtering[],
  page: number,
};

export const initialState: CustomersState = {
  customers: [],
  order: 'asc',
  orderBy: 'company',
  filtering: [
    {
      selected: 'name',
      text: '',
    },
    {
      selected: 'company',
      text: '',
    },
  ],
  page: 0,
};

const getSales = (customer: Customer): string => {
  const sales = customer.suppliers.find((supplier) => supplier.role === Role.sales);
  if (!sales) {
    return '';
  }
  return sales.name;
};

const getMovring = (customer: Customer): string => {
  const movring = customer.suppliers.find((supplier) => supplier.role === Role.movring);
  if (!movring) {
    return '';
  }
  return movring.name;
};

const getTgc = (customer: Customer): string => {
  const tgc = customer.suppliers.find((supplier) => supplier.role === Role.admin);
  if (!tgc) {
    return '';
  }
  return tgc.name;
};

const getComparator = <Key extends keyof CustomersListViewModel>(order: Order, orderBy: Key): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number => ((order === 'desc') ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy));

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setCustomers: (state, action: PayloadAction<Customer[]>) => {
      const customers: CustomersListViewModel[] = action.payload.map((customer) => ({
        id: customer.id,
        company: customer.company,
        department: customer.department || '',
        name: customer.name,
        sales: getSales(customer),
        movring: getMovring(customer),
        tgc: getTgc(customer),
      }));

      state.customers = stableSort(customers, getComparator(state.order, state.orderBy));
      state.page = 0;
    },
    sortCustomers: (state, action: PayloadAction<keyof CustomersListViewModel>) => {
      const isAsc = state.orderBy === action.payload && state.order === 'asc';
      state.order = isAsc ? 'desc' : 'asc';
      state.orderBy = action.payload;
      state.customers = stableSort(state.customers, getComparator(state.order, state.orderBy));
      state.page = 0;
    },
    filterCustomers: (state, action: PayloadAction<Filtering[]>) => {
      state.filtering = action.payload;
      state.page = 0;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const {
  setCustomers,
  sortCustomers,
  filterCustomers,
  setPage,
} = customersSlice.actions;

export const selectAllCustomers = (
  state: RootState,
): CustomersListViewModel[] => state.customers.customers;
export const selectCustomersTotal = (state: RootState): number => state.customers.customers.length;

const filterdCustomers = (state: RootState): CustomersListViewModel[] => state.customers.customers
  .filter((customer) => (
    String(customer[state.customers.filtering[0].selected])
      .indexOf(state.customers.filtering[0].text.trim()) >= 0))
  .filter((customer) => (
    String(customer[state.customers.filtering[1].selected])
      .indexOf(state.customers.filtering[1].text.trim()) >= 0));

export const selectFilteredCustomers = (
  state: RootState,
): CustomersListViewModel[] => filterdCustomers(state);
export const selectFilteredCustomersTotal = (
  state: RootState,
): number => filterdCustomers(state).length;

export default customersSlice.reducer;
