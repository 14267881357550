import { Table, TableContainer } from '@material-ui/core';
import { Submission } from 'features/api/types';
import { FileTableHeadCell } from '../type';
import FileTableHead from './FileTableHead';
import SubmissionFileTableBody from './SubmissionFileTableBody';

type SubmissionFileTableProps = {
  submissions: Submission[];
  selectedRadio: string;
  handleSelectedRadio: (submitId: string) => void;
  handleSelectedSubmisson: (submitId: string) => void;
}

const SubmissionFileTable: React.FC<SubmissionFileTableProps> = (
  props: SubmissionFileTableProps,
) => {
  const {
    submissions, selectedRadio, handleSelectedRadio, handleSelectedSubmisson,
  } = props;
  const tableHeadCells: FileTableHeadCell[] = [
    { align: 'left', label: '入稿' },
    { align: 'left', label: '入稿日' },
    { align: 'left', label: 'ユーザ名' },
  ];

  return (
    <TableContainer style={{ height: '198px' }}>
      <Table stickyHeader style={{ cursor: 'pointer' }}>
        <FileTableHead cells={tableHeadCells} />
        <SubmissionFileTableBody
          submissions={submissions}
          selectedRadio={selectedRadio}
          handleSelectedRadio={handleSelectedRadio}
          handleSelectedSubmisson={handleSelectedSubmisson}
        />
      </Table>
    </TableContainer>
  );
};

export default SubmissionFileTable;
