import styled from 'styled-components';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import MultiLineTypography from 'common/components/atoms/MultiLineTypography';

const ButtonArea = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Form = styled.form`
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MessageArea = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledDialogTitle = withStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
}))(DialogTitle);

const StyledList = withStyles(() => createStyles({
  root: {
    paddingLeft: 30,
    paddingRight: 30,
  },
}))(List);

const StyledButton = withStyles(() => createStyles({
  root: {
    borderRadius: 50,
    width: '140px',
  },
}))(Button);

const StyledDivider = withStyles(() => createStyles({
  root: {
    marginTop: 10,
    marginBottom: 10,
  },
}))(Divider);

type AddValueListDialogProps = {
  open: boolean,
  title: string,
  description: string,
  label: string,
  value: string,
  fixedListItems: string[],
  listItems: string[],
  errorMessage: string,
  maxLength?: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any, // icon を受け取るために any 型を許す(https://stackoverflow.com/questions/57390540/how-to-use-material-ui-icon-v1-0-0-beta-36-in-tsx-component/57412808#57412808)
  saveText: string,
  cancelText: string,
  handleChangeValue: (value: string) => void,
  handleRemoveIconClick: (removeValue: string) => void,
  handleAddItem: () => void,
  handleCancel: () => void,
  handleSave: () => void,
};

const AddValueListDialog: React.FC<AddValueListDialogProps> = (props: AddValueListDialogProps) => {
  const {
    open,
    title,
    description,
    label,
    value,
    fixedListItems,
    listItems,
    errorMessage,
    maxLength,
    icon: Icon,
    saveText,
    cancelText,
    handleChangeValue,
    handleRemoveIconClick,
    handleAddItem,
    handleCancel,
    handleSave,
  } = props;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleAddItem();
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <StyledDialogTitle id="simple-dialog-title">{title}</StyledDialogTitle>
      <StyledList>
        <MessageArea>
          <MultiLineTypography text={description} variant="subtitle1" />
        </MessageArea>
        <StyledDivider />
        {fixedListItems.map((item) => (
          <ListItem key={item}>
            <ListItemText primary={item} />
          </ListItem>
        ))}
        {listItems.map((item) => (
          <ListItem key={item}>
            <ListItemText primary={item} />
            <ListItemIcon onClick={() => handleRemoveIconClick(item)}>
              <RemoveCircleIcon />
            </ListItemIcon>
          </ListItem>
        ))}
      </StyledList>
      <Form onSubmit={handleSubmit}>
        <IconButton type="submit" size="small">
          <Icon />
        </IconButton>
        <TextField
          autoFocus
          label={label}
          fullWidth
          value={value}
          onChange={(event) => {
            handleChangeValue(event.target.value);
          }}
          error={errorMessage !== ''}
          helperText={errorMessage}
          inputProps={{
            maxLength,
          }}
        />
      </Form>
      <ButtonArea>
        <StyledButton
          variant="outlined"
          color="primary"
          size="large"
          onClick={handleCancel}
        >
          {cancelText}
        </StyledButton>
        <StyledButton
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSave}
        >
          {saveText}
        </StyledButton>
      </ButtonArea>
    </Dialog>
  );
};

AddValueListDialog.defaultProps = {
  maxLength: undefined,
};

export default AddValueListDialog;
