import axios from 'axios';

import strings from 'common/utils/freeze/strings';
import ApiUrl from 'common/utils/freeze/ApiUrl';
import getToken from 'features/auth/getToken';

const { BASE_URL } = ApiUrl;

type ResponseType = {
  message: string
  url: string
}

type GetFileUploadReturn = {
  response: { message: string, url: string } | null
  error: string | null,
}

const getFileUploadUrl = async (
  projectId: string,
  type: string,
  filename: string,
  submitId: string | null,
): Promise<GetFileUploadReturn> => {
  try {
    const { idToken, accessToken } = await getToken();
    const response = await axios.get<ResponseType>(
      `${BASE_URL}/projects/${projectId}/file-upload-url`,
      {
        headers: {
          authorization: idToken,
          accesstoken: accessToken,
        },
        params: {
          type,
          filename,
          submitId,
        },
      },
    );
    return { response: response.data, error: null };
  } catch (e) {
    return { response: null, error: strings.ERROR_DESCRIPTION_UPLOAD_FILE };
  }
};

export default getFileUploadUrl;
