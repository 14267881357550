import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import PopoverComponent from 'common/components/molecules/PopoverComponent';
import CustomTextField from 'common/components/molecules/CustomTextField';

type PopoverInfoTextFieldProps = {
  label: string
  value: string
  onChange: (value: string) => void
  popoverMessage: string
  error?: boolean
  helperText?: string
  placeholder?: string
  type?: string
  disabled?: boolean
  maxLength?: number
}

const PopoverInfoTextField: React.FC<PopoverInfoTextFieldProps> = (
  props: PopoverInfoTextFieldProps,
) => {
  const {
    label,
    value,
    onChange,
    popoverMessage,
    error,
    helperText,
    placeholder,
    type,
    disabled,
    maxLength,
  } = props;

  return (
    <CustomTextField
      label={label}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
      maxLength={maxLength}
      content={(
        <PopoverComponent
          message={popoverMessage}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <InfoOutlinedIcon />
        </PopoverComponent>
      )}
    />
  );
};

PopoverInfoTextField.defaultProps = {
  error: false,
  helperText: '',
  placeholder: '',
  type: '',
  disabled: false,
  maxLength: undefined,
};

export default PopoverInfoTextField;
