import {
  TableHead, TableRow, TableCell, withStyles, createStyles,
} from '@material-ui/core';
import { FileTableHeadCell } from '../type';

const StyledTableCell = withStyles(() => createStyles({
  root: {
    fontSize: '10px',
    paddingTop: '0',
    paddingBottom: '0',
    color: '#9597A6',
    backgroundColor: '#DADBE6',
  },
}))(TableCell);

type FileTableHeadProps = {
  cells: FileTableHeadCell[]
}

const FileTableHead: React.FC<FileTableHeadProps> = (props: FileTableHeadProps) => {
  const { cells } = props;

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox" />
        {cells.map((headCell) => (
          <StyledTableCell
            key={headCell.label}
            align={headCell.align}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default FileTableHead;
