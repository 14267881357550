import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Role } from 'common/types/Role';
import { ProjectNotifications, ProjectNote } from 'features/api/types';
import ProjectInfoDateField from './ProjectInfoDateField';
import ProjectInfoCopiedFromField from './ProjectInfoCopiedFromField';
import NotificationSettingField from './NotificationSettingField';
import ProjectInfoTextbox from './ProjectInfoTextbox';

type ProjectInfoBodyProps = {
  userRole: Role,
  ownerName: string,
  copiedId: number | null,
  copiedName: string | null,
  submittedDate: string,
  confirmationReqDate: string,
  notifications: ProjectNotifications[],
  saveEmails: (emails: string[]) => void,
  saveEmailsToppan: (emails: string[]) => void,
  projectNote: ProjectNote | null,
  projectId: number,
  setErrorDialogOpen: (conflict: boolean) => void,
}

const ProjectInfoBody: React.FC<ProjectInfoBodyProps> = (props: ProjectInfoBodyProps) => {
  const {
    userRole,
    ownerName,
    copiedId,
    copiedName,
    submittedDate,
    confirmationReqDate,
    notifications,
    saveEmails,
    saveEmailsToppan,
    projectNote,
    projectId,
    setErrorDialogOpen,
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          {ownerName}
        </Typography>
      </Grid>
      <Grid item xs={4} container alignItems="center">
        <Grid item xs={12}>
          {(copiedId !== null) && (copiedName !== null) && (
            <ProjectInfoCopiedFromField
              copiedId={copiedId}
              copiedName={copiedName}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <ProjectInfoDateField
            submittedDate={submittedDate}
            confirmationReqDate={confirmationReqDate}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <ProjectInfoTextbox
          projectNote={projectNote}
          projectId={projectId}
          setErrorDialogOpen={setErrorDialogOpen}
        />
      </Grid>
      <Grid item xs={2} container text-alignItems="flex-end">
        <NotificationSettingField
          userRole={userRole}
          notifications={notifications}
          saveEmails={saveEmails}
          saveEmailsToppan={saveEmailsToppan}
        />
      </Grid>
    </Grid>
  );
};

export default ProjectInfoBody;
