import axios from 'axios';

import { User } from 'features/api/types';
import strings from 'common/utils/freeze/strings';
import ApiUrl from 'common/utils/freeze/ApiUrl';
import getToken from 'features/auth/getToken';

const { BASE_URL } = ApiUrl;

type ReturnType = {
  data: User | null
  error: string | null
}

const getUser = async (): Promise<ReturnType> => {
  try {
    const { idToken, accessToken } = await getToken();
    const response = await axios.get<User>(
      `${BASE_URL}/user`,
      {
        headers: {
          authorization: idToken,
          accesstoken: accessToken,
        },
      },
    );
    return { data: response.data, error: null };
  } catch (e) {
    return { data: null, error: strings.ERROR_DESCRIPTION_GET_USER };
  }
};

export default getUser;
