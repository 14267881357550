import { useState, useEffect } from 'react';
import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import { useAppDispatch } from 'common/hooks';
import { filterProjects, Filtering } from 'features/projects/projectsSlice';
import { ProjectsListViewModel, TableHeadCell } from 'features/projects/types';
import SelectAndInputField, { SelectorItem } from 'common/components/molecules/SelectAndInputField';

const Form = styled.form`
  display: flex;
  justify-content: flex-end;
`;

const SearchBox = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type SearchProjectsProps = {
  tableHeadCells: TableHeadCell[],
};

const SearchProjects: React.FC<SearchProjectsProps> = (props: SearchProjectsProps) => {
  const { tableHeadCells } = props;
  const dispatch = useAppDispatch();
  const selectorItems: SelectorItem[] = tableHeadCells.map((cell) => ({
    id: cell.id,
    label: cell.label,
  }));

  const [searchState1, setSearchState1] = useState<Filtering>({
    selected: 'projectId',
    text: '',
  });
  const [searchState2, setSearchState2] = useState<Filtering>({
    selected: 'customerCompany',
    text: '',
  });

  useEffect(() => {
    dispatch(filterProjects([searchState1, searchState2]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange1 = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchState1({
      selected: event.target.value as keyof ProjectsListViewModel,
      text: '',
    });
  };

  const handleInput1 = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchState1({
      ...searchState1,
      text: event.target.value as string,
    });
  };

  const handleChange2 = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchState2({
      selected: event.target.value as keyof ProjectsListViewModel,
      text: '',
    });
  };

  const handleInput2 = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchState2({
      ...searchState2,
      text: event.target.value as string,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(filterProjects([searchState1, searchState2]));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <SearchBox>
        <SelectAndInputField
          selectedItem={searchState1.selected}
          handleChange={handleChange1}
          selectorItems={selectorItems}
          inputValue={searchState1.text}
          handleInput={handleInput1}
        />
      </SearchBox>
      <SearchBox>
        <SelectAndInputField
          selectedItem={searchState2.selected}
          handleChange={handleChange2}
          selectorItems={selectorItems}
          inputValue={searchState2.text}
          handleInput={handleInput2}
        />
      </SearchBox>
      <Icon>
        <IconButton type="submit" size="small">
          <SearchIcon fontSize="large" />
        </IconButton>
      </Icon>
    </Form>
  );
};

export default SearchProjects;
