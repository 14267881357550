import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useGetCustomersQuery } from 'features/api/apiSlice';
import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';
import { User } from 'features/api/types';
import Loading from 'common/components/molecules/Loading';
import { AutoCompleteData } from './AutoCompleteTextField';
import CreateProjectForm from './CreateProjectForm';
import useCreateProject from '../hooks/useCreateProject';

const { ERROR_GET_CUSTOMERS } = strings.createProjectPage.completionMessage;

const {
  SUCCESS,
  ERROR,
} = strings.createProjectPage.completionMessage;

type ToppanPageProps = {
  userInfo: User,
};

const ToppanPage: React.FC<ToppanPageProps> = (props: ToppanPageProps) => {
  const { userInfo } = props;

  const [projectName, setProjectName] = useState('');
  const [accountName, setAccountName] = useState<AutoCompleteData>({ id: 0, label: '' });
  const [emails, setEmails] = useState<string[]>([]);
  const [emailsToppan, setEmailsToppan] = useState<string[]>([]);
  const [fixedEmails, setFixedEmails] = useState<string[]>([]);
  const [fixedEmailsToppan, setFixedEmailsToppan] = useState<string[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const {
    data: customers,
    isSuccess,
    isError,
  } = useGetCustomersQuery();

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(ERROR_GET_CUSTOMERS, { variant: 'error' });
    }
  }, [isError, enqueueSnackbar]);

  useEffect(() => {
    const selectedUser = (customers)
      ? customers.find((customer) => customer.id === accountName.id)
      : null;
    if (selectedUser) {
      setFixedEmails([selectedUser.mail]);
      const mails = selectedUser.suppliers.map((surpplier) => surpplier.mail);
      setFixedEmailsToppan(mails);
    } else {
      setFixedEmails([]);
      setFixedEmailsToppan([]);
    }
  }, [customers, accountName]);

  const [accountData, setAccountData] = useState<AutoCompleteData[]>([]);

  useEffect(() => {
    if (isSuccess && customers) {
      setAccountData(customers.map((customer) => {
        const label = (customer.department)
          ? `${customer.company} / ${customer.department} / ${customer.name}`
          : `${customer.company} / ${customer.name}`;

        return {
          id: customer.id,
          label,
        };
      }));
    }
  }, [isSuccess, customers]);

  const handleChangeProjectName = (value: string) => {
    setProjectName(value);
  };

  const handleChangeAccountName = (value: AutoCompleteData) => {
    setAccountName(value);
  };

  const saveEmails = (newEmails: string[]) => {
    setEmails(newEmails);
  };

  const saveEmailsToppan = (newEmails: string[]) => {
    setEmailsToppan(newEmails);
  };

  const clearForm = () => {
    setProjectName('');
    setAccountName({ id: 0, label: '' });
    setEmails([]);
    setEmailsToppan([]);
    setFixedEmails([]);
    setFixedEmailsToppan([]);
  };

  const success = (projectId: number) => {
    clearForm();
    history.push(`${pageUrl.PROJECTS_LIST}/${projectId}`);
    enqueueSnackbar(SUCCESS, {
      variant: 'success',
    });
  };

  const error = () => {
    enqueueSnackbar(ERROR, { variant: 'error' });
  };

  const {
    isLoading,
    createProject,
    inputError,
  } = useCreateProject(success, error);

  const handleClickSaveButton = async () => {
    createProject(
      projectName,
      accountName,
      emails,
      emailsToppan,
    );
  };

  return (
    <>
      <Loading open={isLoading} />
      <CreateProjectForm
        role={userInfo.role}
        accountData={accountData}
        fixedEmails={fixedEmails}
        fixedEmailsToppan={fixedEmailsToppan}
        projectName={projectName}
        projectNameError={inputError.projectName}
        accountName={accountName}
        accountNameError={inputError.accountName}
        emails={emails}
        emailsToppan={emailsToppan}
        handleChangeProjectName={handleChangeProjectName}
        handleChangeAccountName={handleChangeAccountName}
        saveEmails={saveEmails}
        saveEmailsToppan={saveEmailsToppan}
        handleClickSaveButton={handleClickSaveButton}
      />
    </>
  );
};

export default ToppanPage;
