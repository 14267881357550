import styled from 'styled-components';
import DehazeIcon from '@material-ui/icons/Dehaze';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { resetState } from 'common/actions';
import { User } from 'features/api/types';
import { Role } from 'common/types/Role';
import { useAppDispatch } from 'common/hooks';
import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';
import SidebarAccountMenu from './SidebarAccountMenu';
import SidebarProjectMenu from './SidebarProjectMenu';
import SidebarOtherMenu from './SidebarOtherMenu';
import SidebarExternalMenu from './SidebarExternalMenu';

const OpenSidebarWrapper = styled.div`
  box-sizing: border-box;
  background-color: var(--theme-color);
  height: 100%;
  width: 250px;
  color: var(--font-color-1);
  overflow: scroll;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const SidebarMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: var(--sidebar-menu-font-size);
  flex: 1;
  padding-bottom: 1.2rem;
`;

type OpenSidebarProps = {
  handleToggle: () => void
  userInfo: User | undefined
  isLoading: boolean
}

const OpenSidebar: React.FC<OpenSidebarProps> = (props: OpenSidebarProps) => {
  const { handleToggle, userInfo, isLoading } = props;
  const { ERROR } = strings.sidebar.completionMessage.logout;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const signOut = async () => {
    try {
      await Auth.signOut();
      history.push({ pathname: pageUrl.SIGNIN });
      dispatch(resetState());
    } catch {
      enqueueSnackbar(ERROR, { variant: 'error' });
    }
  };

  return (
    <OpenSidebarWrapper>
      <div style={{ textAlign: 'end', padding: '1rem 0.8rem 0' }}>
        <DehazeIcon onClick={handleToggle} style={{ fontSize: 30, cursor: 'pointer' }} />
      </div>
      {isLoading
        ? <CircularProgress color="inherit" style={{ margin: 'auto' }} />
        : (
          <SidebarMenu>
            {userInfo && userInfo.role !== Role.customer && (
              <SidebarAccountMenu userInfo={userInfo} />
            )}
            <SidebarProjectMenu />
            <SidebarOtherMenu userInfo={userInfo} signOut={signOut} />
            <SidebarExternalMenu />
          </SidebarMenu>
        )}
    </OpenSidebarWrapper>
  );
};

export default OpenSidebar;
