import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { User } from 'features/api/types';
import { useEditUserMutation } from 'features/api/apiSlice';
import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';
import regex from 'common/utils/freeze/regex';
import checkBlankAndBlankConvertNull from 'common/utils/checkBlankAndBlankConvertNull';

type useEditAccountProps = {
  userInfo: User | undefined
}

type Return = {
  department: string
  manager: string
  preferredId: string
  handleChangeDepartment: (value: string) => void
  handleChangeManager: (value: string) => void
  handleChangePreferredId: (value: string) => void
  managerError: string
  preferredIdError: string
  handleSave: () => void
  isLoadingEditUser: boolean
}

const useEditAccount = (props: useEditAccountProps): Return => {
  const { REQUIRED, LOGIN_ID_ERROR } = strings.editAccountData.validationError;
  const {
    SUCCESS_EDIT_USER,
    ERROR_EDIT_USER,
    ERROR_DUPLICATE,
  } = strings.editAccountData.completionMessage;
  const { userInfo } = props;

  const [department, setDepartment] = useState('');
  const [manager, setManager] = useState('');
  const [preferredId, setPreferredId] = useState('');
  const [managerError] = useState('');
  const [preferredIdError, setPreferredIdError] = useState('');

  const [editUser, { isLoading }] = useEditUserMutation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  // RTK queryにType Guardが実装されていなかったので、自前で実装しました。そのため `any` を使用。
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isFetchBaseQueryError = (err: any): err is FetchBaseQueryError => err.status !== undefined;

  useEffect(() => {
    if (userInfo) {
      setDepartment(userInfo.department || '');
      setManager(userInfo.manager || '');
      setPreferredId(userInfo.preferred_id || '');
    }
  }, [userInfo]);

  const handleChangeDepartment = (value: string) => {
    setDepartment(value);
  };

  const handleChangeManager = (value: string) => {
    setManager(value);
  };

  const handleChangePreferredId = (value: string) => {
    setPreferredId(value);
  };

  const validationCheck = (): boolean => {
    let requiredError = false;

    if (preferredId.trim() === '') {
      setPreferredIdError(REQUIRED);
      requiredError = true;
    } else if (!regex.LOGIN_ID.test(preferredId)) {
      setPreferredIdError(LOGIN_ID_ERROR);
      requiredError = true;
    } else {
      setPreferredIdError('');
    }

    return requiredError;
  };

  const handleSave = async () => {
    if (!validationCheck()) {
      try {
        await editUser({
          preferred_id: preferredId.trim(),
          department: checkBlankAndBlankConvertNull(department.trim()),
          manager: manager.trim(),
        }).unwrap();
        enqueueSnackbar(SUCCESS_EDIT_USER, { variant: 'success' });
        history.push({ pathname: pageUrl.TOP });
      } catch (err) {
        if (isFetchBaseQueryError(err) && err.status === 409) {
          setPreferredIdError(ERROR_DUPLICATE);
          return;
        }
        enqueueSnackbar(ERROR_EDIT_USER, { variant: 'error' });
      }
    }
  };

  return {
    department,
    manager,
    preferredId,
    handleChangeDepartment,
    handleChangeManager,
    handleChangePreferredId,
    managerError,
    preferredIdError,
    handleSave,
    isLoadingEditUser: isLoading,
  };
};

export default useEditAccount;
