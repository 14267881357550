import { useEffect, useState } from 'react';
import {
  Route, RouteProps, Redirect,
} from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Loading from 'common/components/molecules/Loading';
import pageUrl from 'common/utils/freeze/pageUrl';

const AutoLoginRoute: React.FC<RouteProps> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSingedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);

      try {
        await Auth.currentAuthenticatedUser();
        setIsSignedIn(true);
      } catch {
        setIsSignedIn(false);
      }

      setIsLoading(false);
    };

    checkAuth();
  }, []);

  if (isLoading) {
    return <Loading open />;
  }

  return (
    isSingedIn
      ? <Redirect to={{ pathname: pageUrl.TOP }} />
      // eslint-disable-next-line react/jsx-props-no-spreading
      : <Route {...props} />
  );
};

export default AutoLoginRoute;
