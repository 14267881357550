import { useState } from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import MultiLineTypography from 'common/components/atoms/MultiLineTypography';

const useStyles = makeStyles((theme: Theme) => createStyles({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

type PopoverComponentProps = {
  message: string,
  children: React.ReactNode,
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  | 'subtitle1' | 'subtitle2'
  | 'body1' | 'body2'
  | 'caption'
  | 'button'
  | 'overline'
  | 'srOnly'
  | 'inherit',
  anchorOrigin?: {
    vertical: 'bottom' | 'center' | 'top' | number,
    horizontal: 'center' | 'left' | 'right' | number,
  }
  transformOrigin?: {
    horizontal: 'center' | 'left' | 'right' | number,
    vertical: 'bottom' | 'center' | 'top' | number,
  }
};

const PopoverComponent: React.FC<PopoverComponentProps> = (props: PopoverComponentProps) => {
  const {
    message, children, variant, anchorOrigin, transformOrigin,
  } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </Typography>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <MultiLineTypography
          text={message}
          variant={variant}
        />
      </Popover>
    </>
  );
};

PopoverComponent.defaultProps = {
  variant: 'caption',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

export default PopoverComponent;
