import styled from 'styled-components';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import BackgroundPageStyle from 'common/styles/BackgroundPageStyle';
import strings from 'common/utils/freeze/strings';
import AppSidebar from 'common/components/orgnisms/AppSidebar';
import AppHeader from 'common/components/molecules/AppHeader';
import { Role } from 'common/types/Role';
import { useGetUserQuery } from 'features/api/apiSlice';
import CustomerPage from './components/CustomerPage';
import ToppanPage from './components/ToppanPage';

const { TITLE } = strings.createProjectPage;
const {
  ERROR_GET_USER,
} = strings.createProjectPage.completionMessage;

const Body = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const MainArea = styled.div`
  flex: 1;
  display: flex;
`;

const CreateProject: React.FC = () => {
  const {
    data: userInfo,
    isSuccess,
    isError,
  } = useGetUserQuery();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(ERROR_GET_USER, { variant: 'error' });
    }
  }, [isError, enqueueSnackbar]);

  return (
    <BackgroundPageStyle>
      <AppHeader title={TITLE} />
      <Body>
        <AppSidebar initState={false} />
        <MainArea>
          {(isSuccess && userInfo) && (
            (userInfo.role === Role.customer) ? (
              <CustomerPage userInfo={userInfo} />
            ) : (
              <ToppanPage userInfo={userInfo} />
            )
          )}
        </MainArea>
      </Body>
    </BackgroundPageStyle>
  );
};

export default CreateProject;
