import { useState, useEffect } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import awsmobile from 'aws-exports.js';
import { useLocation, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useAppDispatch } from 'common/hooks';
import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';
import { setFirstLoginUserInfo } from '../firstLoginUserInfoSlice';

Amplify.configure(awsmobile);

type InputError = {
  id: string,
  password: string,
  reCAPTCHAToken: string
}

type Return = {
  signIn: (
    id: string,
    password: string,
    reCAPTCHAToken: string | null
  ) => void
  inputError: InputError,
  loading: boolean,
}

const useSignIn = (success: () => void): Return => {
  const {
    REQUIRED_ID, REQUIRED_PASSWORD, REQUIRED_RECAPTCHA, COGNITO,
  } = strings.signIn.validationError;
  const {
    INCORRECT_SIGNIN_PAGE,
  } = strings;

  const [idError, setIdError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [reCAPTCHATokenError, setReCAPTCHATokenError] = useState('');
  const [replacePath, setReplacePath] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const signInPage = location.pathname;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (replacePath) {
      enqueueSnackbar(INCORRECT_SIGNIN_PAGE, { variant: 'warning' });
      history.replace(replacePath);
    }
  }, [history, replacePath, enqueueSnackbar, INCORRECT_SIGNIN_PAGE]);

  const validation = (
    id: string,
    password: string,
    reCAPTCHAToken: string | null,
  ): boolean => {
    let requiredError = false;

    if (id.trim() === '') {
      requiredError = true;
      setIdError(REQUIRED_ID);
    } else {
      setIdError('');
    }

    // パスワードはユーザー側から空白も1文字として見えるので length で記述
    if (password.length === 0) {
      requiredError = true;
      setPasswordError(REQUIRED_PASSWORD);
    } else {
      setPasswordError('');
    }

    /*     if (reCAPTCHAToken === '') {
      requiredError = true;
      setReCAPTCHATokenError(REQUIRED_RECAPTCHA);
    } else {
      setReCAPTCHATokenError('');
    } */

    return requiredError;
  };

  const cognitoSignIn = async (
    id: string,
    password: string,
    reCAPTCHAToken: string,
  ) => {
    setLoading(true);
    let cognitoError = false;
    try {
      const signInUser = await Auth.signIn(
        id, password,
        { captcha: reCAPTCHAToken },
      );
      const isFirstSignIn = () => (signInUser.challengeName === 'NEW_PASSWORD_REQUIRED');

      if (signInPage === pageUrl.SIGNIN) {
        if (isFirstSignIn()) {
          cognitoError = true;
          setReplacePath(pageUrl.FIRST_SIGNIN);
        }
      } else if (signInPage === pageUrl.FIRST_SIGNIN) {
        if (isFirstSignIn()) {
          dispatch(setFirstLoginUserInfo({
            session: signInUser.Session || '',
            id,
          }));
        } else {
          cognitoError = true;
          setReplacePath(pageUrl.SIGNIN);
        }
      }
    } catch {
      cognitoError = true;
      setIdError(COGNITO);
      setPasswordError(COGNITO);
    }
    setLoading(false);
    return cognitoError;
  };

  const signIn = async (
    id: string,
    password: string,
    reCAPTCHAToken: string | null,
  ) => {
    const validationError = validation(
      id, password, reCAPTCHAToken,
    );

    if (validationError) {
      return;
    }

    if (reCAPTCHAToken !== null) {
      const signInError = await cognitoSignIn(
        id, password, reCAPTCHAToken,
      );
      if (signInError) {
        return;
      }
    }

    success();
  };

  return {
    signIn,
    inputError: {
      id: idError,
      password: passwordError,
      reCAPTCHAToken: reCAPTCHATokenError,
    },
    loading,
  };
};

export default useSignIn;
