import styled from 'styled-components';
import AppButton from 'common/components/atoms/AppButton';
import PopoverComponent from 'common/components/molecules/PopoverComponent';
import { ProjectStatus } from 'common/types/ProjectStatus';
import { Role } from 'common/types/Role';
import { isDisplayApprovalAndReturnButton } from '../utils/videoConfirmation';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
`;

type VideoConfirmationBottomContentProps = {
  userRole: Role;
  projectStatus: ProjectStatus;
  onClickAcceptSales: () => void;
  onClickAcceptCustomer: () => void;
  onClickSendBackSales: () => void;
  onClickSendBackCustomer: () => void;
  onClickSendBackMovringAndAdmin: () => void;
}

const VideoConfirmationBottomContent: React.FC<VideoConfirmationBottomContentProps> = (
  props: VideoConfirmationBottomContentProps,
) => {
  const {
    userRole,
    projectStatus,
    onClickAcceptSales,
    onClickAcceptCustomer,
    onClickSendBackSales,
    onClickSendBackCustomer,
    onClickSendBackMovringAndAdmin,
  } = props;

  if (!isDisplayApprovalAndReturnButton(projectStatus, userRole)) {
    return null;
  }

  return (
    <Container>
      {userRole === Role.customer && (
        <>
          <AppButton
            value="修正"
            backgroundColor="inherit"
            color="var(--progress-button-dark-grey)"
            border="1px solid var(--progress-button-dark-grey)"
            width="180px"
            onClick={onClickSendBackCustomer}
          />
          <AppButton
            value="承認"
            backgroundColor="var(--progress-button-dark-grey)"
            color="white"
            width="180px"
            onClick={onClickAcceptCustomer}
          />
        </>
      )}

      {(userRole === Role.sales || userRole === Role.movring) && (projectStatus === 'TBC_sales') && (
      <>
        <AppButton
          value="TGCへ差戻"
          backgroundColor="inherit"
          color="var(--progress-button-dark-grey)"
          border="1px solid var(--progress-button-dark-grey)"
          width="180px"
          onClick={onClickSendBackSales}
        />
        <PopoverComponent
          message="動画と一緒にコメントを送信できます。"
        >
          <AppButton
            value="得意先へ送信"
            backgroundColor="var(--progress-button-dark-grey)"
            color="white"
            width="180px"
            onClick={onClickAcceptSales}
          />
        </PopoverComponent>
      </>
      )}
      {(userRole === Role.admin || userRole === Role.movring) && (projectStatus === 'WIP_production') && (
        <AppButton
          value="TGCへ差戻"
          backgroundColor="inherit"
          color="var(--progress-button-dark-grey)"
          border="1px solid var(--progress-button-dark-grey)"
          width="180px"
          onClick={onClickSendBackMovringAndAdmin}
        />
      )}
    </Container>
  );
};

export default VideoConfirmationBottomContent;
