import axios from 'axios';
import strings from 'common/utils/freeze/strings';

type Return = {
  response: unknown
  error: string | null
}

const presignedUrlUploadFile = async (file: File, url: string): Promise<Return> => {
  try {
    const response = await axios({
      method: 'PUT',
      url,
      headers: {
        'Content-Type': '',
      },
      data: file,
    });
    return { response: response.data, error: null };
  } catch (e) {
    return { response: null, error: strings.ERROR_DESCRIPTION_UPLOAD_FILE };
  }
};

export default presignedUrlUploadFile;
