import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

type FirstLoginUserInfo = {
  session: string,
  id: string,
}

type State = {
  firstLoginUserInfo: FirstLoginUserInfo
}

const initialState: State = {
  firstLoginUserInfo: {
    session: '',
    id: '',
  },
};

export const firstLoginUserInfoSlice = createSlice({
  name: 'firstLoginUserInfo',
  initialState,
  reducers: {
    setFirstLoginUserInfo: (state, action: PayloadAction<FirstLoginUserInfo>) => {
      state.firstLoginUserInfo = action.payload;
    },
  },
});

export const { setFirstLoginUserInfo } = firstLoginUserInfoSlice.actions;

// eslint-disable-next-line @typescript-eslint/ban-types
export const selectFirstLoginUserInfo = (state: RootState): FirstLoginUserInfo =>
  // eslint-disable-next-line implicit-arrow-linebreak
  state.firstLoginuserInfo.firstLoginUserInfo;
export default firstLoginUserInfoSlice.reducer;
