import pageUrl from 'common/utils/freeze/pageUrl';
import Link from '@material-ui/core/Link';
import PopoverComponent from 'common/components/molecules/PopoverComponent';

import strings from 'common/utils/freeze/strings';
import styled from 'styled-components';

const CopiedLavel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
`;

const {
  COPIED_ID,
  PROJECT_NAME,
  COPIED_PROJECT,
} = strings.projectDetail.projectInfo;

type ProjectInfoCopiedFromFieldProps = {
  copiedId: number | null
  copiedName: string | null
}

const ProjectInfoCopiedFromField: React.FC<ProjectInfoCopiedFromFieldProps> = (
  props: ProjectInfoCopiedFromFieldProps,
) => {
  const {
    copiedId,
    copiedName,
  } = props;

  return (
    <CopiedLavel>
      <PopoverComponent
        message={`${COPIED_ID}: ${copiedId}\n${PROJECT_NAME}: ${copiedName}`}
      >
        <Link
          href={`${pageUrl.PROJECTS_LIST}/${copiedId}`}
          underline="always"
          color="initial"
          target="_blank"
        >
          {COPIED_PROJECT}
        </Link>
      </PopoverComponent>
    </CopiedLavel>
  );
};

export default ProjectInfoCopiedFromField;
