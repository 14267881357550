import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useEditConfirmationURLMutation } from 'features/api/apiSlice';
import { Role } from 'common/types/Role';
import strings from 'common/utils/freeze/strings';
import regex from 'common/utils/freeze/regex';
import checkBlankAndBlankConvertNull from 'common/utils/checkBlankAndBlankConvertNull';

type UseVideoConfirmationReturn = {
  value: string
  url: string
  isSavaConfirmationUrl: boolean
  urlValidationError: boolean
  urlValidationErrorMessage: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleCopy: () => void
  handleSave: () => void
}

const useVideoConfirmation = (
  confirmationUrl: string,
  userRole: Role,
  projectId: string,
)
  : UseVideoConfirmationReturn => {
  const MESSAGE = strings.projectDetail.completionMessage;
  const { URL_FORMAT } = strings.projectDetail.videoConfirmation.validation;
  const { URL } = regex;

  const [value, setValue] = useState(confirmationUrl);
  const [url, setUrl] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { enqueueSnackbar } = useSnackbar();
  const [
    saveConfirmationUrl,
    { isLoading: isSavaConfirmationUrl },
  ] = useEditConfirmationURLMutation();

  useEffect(() => {
    if (confirmationUrl.match(URL)) {
      setUrl(confirmationUrl);
    }
  }, [confirmationUrl, URL]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (URL.test(e.target.value.trim()) || e.target.value.trim() === '') {
      setUrl(e.target.value.trim());
    }
    setValue(e.target.value);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(value)
      .then(() => {
        enqueueSnackbar(MESSAGE.copyConfirmationUrl.SUCCESS, {
          variant: 'success',
          autoHideDuration: 1000,
        });
      })
      .catch(() => {
        enqueueSnackbar(MESSAGE.copyConfirmationUrl.ERROR, {
          variant: 'error',
          autoHideDuration: 1000,
        });
      });
  };

  const validation = () => {
    if (value.trim() !== '' && !URL.test(value.trim())) {
      setError(true);
      setErrorMessage(URL_FORMAT);
      return true;
    }

    setError(false);
    setErrorMessage('');
    return false;
  };

  const handleSave = async () => {
    if (validation()) {
      return;
    }

    if (!isSavaConfirmationUrl) {
      try {
        await saveConfirmationUrl(
          {
            projectId,
            confirmation_url: checkBlankAndBlankConvertNull(value.trim()),
          },
        ).unwrap();
        enqueueSnackbar(MESSAGE.saveConfirmationUrl.SUCCESS, {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar(MESSAGE.saveConfirmationUrl.ERROR, { variant: 'error' });
      }
    }
  };

  return {
    value,
    url,
    isSavaConfirmationUrl,
    urlValidationError: error,
    urlValidationErrorMessage: errorMessage,
    onChange,
    handleCopy,
    handleSave,
  };
};

export default useVideoConfirmation;
