import styled from 'styled-components';

import { withStyles, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import strings from 'common/utils/freeze/strings';
import maxLength from 'common/utils/freeze/maxLength';

const { LABEL_PROJECT_NAME } = strings.createProjectPage;

const InputComponent = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledTextField = withStyles(() => createStyles({
  root: {
    backgroundColor: 'white',
    '& .MuiFormHelperText-root.Mui-error': {
      backgroundColor: 'rgba(251,251,251,0.9)',
      margin: 0,
      paddingLeft: 10,
    },
  },
}))(TextField);

type ProjectNameFieldProps = {
  value: string,
  onChange: (value: string) => void,
  errorMessage?: string,
};

const ProjectNameField: React.FC<ProjectNameFieldProps> = (props: ProjectNameFieldProps) => {
  const { value, onChange, errorMessage } = props;

  const handleInput = (event: React.ChangeEvent<{ value: string }>) => {
    const text = event.target.value;

    onChange(text);
  };

  return (
    <InputComponent>
      <Typography variant="body1">
        {LABEL_PROJECT_NAME}
      </Typography>
      <StyledTextField
        value={value}
        onChange={handleInput}
        variant="outlined"
        fullWidth
        inputProps={{
          maxLength: maxLength.PROJECT_NAME,
        }}
        error={errorMessage !== ''}
        helperText={errorMessage}
        placeholder={strings.INPUT_PLACEHOLDER}
      />
    </InputComponent>
  );
};

ProjectNameField.defaultProps = {
  errorMessage: '',
};

export default ProjectNameField;
