import { useState } from 'react';

import { createStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useSnackbar } from 'notistack';

import { RootState } from 'app/store';
import { useAppSelector, useAppDispatch } from 'common/hooks';
import { ProjectStatus } from 'common/types/ProjectStatus';
import strings from 'common/utils/freeze/strings';
import MenuItem from 'common/components/atoms/MenuItem';
import Loading from 'common/components/molecules/Loading';
import { useAddDiversionProjectMutation } from 'features/api/apiSlice';
import { setSelectedProject, selectProjectById } from 'features/projects/projectsSlice';

const {
  LABEL,
  DIVERSION,
  DELETE,
} = strings.projects.menu;
const diversionCompMsg = strings.projects.completionMessage.diversion;

const StyledButton = withStyles(() => createStyles({
  root: {
    height: 40,
    fontSize: 'var(--small-font-size)',
    backgroundColor: '#F0F1F7',
  },
}))(Button);

type Props = {
  onClickDeleteButton: () => void;
}
const ProjectsMenu: React.FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector((state: RootState) => state.projects.selected);
  const project = useAppSelector((state: RootState) => selectProjectById(state, projectId));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [addDiversionProject, {
    isLoading: isLoadingDiversion,
  }] = useAddDiversionProjectMutation();

  const isLoading = isLoadingDiversion;

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDiversion = async () => {
    setAnchorEl(null);

    if (projectId === 0) {
      return;
    }

    if (!isLoading) {
      try {
        await addDiversionProject(projectId).unwrap();
        enqueueSnackbar(diversionCompMsg.SUCCESS, { variant: 'success' });
      } catch (err) {
        enqueueSnackbar(diversionCompMsg.ERROR, { variant: 'error' });
      }
    }
    dispatch(setSelectedProject(0));
  };

  const handleDelete = async () => {
    setAnchorEl(null);
    props.onClickDeleteButton();
  };

  return (
    <>
      <Loading open={isLoading} />
      {(projectId !== 0) && (
        <>
          <StyledButton
            onClick={handleClick}
            variant="outlined"
            endIcon={<ArrowDropDownIcon />}
          >
            {LABEL}
          </StyledButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleDiversion}>{DIVERSION}</MenuItem>
            {(project) && (project.statusId === ProjectStatus.preSubmission) && (
              <MenuItem onClick={handleDelete}>{DELETE}</MenuItem>
            )}
          </Menu>
        </>
      )}
    </>
  );
};

export default ProjectsMenu;
