import styled from 'styled-components';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import BackgroundPageStyle from 'common/styles/BackgroundPageStyle';
import { useAppSelector, useAppDispatch } from 'common/hooks';
import { Role } from 'common/types/Role';
import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';
import AppSidebar from 'common/components/orgnisms/AppSidebar';
import AppHeader from 'common/components/molecules/AppHeader';
import Loading from 'common/components/molecules/Loading';
import { useGetUserQuery, useGetCustomersQuery } from 'features/api/apiSlice';
import { TableHeadCell } from 'features/customers/types';
import {
  setCustomers,
  selectCustomersTotal,
} from 'features/customers/customersSlice';
import CustomersTable from './components/CustomersTable';
import SearchCustomers from './components/SearchCustomers';
import AddNewCustomerButton from './components/AddNewCustomerButton';

const {
  HEADER_TITLE,
  ERROR_GET_CUSTOMERS,
  errorUserInfo,
} = strings.customers;
const {
  COMPANY,
  DEPARTMENT,
  NAME,
  SALES,
  MOVRING,
  TGC,
} = strings.customers.tableHeader;

const Body = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const MainArea = styled.div`
  flex: 1;
  padding: 0 35px 35px 35px;
  overflow: scroll;
`;

const Head = styled.div`
  display: flex;
  padding: 20px 0 20px 0
`;

const Search = styled.div`
  width: 800px;
  height: 40px;
  margin: 0 0 0 auto;
`;

const Customers: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const count = useAppSelector(selectCustomersTotal);
  const dispatch = useAppDispatch();
  const tableHeadCells: TableHeadCell[] = [
    { id: 'company', align: 'left', label: COMPANY },
    { id: 'department', align: 'left', label: DEPARTMENT },
    { id: 'name', align: 'left', label: NAME },
    { id: 'sales', align: 'left', label: SALES },
    { id: 'movring', align: 'left', label: MOVRING },
    { id: 'tgc', align: 'left', label: TGC },
  ];

  const {
    data,
    isLoading,
    isSuccess,
    isError,
  } = useGetCustomersQuery();

  if (isSuccess) {
    if (data) {
      dispatch(setCustomers(data));
    }
  }

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(ERROR_GET_CUSTOMERS, { variant: 'error' });
    }
  }, [isError, enqueueSnackbar]);

  return (
    <BackgroundPageStyle>
      <AppHeader title={HEADER_TITLE} />
      <Body>
        <AppSidebar initState={false} />
        <MainArea>
          <Loading open={isLoading} />
          <Head>
            <AddNewCustomerButton />
            <Search>
              <SearchCustomers tableHeadCells={tableHeadCells} />
            </Search>
          </Head>
          {count > 0 && (
            <CustomersTable headCells={tableHeadCells} />
          )}
        </MainArea>
      </Body>
    </BackgroundPageStyle>
  );
};

const CustomerAccountList: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const {
    data,
    isLoading,
    isSuccess,
    isError,
  } = useGetUserQuery();

  useEffect(() => {
    if (isSuccess) {
      if (data && data.role === Role.customer) {
        enqueueSnackbar(errorUserInfo.ERROR_PERMISSION, { variant: 'error' });
        history.replace({ pathname: pageUrl.TOP });
      }
    }
  }, [data, isSuccess, enqueueSnackbar, history]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(errorUserInfo.ERROR_GET_USER, { variant: 'error' });
    }
  }, [isError, enqueueSnackbar]);

  return (
    <>
      <Loading open={isLoading} />
      {(!isLoading && !isError) && (
        // ユーザー情報の取得失敗時はコンポーネントを表示したくないので、
        // isLoading を見てローディング中にコンポーネントを作成しないようにしている
        <Customers />
      )}
    </>
  );
};

export default CustomerAccountList;
