import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useAcceptConfirmationVideoMutation } from 'features/api/apiSlice';
import strings from 'common/utils/freeze/strings';
import { AlertProps } from '../components/Alert';

const {
  CUSTOMER_ACCEPT_SUCCESS,
  CUSTOMER_ACCEPT_ERROR,
  ACCEPT,
  CONFIRMATION_VIDEO_ACCEPT,
  CUSTOMER_ACCEPT_ALERT_BODY,
} = strings.projectDetail.videoConfirmation;

const useCustomerAcceptAlert = (projectId: string): [AlertProps, () => void] => {
  const agreeButtonText = ACCEPT;
  const dialogTitle = CONFIRMATION_VIDEO_ACCEPT;
  const body = CUSTOMER_ACCEPT_ALERT_BODY;

  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [accept, { isLoading }] = useAcceptConfirmationVideoMutation();

  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => setDialogOpen(false);

  const onAgree = async () => {
    try {
      await accept({ projectId, message: '' }).unwrap();
      enqueueSnackbar(CUSTOMER_ACCEPT_SUCCESS, { variant: 'success' });
      setDialogOpen(false);
    } catch {
      enqueueSnackbar(CUSTOMER_ACCEPT_ERROR, { variant: 'error' });
    }
  };

  return [{
    dialogOpen,
    isAgree: isLoading,
    agreeButtonText,
    dialogTitle,
    body,
    handleClose,
    onAgree,
  }, handleOpen];
};

export default useCustomerAcceptAlert;
