import styled from 'styled-components';

import { Role } from 'common/types/Role';
import strings from 'common/utils/freeze/strings';
import MailSettingsButton from 'common/components/orgnisms/MailSettingsButton';
import { ProjectNotifications } from 'features/api/types';

const {
  BUTTON_CUSTOMER,
  BUTTON_TOPPAN,
} = strings.projectDetail.projectInfo.notifications;

const NotificationSettingsField = styled.div`
  width: 160px;
  margin: 0 0 0 auto;
`;

const FlexSettingsField = styled.div`
  display: flex;
  align-items: center;
`;

type NotificationSettingFieldProps = {
  userRole: Role,
  notifications: ProjectNotifications[],
  saveEmails: (emails: string[]) => void,
  saveEmailsToppan: (emails: string[]) => void,
}

const NotificationSettingField: React.FC<NotificationSettingFieldProps> = (
  props: NotificationSettingFieldProps,
) => {
  const {
    userRole,
    notifications,
    saveEmails,
    saveEmailsToppan,
  } = props;

  const customerMails = notifications
    .filter((notification) => notification.is_customer)
    .filter((notification) => !notification.is_representative)
    .map((notification) => notification.mail);

  const customerFixedMails = notifications
    .filter((notification) => notification.is_customer)
    .filter((notification) => notification.is_representative)
    .map((notification) => notification.mail);

  const toppanMails = notifications
    .filter((notification) => !notification.is_customer)
    .filter((notification) => !notification.is_representative)
    .map((notification) => notification.mail);

  const toppanFixedMails = notifications
    .filter((notification) => !notification.is_customer)
    .filter((notification) => notification.is_representative)
    .map((notification) => notification.mail);

  return (
    <FlexSettingsField>
      <NotificationSettingsField>
        <MailSettingsButton
          text={BUTTON_CUSTOMER}
          emails={customerMails}
          fixedEmails={customerFixedMails}
          saveEmails={saveEmails}
        />
        {(userRole !== Role.customer) && (
          <MailSettingsButton
            text={BUTTON_TOPPAN}
            emails={toppanMails}
            fixedEmails={toppanFixedMails}
            saveEmails={saveEmailsToppan}
          />
        )}
      </NotificationSettingsField>
    </FlexSettingsField>
  );
};

export default NotificationSettingField;
