import { ProjectStatus } from 'common/types/ProjectStatus';
import { Role } from 'common/types/Role';
import { useState, useEffect } from 'react';
import { StepStatus } from '../type';
import {
  mostAdvancedStepCaseCustomer,
  mostAdvancedStepCaseSales,
  mostAdvancedStepCaseMovringAndAdmin,
} from '../utils/step';

type UseStepRetrun = {
  activeStep: StepStatus,
  handleStep: (step: StepStatus) => void,
}

const useStep = (projectStatus: ProjectStatus, userRole: Role): UseStepRetrun => {
  const initStep = (): StepStatus => {
    switch (userRole) {
      case Role.customer:
        return mostAdvancedStepCaseCustomer(projectStatus);
      case Role.sales:
        return mostAdvancedStepCaseSales(projectStatus);
      case Role.movring:
      case Role.admin:
        return mostAdvancedStepCaseMovringAndAdmin(projectStatus);
      default: {
        const exhaustivenessCheck: never = userRole;
        throw new Error(`Unsupported type: ${exhaustivenessCheck}`);
      }
    }
  };

  const [activeStep, setActiveStep] = useState<StepStatus>(initStep);

  useEffect(() => {
    if (userRole === Role.customer) {
      setActiveStep(mostAdvancedStepCaseCustomer(projectStatus));
    } else if (userRole === Role.sales) {
      setActiveStep(mostAdvancedStepCaseSales(projectStatus));
    } else if (userRole === Role.movring || userRole === Role.admin) {
      setActiveStep(mostAdvancedStepCaseMovringAndAdmin(projectStatus));
    }
  }, [projectStatus, userRole]);

  return {
    activeStep, handleStep: setActiveStep,
  };
};

export default useStep;
