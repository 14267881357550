import { useState } from 'react';

import strings from 'common/utils/freeze/strings';
import { useAddNewProjectMutation } from 'features/api/apiSlice';
import { ProjectBody } from 'features/api/types';
import { AutoCompleteData } from '../components/AutoCompleteTextField';

const { REQUIRED } = strings.createProjectPage.validationError;

type InputError = {
  projectName: string,
  accountName: string,
}

type Return = {
  isLoading: boolean
  createProject: (
    projectName: string,
    accountName: AutoCompleteData,
    emails: string[],
    emailsToppan: string[],
  ) => void,
  inputError: InputError,
};

const useCreateProject = (
  success: (projectId :number) => void,
  error: () => void,
): Return => {
  const [projectNameError, setProjectNameError] = useState('');
  const [accountNameError, setAccountNameError] = useState('');

  const [addNewProject, {
    isLoading,
  }] = useAddNewProjectMutation();

  const createProject = async (
    projectName: string,
    accountName: AutoCompleteData,
    emails: string[],
    emailsToppan: string[],
  ) => {
    let requiredError = false;

    if (projectName.trim() === '') {
      requiredError = true;
      setProjectNameError(REQUIRED);
    } else {
      setProjectNameError('');
    }

    if (accountName.id === 0) {
      requiredError = true;
      setAccountNameError(REQUIRED);
    } else {
      setAccountNameError('');
    }

    if (requiredError) {
      return;
    }

    const customerEmails = emails.map((email) => ({
      mail: email,
    }));

    const toppanEmails = emailsToppan.map((email) => ({
      mail: email,
    }));

    const project: ProjectBody = {
      customer_id: accountName.id,
      name: projectName,
      customer_notifications: customerEmails,
      supplier_notifications: toppanEmails,
    };

    if (!isLoading) {
      try {
        const response = await addNewProject(project).unwrap();
        success(response.id);
      } catch (err) {
        error();
      }
    }
  };

  return {
    isLoading,
    createProject,
    inputError: {
      projectName: projectNameError,
      accountName: accountNameError,
    },
  };
};

export default useCreateProject;
