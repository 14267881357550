const maxLength = Object.freeze({
  PASSWORD: 50,
  ANNOUNCEMENT: 5000,
  NOTE: 5000,
  CUSTOMER_COMPANY_NAME: 40,
  CUSTOMER_COMPANY_CODE: 6,
  CUSTOMER_DEPARTMENT: 60,
  CUSTOMER_MANAGER: 20,
  CUSTOMER_USER_NAME: 20,
  LOGIN_ID: 128,
  SUPPLIER_COMPANY_NAME: 40,
  SUPPLIER_BUSINESS_UNIT: 60,
  SUPPLIER_DEPARTMENT: 60,
  SUPPLIER_USER_NAME: 20,
  PROJECT_NAME: 60,
  TEMPLATE_ID: 5,
  CONFIRMATION_URL: 1000,
  EMAIL: 128,
  APPROVALANDRETURN: 5000,
});

export default maxLength;
