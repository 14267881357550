import styled from 'styled-components';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { User } from 'features/api/types';
import { Role } from 'common/types/Role';
import strings from 'common/utils/freeze/strings';
import pageUrl from 'common/utils/freeze/pageUrl';
import SidebarLink from '../atoms/SidebarLink';

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.2rem;
  .sidebar-title-font {
    font-size: var(--sidebar-title-font-size);
    font-weight: bold;
    margin-left: 0.6rem;
  }
`;

type Props = {
  userInfo: User | undefined
}

const SidebarAccountMenu: React.FC<Props> = (props: Props) => {
  const { userInfo } = props;

  const {
    ACCOUNT_MANAGEMENT,
  } = strings.sidebar.TITLE;
  const {
    CUSTOMERS,
    SIGN_UP_CUSTOMER,
    SUPPLIERS,
    SIGN_UP_SUPPLIER,
  } = strings.sidebar.MENU;

  return (
    <div>
      <Title>
        <AccountCircleIcon style={{ fontSize: 35 }} />
        <div className="sidebar-title-font">{ACCOUNT_MANAGEMENT}</div>
      </Title>
      <SidebarLink
        to={pageUrl.CUSTOMER_ACCOUNT_LIST}
        text={CUSTOMERS}
      />
      <SidebarLink
        to={pageUrl.CUSTOMER_SIGNUP}
        text={SIGN_UP_CUSTOMER}
      />
      {userInfo && userInfo.role !== Role.sales && (
        <>
          <SidebarLink
            to={pageUrl.TOPPAN_ACCOUNT_LIST}
            text={SUPPLIERS}
          />
          <SidebarLink
            to={pageUrl.TOPPAN_SIGNUP}
            text={SIGN_UP_SUPPLIER}
          />
        </>
      )}
    </div>
  );
};

export default SidebarAccountMenu;
