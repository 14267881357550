import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

const dialogTitle = makeStyles({
  root: {
    backgroundColor: 'var(--theme-color)',
    color: 'var(--font-color-4)',
    fontWeight: 'bold',
    fontSize: 'var(--button-medium-font-size)',
    padding: '0.55rem 1.1rem',
  },
});

const TitleContent = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
`;

type ActionDialogProps = {
  open: boolean
  onClose: () => void
  children: React.ReactNode
  maxWidth: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
  title: string
  titleContent: React.ReactNode | null
}

const ActionDialog: React.FC<ActionDialogProps> = (props: ActionDialogProps) => {
  const {
    open, onClose, children, maxWidth, title, titleContent,
  } = props;
  const classes = dialogTitle();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={maxWidth}>
      <DialogTitle
        disableTypography
        classes={{
          root: classes.root,
        }}
      >
        <TitleContent>
          <div>{title}</div>
          <div style={{ paddingTop: '0.4rem', paddingLeft: '0.6rem' }}>{titleContent}</div>
        </TitleContent>
      </DialogTitle>
      {children}
    </Dialog>
  );
};

export default ActionDialog;
