import axios from 'axios';
import strings from 'common/utils/freeze/strings';
import ApiUrl from 'common/utils/freeze/ApiUrl';
import getToken from 'features/auth/getToken';

const { BASE_URL } = ApiUrl;

type ReturnType = {
  response: unknown
  error: string | null
}

const deleteFileData = async (projectId: string, fileId: string): Promise<ReturnType> => {
  try {
    const { idToken, accessToken } = await getToken();
    const response = await axios.delete(
      `${BASE_URL}/projects/${projectId}/files/${fileId}`,
      {
        headers: {
          authorization: idToken,
          accesstoken: accessToken,
        },
      },
    );
    return { response: response.data, error: null };
  } catch (e) {
    return { response: null, error: strings.projectDetail.completionMessage.deleteFile.ERROR };
  }
};

export default deleteFileData;
